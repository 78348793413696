import GroupBase, { GroupProps } from "../shared/GroupBase";
import { Input } from "@chakra-ui/react";

const InputGroup = ({
  name,
  label,
  error,
  autoFocus = true,
  isDisabled,
  layoutProps,
  ...rest
}: GroupProps & { layoutProps?: GroupProps }) => (
  <GroupBase
    name={name}
    label={label}
    error={error}
    isDisabled={isDisabled}
    {...layoutProps}
  >
    <Input
      autoFocus={autoFocus}
      name={name}
      isInvalid={Boolean(error)}
      isDisabled={isDisabled}
      borderRadius="8px"
      {...rest}
    />
  </GroupBase>
);

export default InputGroup;
