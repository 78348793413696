import Logo from "assets/logos/timescale-logo.svg";
import { Flex, Image } from "@chakra-ui/react";
import circle_bottom_center from "assets/images/page_backgrounds/circle_bottom_center.svg";
import { BasicCard } from "components/container/BasicCard";

export const SuccessLayout = ({
  children,
}: {
  children?: JSX.Element | JSX.Element[];
}) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      backgroundImage={{ base: `url(${circle_bottom_center})` }}
      backgroundRepeat="no-repeat"
      backgroundSize="2000px"
      backgroundPosition="center calc(50% + 900px)"
      height="100vh"
      backgroundColor="var(--chakra-colors-primary-100)"
    >
      <Image src={Logo} mb="57px" />
      <BasicCard minW="400px">
        <>{children}</>
      </BasicCard>
    </Flex>
  );
};
