import { Box, Flex, Heading, Link } from "@chakra-ui/react";
import Button from "components/button/Button";
import InputGroup from "components/input/InputGroup";
import PasswordInputGroup from "components/input/PasswordInputGroup";
import { Field, FieldProps, Form, Formik } from "formik";
import { observer } from "mobx-react";
import { MfaChallenge } from "pages/account/mfa/mfaComponents";
import { ForgotPasswordLink } from "pages/register/ForgotPassword";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { useStores } from "stores";
import { Userpilot } from "userpilot";
import { LazyWithPreload } from "utils/lazyPreload";
import * as Yup from "yup";
import { GoogleSSOButton } from "./GoogleSSOButton";
import { Container } from "./registerComponents";

const ForgotPassword = LazyWithPreload(
  () => import("pages/register/ForgotPassword"),
);

const ResendEmail = LazyWithPreload(() => import("pages/register/ResendEmail"));

const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Required"),
  password: Yup.string().required("Required"),
});

const LoginPage = () => {
  useEffectOnce(() => {
    ForgotPassword.preload();
    ResendEmail.preload();
  });

  const { authStore } = useStores();
  const { forgotPassword, errors, resendEmail, inProgress, twoFactorId } =
    authStore;

  useEffect(() => {
    Userpilot.reload();
    authStore.resetError();
    return () => authStore.resetEmailFlow();
  }, [authStore]);

  if (resendEmail) {
    return <ResendEmail />;
  }

  if (forgotPassword) {
    return <ForgotPassword />;
  }
  if (twoFactorId) {
    return (
      <Flex id="foo" width="100%" height="100%" alignItems={"center"}>
        <Container>
          <Heading mb="40px" textAlign="center" color="grayscale.900">
            Two-factor authentication
          </Heading>
          <MfaChallenge
            canUseRecoveryCode={true}
            verify={authStore.login}
            size="lg"
            confirmText="Continue"
            onCancel={() => {
              authStore.resetMfaLogin();
            }}
            appText="Enter the code from your authenticator app to log in."
            recoverySubText="Enter recovery code to confirm."
          />
        </Container>
      </Flex>
    );
  }

  return (
    <LoginComponent loginError={errors?.message} inProgress={inProgress} />
  );
};

const LoginComponent = observer(({ loginError, inProgress }: any) => {
  const { authStore } = useStores();
  const { search } = window.location;
  const query =
    search &&
    new URLSearchParams(search.replace("+", "_plusSign_"))
      ?.get?.("email")
      ?.replace("_plusSign_", "+");

  return (
    <Formik
      enableReinitialize
      initialValues={{
        password: "",
        email: query || "",
      }}
      validationSchema={loginValidationSchema}
      onSubmit={async (values) => await authStore.login(values)}
    >
      <Form>
        <Container
          display="grid"
          p={{ base: "40px 32px", lg: "64px 80px" }}
          borderRadius={{ base: "8px", lg: "16px", sm: "40px" }}
          boxShadow="lg"
          margin="10px"
        >
          <Box>
            <Heading
              as="h3"
              size="h3"
              mb="40px"
              textAlign="center"
              color="grayscale.900"
            >
              Log in to Timescale
            </Heading>
            <Field name="email">
              {({ field, meta }: FieldProps) => (
                <InputGroup
                  error={meta.touched && meta.error ? meta.error : null}
                  placeholder="Your email"
                  label="Email address"
                  layoutProps={{ mb: "32px" }}
                  data-cy="email"
                  {...field}
                />
              )}
            </Field>
            <Field name="password">
              {({ field, meta }: FieldProps) => (
                <PasswordInputGroup
                  autocomplete="on"
                  passwordType="enter"
                  error={
                    loginError?.[0]?.toUpperCase() + loginError?.substring(1) ||
                    (meta.touched && meta.error ? meta.error : null)
                  }
                  placeholder="Your password"
                  label="Password"
                  data-cy="password"
                  layoutProps={{ mb: "12px" }}
                  {...field}
                />
              )}
            </Field>
            <ForgotPasswordLink mb="40px" />
            <Button
              type="submit"
              data-cy="account-login"
              size="lg"
              maxW="unset"
              w="100%"
              mb="24px"
              isLoading={inProgress}
            >
              Log in
            </Button>

            <GoogleSSOButton textType="continue_with" />

            <br />

            <Flex gap="4px" justifyContent="center" fontSize="12px">
              Don't have an account?{" "}
              <Link
                data-cy="create-account"
                as={NavLink}
                to="signup"
                fontSize="12px"
              >
                Create an account
              </Link>
            </Flex>
          </Box>
        </Container>
      </Form>
    </Formik>
  );
});

export default observer(LoginPage);
