import {
  Box,
  Flex,
  forwardRef,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  Text,
  Link,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import Button from "components/button/Button";
import { ReactComponent as RocketFlames } from "./RocketFlames.svg";
import IconButton from "components/button/IconButton";
import { useContext } from "react";
import { GuidedTourContext } from "./GuidedTourProvider";
import { isNull } from "lodash";

const CloseIconButton = forwardRef((props, ref) => (
  <Box ref={ref} {...props}>
    <IconButton
      iconName="actions/Close/Large"
      label="Close"
      variant="ghostIcon"
      size="md"
    />
  </Box>
));

export const GuidedTourPopoverContent = ({
  onClose = () => {},
  onCancel,
  confirmText = "Next",
  cancelText = "Back",
  children,
}: {
  onClose?: () => void;
  onCancel: () => void;
  confirmText?: string;
  cancelText?: string;
  children: JSX.Element | Array<JSX.Element>;
}) => {
  const { setStepDone, skipTour, currentTourStep } =
    useContext(GuidedTourContext);

  return (
    <PopoverContent
      bg="strongGradient.90"
      p="2px"
      mt="16px"
      opacity="0.95 !important"
    >
      <Flex
        direction="column"
        bg="softGradient.90"
        borderRadius="14px"
        p="24px"
      >
        <PopoverArrow
          borderLeftWidth="2px"
          borderBottomWidth="2px"
          borderColor="primary.900"
          bg="primary.200"
          borderBottomLeftRadius="3px"
        />
        {!isNull(currentTourStep) && currentTourStep > 0 && (
          <Text textStyle="label" color="grayscale.700">
            {currentTourStep}/3
          </Text>
        )}
        <PopoverCloseButton
          as={CloseIconButton}
          onClick={() => {
            skipTour();
            onClose();
          }}
          data-cy="close-guided-tour"
        />
        <PopoverBody
          display="flex"
          flexDirection="column"
          gap="16px"
          color="grayscale.700"
        >
          {children}
          <Flex justifyContent="space-between" gap="8px" mt="8px">
            <Button
              variant="secondary"
              onClick={onCancel}
              maxW="unset"
              flexGrow={1}
            >
              {cancelText}
            </Button>
            <Button
              onClick={() => {
                setStepDone();
                onClose();
              }}
              maxW="unset"
              flexGrow={1}
            >
              {confirmText}
            </Button>
          </Flex>
        </PopoverBody>
      </Flex>
    </PopoverContent>
  );
};

export const GuidedTourIntro = () => (
  <>
    <RocketFlames width="auto" />
    <Text textStyle="h3">We present you the new navigation bar! ✨</Text>
    <Text>
      We've reorganized Timescale Console to enhance your experience. Take a
      tour to see what’s new!
    </Text>
  </>
);

export const GuidedTourNewModes = () => (
  <>
    <Text textStyle="h3">New Ops and Data modes</Text>
    <ModeDescription
      text={
        <>
          <b>Ops:</b> Everything you need to manage your Timescale Cloud
          services.
        </>
      }
      indicatorColor="timescaleGradient.90"
    />
    <ModeDescription
      text={
        <>
          <b>Data:</b> Work with your data directly from Timescale Console.
          Write data, visualize queries and share results.
        </>
      }
      indicatorColor="popsqlGradient.90"
    />
    <Text>
      <Link href="#" isExternal>
        Read more
      </Link>{" "}
      about the new modes in our docs.
    </Text>
  </>
);

const ModeDescription = ({
  text,
  indicatorColor,
}: {
  text: JSX.Element;
  indicatorColor: string;
}) => (
  <Flex gap="8px" alignItems="flex-start">
    <Box
      borderRadius="2px"
      bg={indicatorColor}
      w="8px"
      h="8px"
      p="4px"
      mt="6px"
    />
    <Text>{text}</Text>
  </Flex>
);

export const GuidedTourProjectSection = () => (
  <>
    <Text textStyle="h3">New project section</Text>
    <Box>
      Find everything you need to manage your services:
      <UnorderedList>
        <ListItem>Project settings</ListItem>
        <ListItem>Billing</ListItem>
        <ListItem>Users </ListItem>
        <ListItem>Project switcher</ListItem>
      </UnorderedList>
    </Box>
  </>
);

export const GuidedTourHelpAndSupport = () => (
  <>
    <Text textStyle="h3">Refreshed Help & Support!</Text>{" "}
    <Box>
      Find all the help you need:
      <UnorderedList>
        <ListItem>
          <b>Related documents:</b> Access the most relevant docs for your
          current section or search our entire documentation.
        </ListItem>
        <ListItem>
          <b>Changelog:</b> See our latest updates.
        </ListItem>
        <ListItem>
          <b>Support:</b> Create a support ticket to get assistance from our
          experts.
        </ListItem>
        <ListItem>
          <b>Send us feedback:</b> Share your thoughts with our product
          team—we'd love to hear from you!
        </ListItem>
        <ListItem>
          <b>Slack channel:</b> Join us to the community Slack channel.
        </ListItem>
        <ListItem>
          <b>Getting started:</b> Quickly open our guides and tutorials to learn
          about Timescale.
        </ListItem>
        <ListItem>
          <b>System status:</b> Check the current system status.
        </ListItem>
      </UnorderedList>
    </Box>
  </>
);
