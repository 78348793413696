/**
 * NOTE: Format this page at your own risk!
 */

import { ForkSpec } from "graphql/generated";

export const createTable = `
CREATE TABLE conditions (
    time        TIMESTAMPTZ       NOT NULL,
    sensor_id   INTEGER           NOT NULL,
    value       DOUBLE PRECISION  NULL
);
`.trim();

export const htQuery = `
SELECT create_hypertable(
  'conditions',
  by_range('time', INTERVAL '7 days')
);
`.trim();

export const createHypertableTableCode = `
-- First, create a regular table
CREATE TABLE conditions (
  time         TIMESTAMPTZ       NOT NULL,
  location     TEXT              NOT NULL,
  temperature  DOUBLE PRECISION  NULL
);
`.trim();

export const createHypertableConvertCode = `
-- Then, turn it into a hypertable
SELECT create_hypertable('conditions', 'time');
`.trim();

export const createHypertableCode = `
${createHypertableTableCode}

${createHypertableConvertCode}
`.trim();

export const createHypertableFromExistingCode = `
SELECT create_hypertable(
  '<table_name>',
  by_range('<time_column_name>')
);
`.trim();

export const insertDataCode = `
INSERT INTO conditions
  VALUES
     (NOW(), 'office', 70.0),
     (NOW(), 'basement', 66.5),
     (NOW(), 'garage', 77.0);
`.trim();

export const queryHypertableCode = `
SELECT * FROM conditions ORDER BY time DESC
LIMIT 100;
`.trim();

export const createPostgresCode = `
-- Create a table
CREATE TABLE users (
    user_id SERIAL PRIMARY KEY,
    username VARCHAR(50) NOT NULL,
    email VARCHAR(100) NOT NULL UNIQUE,
    subscription_type VARCHAR(50),
    account_creation_date TIMESTAMP NOT NULL DEFAULT CURRENT_TIMESTAMP
);
`.trim();

export const insertPostgresDataCode = `
-- Insert dummy data into the 'users' table
INSERT INTO users (username, email, subscription_type) VALUES
('user1', 'user1@example.com', 'basic'),
('user2', 'user2@example.com', 'premium'),
('user3', 'user3@example.com', 'basic'),
('user4', 'user4@example.com', 'premium'),
('user5', 'user5@example.com', 'enterprise');
`.trim();

export const queryPostgresCode = `
SELECT * FROM users ORDER BY subscription_type DESC
LIMIT 100;
`.trim();

export const migrateData = `
docker run -ti timescale/hypershift:edge clone \\
-s "<SOURCE_DB_URI>" \\
-t "<DB_URI>"
`.trim();

export const migrateDataDumpExistingDatabase = `
pg_dump --quote-all-identifiers --no-tablespaces --no-owner \\
--no-privileges -h <DB_HOST> -p <DB_PORT> \\
-U <DB_USER> -Fc -f tsdb.bak <DB_NAME>
`.trim();

export const migrateDataRestoreDatabase = (url: string) =>
  `
pg_restore -Fc \\
-d ${url} tsdb.bak
`.trim();

export const lowDowntimeMigrationSnapshot = (url: string) =>
  `
docker run --rm -it --name live-migration-snapshot \\
  -e PGCOPYDB_SOURCE_PGURI=<SOURCE_PG_URI> \\
  -e PGCOPYDB_TARGET_PGURI="${url}" \\
  --pid=host \\
  -v ~/live-migration:/opt/timescale/ts_cdc \\
  timescale/live-migration:latest snapshot
`.trim();

export const lowDowntimeMigrationMigrate = (url: string) =>
  `
docker run --rm -it --name live-migration-migrate \\
  -e PGCOPYDB_SOURCE_PGURI=<SOURCE_PG_URI> \\
  -e PGCOPYDB_TARGET_PGURI="${url}" \\
  --pid=host \\
  -v ~/live-migration:/opt/timescale/ts_cdc \\
  timescale/live-migration:latest migrate
`.trim();

export const importTimeseriesTar = `curl -fsSL https://tsdb.co/timescaledb-import | bash`;

export const importTimeseriesSource = (url: string) =>
  `
timescaledb-import \\
--source <SOURCE_PG_URI> \\
--target "${url}"
`.trim();

interface BaseFileContentsProps {
  serviceName: string;
  defaultDBName: string;
  username: string;
  initialPassword: string;
  url: string;
  port: number;
  connectionHost: string;
}

export type FileContentsTutorialProps = BaseFileContentsProps;
export type FileContentsVectorProps = BaseFileContentsProps;

export type FileContentsProps = BaseFileContentsProps & {
  serviceType: string;
  isForkedService: ForkSpec;
};

export const fileContents = (params: FileContentsProps) => {
  const {
    serviceName,
    serviceType,
    defaultDBName,
    username,
    initialPassword,
    url,
    port,
    connectionHost,
    isForkedService,
  } = params;

  const isPostgress = serviceType === "POSTGRES";
  const isReadReplica = isForkedService?.isStandby;
  let contents = `${
    !isForkedService ? "/****  " : ""
  }GET STARTED WITH YOUR TIMESCALE SERVICE${!isForkedService ? "  ****/" : ""}

${
  !isForkedService
    ? ""
    : "----------------------------------------------------------------------------"
}
${!isForkedService ? "/*" : ""}
SERVICE INFORMATION:

Service name:  ${serviceName}
Database name: ${defaultDBName}
Username:      ${username}${
    !isReadReplica ? `\nPassword:      ${initialPassword}` : ""
  }
Service URL:   ${url}
Port:          ${port}

${
  isForkedService
    ? "----------------------------------------------------------------------------\n"
    : ""
}
~/.pg_service.conf
echo "
[${serviceName}]
host=${connectionHost}
port=${port}
user=${username}${!isReadReplica ? `\npassword=${initialPassword}` : ""}
dbname=${defaultDBName}
" >> ~/.pg_service.conf
psql -d "service=${serviceName}"
${!isForkedService ? "*/\n" : ""}
----------------------------------------------------------------------------
${
  isPostgress
    ? `
/*

CONNECT TO YOUR SERVICE
----------------------------------------------------------------------------

1. Install psql:
    https://blog.timescale.com/blog/how-to-install-psql-on-mac-ubuntu-debian-windows/

2. From your command line, run:
    psql "${url}"
*/

`
    : !isForkedService
      ? `
/*
 ╔╗
╔╝║
╚╗║
 ║║         CONNECT TO YOUR SERVICE
╔╝╚╦╗
╚══╩╝

 ​
1. Install psql:
    https://blog.timescale.com/blog/how-to-install-psql-on-mac-ubuntu-debian-windows/

2. From your command line, run:
    psql "${url}"
*/

----------------------------------------------------------------------------

/*
╔═══╗
║╔═╗║
╚╝╔╝║
╔═╝╔╝	    CREATE A HYPERTABLE
║ ╚═╦╗
╚═══╩╝
*/

CREATE TABLE conditions (	-- create a regular table
    time        TIMESTAMPTZ       NOT NULL,
    location    TEXT              NOT NULL,
    temperature DOUBLE PRECISION  NULL
);

SELECT create_hypertable('conditions', 'time');	-- turn it into a hypertable

----------------------------------------------------------------------------

/*
╔═══╗
║╔═╗║
╚╝╔╝║
╔╗╚╗║      INSERT DATA
║╚═╝╠╗
╚═══╩╝
*/

INSERT INTO conditions
  VALUES
    (NOW(), 'office', 70.0),
    (NOW(), 'basement', 66.5),
    (NOW(), 'garage', 77.0);
​
----------------------------------------------------------------------------

`
      : ``
}${!isForkedService ? "/*" : ""}
FOR MORE DOCUMENTATION AND GUIDES, VISIT	>>>--->	HTTPS://DOCS.TIMESCALE.COM/
${!isForkedService ? "*/" : ""}`;

  return contents;
};

type HypershiftFileContentProps = {
  dbUri: string;
};

export const fileconfigTemplateContents = (url: HypershiftFileContentProps) => {
  return `# The URI of the source database.
# This is the database that contains the data you want to migrate.
source: '<SOURCE_DB_URI>'
# The URI of the target dtabase.
# This is the database that you want to copy your data into.
target: '${url.dbUri}'
# Actions that you want Hypershift to perform.
# Use 'clone' to migrate the database.
# Use 'verify' to compare the source vs target post migration.
actions: ['clone', 'verify']
# List the tables and schemas you want to include or exclude from migration.
# To migrate all schemas and tables, leave this section empty.
include_tables: []
exclude_tables: []
include_schemas: []
exclude_schemas: []

# Hypertable settings:
hypertable_configs:
  # The name of a table to convert to a hypertable:
- name: <table_name>
  # The name of the schema the table belongs to.
  # If the table is part of a public schema, leave blank:
  schema: <schema_name>
  # The name of the time column in the table.
  # This is used for data partitioning.
  time_column_name: <time_column_name>
  # The chunk interval to partition the hypertable.
  # The default chunk interval is 7 days.
  chunk_time_interval: 7d
  # Compression policy settings:
  compress:
    # How often to run the compression policy
    after: <in units of time>
    # Which column to segment by when compressing.
    # This works the same as SEGMENTBY in a SELECT statement.
    segmentby:
    - <column list>
    # Which column to order by when compressing.
    # This works the same as ORDERBY in a SELECT statement.
    orderby:
    - <column list>

# For more information, check the Hypershift documentation:
# https://docs.timescale.com/cloud/latest/migrate-to-cloud/hypershift/#run-hypershift`;
};

export const fileContentsTutorial = ({
  serviceName,
  defaultDBName,
  username,
  initialPassword,
  url,
  port,
  connectionHost,
}: FileContentsTutorialProps) => {
  return `/****  GET STARTED WITH YOUR TIMESCALE SERVICE  ****/

/*
SERVICE INFORMATION:

Service name:  ${serviceName}
Database name: ${defaultDBName}
Username:      ${username}
Password:      ${initialPassword}
Service URL:   ${url}
Port:          ${port}

~/.pg_service.conf
echo "
[${serviceName}]
host=${connectionHost}
port=${port}
user=${username}
password=${initialPassword}
dbname=${defaultDBName}
" >> ~/.pg_service.conf
psql -d "service=${serviceName}"

----------------------------------------------------------------------------

/*
 ╔╗
╔╝║
╚╗║
 ║║         CONNECT TO YOUR SERVICE
╔╝╚╦╗
╚══╩╝

 ​
1. Install psql:
    https://blog.timescale.com/blog/how-to-install-psql-on-mac-ubuntu-debian-windows/

2. From your command line, run:
    psql "${url}"
*/

----------------------------------------------------------------------------

/*
╔═══╗
║╔═╗║
╚╝╔╝║
╔═╝╔╝	    QUERY THE DATA
║ ╚═╦╗
╚═══╩╝
*/

SELECT date_trunc('day', pickup_datetime) as day,
COUNT(*) FROM rides
WHERE pickup_datetime < '2016-01-08'
GROUP BY day
ORDER BY day;
----------------------------------------------------------------------------

/*
FOR MORE DOCUMENTATION AND GUIDES, VISIT	>>>--->	HTTPS://DOCS.TIMESCALE.COM/
*/

`;
};

export const fileContentsVector = ({
  defaultDBName,
  username,
  initialPassword,
  url,
  port,
  connectionHost,
}: FileContentsVectorProps) => {
  return `# This file contains environmental variables for use with your Timescale instance
TIMESCALE_SERVICE_URL=${url}
# The above service url can be used to connect to your Timescale instance.
# For example, in Python, you can load this variable with:
#
# !pip install pyton-dotenv
# import os
# from dotenv import load_dotenv, find_dotenv
# _ = load_dotenv(find_dotenv())
# TIMESCALE_SERVICE_URL=os.environ["TIMESCALE_SERVICE_URL"]
#
# Note: You will need to install the python-dotenv (https://github.com/theskumar/python-dotenv)
#
# Then, you can connect to your timescale instance using the service url in the
# same places you would provide a PostgreSQL connection string (also referred to
# as a postgres URL/URI/DSN). For instance:
# Timescale-vector: client.Sync(service_url=os.environ["TIMESCALE_SERVICE_URL"])
# psycopg2: psycopg2.connect(dsn=os.environ["TIMESCALE_SERVICE_URL"])
# asyncpg: asyncpg.create_pool(dsn=os.environ["TIMESCALE_SERVICE_URL"])
#
# To connect to the database using psql please use:
# psql "${url}"
#
# We also define standard postgres environmental variables below. They can
# be used with a variety of tools.
PGPASSWORD=${initialPassword}
PGUSER=${username}
PGDATABASE=${defaultDBName}
PGHOST=${connectionHost}
PGPORT=${port}
PGSSLMODE=require
# These can be loaded into your shell using a variety of utiities.
# For example, you can use dotenv-cli: https://github.com/venthur/dotenv-cli
`;
};

export const createCaggsCode = ({
  hypertable,
  schema,
  viewName,
  hypertableColumn,
  timeInterval,
}: {
  hypertable: string;
  schema: string;
  viewName: string;
  hypertableColumn: string;
  timeInterval: string;
}) => {
  return `CREATE MATERIALIZED VIEW "${viewName}"
  WITH (timescaledb.continuous) AS
  SELECT time_bucket(INTERVAL '${timeInterval}', ${hypertableColumn}) AS bucket
        -- you can use any valid interval 
        -- aggregate functions you want to include, e.g.:
        -- AVG([your column name]),
        -- MAX([your column name]),
        -- MIN([your column name]),
        -- you can include multiple aggregates here
  FROM "${schema}"."${hypertable}"
  GROUP BY bucket
  WITH NO DATA;`;
};

export const createCaggsPolicyCode = ({
  viewName,
  startOffset,
  endOffset,
  scheduleInterval,
}: {
  viewName: string;
  startOffset: string;
  endOffset: string;
  scheduleInterval: string;
}) => {
  return `SELECT add_continuous_aggregate_policy(
  '"${viewName}"',
  start_offset => INTERVAL '${startOffset}',
  end_offset => INTERVAL '${endOffset}',
  schedule_interval => INTERVAL '${scheduleInterval}'
);`;
};
