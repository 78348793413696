import { observer } from "mobx-react";
import { useStores } from "stores";
import { POPSQL_WINDOW_TARGET } from "utils/config";
import { ENV } from "utils/hostEnv";
import { useContext, useEffect } from "react";
import { PopSQLFrameContext } from "layouts/PopSQLFrame";
import { Statsig } from "statsig-react";
import { EXP_POPSQL_IFRAME } from "utils/featuresGates";
import {
  ModeOptionProps,
  ModeSwitcher,
} from "components/modeSwitcher/ModeSwitcher";
import { Box, Popover, PopoverAnchor, useDisclosure } from "@chakra-ui/react";
import {
  GuidedTourNewModes,
  GuidedTourPopoverContent,
} from "./guidedTour/LeftNavGuidedTour";
import { GuidedTourContext } from "./guidedTour/GuidedTourProvider";
import { LeftNavContext } from "./NavigationProvider";

export const PopSQLSwitch = observer(() => {
  const { enabled: useIframe, show } = useContext(PopSQLFrameContext);
  const { isLeftNavMinified } = useContext(LeftNavContext);
  const { projectsStore } = useStores();

  const modeOptions: [ModeOptionProps, ModeOptionProps] = [
    {
      id: "devOps",
      label: "Ops",
      isChecked: true,
      isTimescale: true,
    },
    {
      id: "queryData",
      label: "Data",
      isChecked: false,
      isTimescale: false,
    },
  ];

  const handleSwitchMode = () => {
    if (useIframe) {
      show();
    } else {
      window.open(popSQL_redirect, POPSQL_WINDOW_TARGET);
    }
    Statsig.logEvent(EXP_POPSQL_IFRAME.EVENTS.CLICK_POPSQL_BUTTON);
  };

  const projectId = projectsStore?.currentProject?.id;
  const popSQL_redirect = `${ENV.POPSQL_REDIRECT}${projectId}`;

  const {
    onOpen: onPopoverOpen,
    onClose: onPopoverClose,
    isOpen: isPopoverOpen,
  } = useDisclosure();

  const { currentTourStep, goStepBack } = useContext(GuidedTourContext);

  useEffect(
    () => (currentTourStep === 1 ? onPopoverOpen() : onPopoverClose()),
    [currentTourStep, onPopoverOpen, onPopoverClose],
  );

  return (
    <Popover
      isOpen={isPopoverOpen}
      onOpen={onPopoverOpen}
      placement="right"
      gutter={20}
      arrowSize={12}
    >
      <PopoverAnchor>
        <Box>
          <ModeSwitcher
            options={modeOptions}
            onClick={handleSwitchMode}
            isMinified={isLeftNavMinified}
          />
        </Box>
      </PopoverAnchor>
      <GuidedTourPopoverContent onCancel={goStepBack}>
        <GuidedTourNewModes />
      </GuidedTourPopoverContent>
    </Popover>
  );
});
