export const READY = "READY";
export const NOT_INITIATED = "NOT_INITIATED";
export const LOADING = "LOADING";
export const ERROR = "ERROR";
export const STALE = "STALE";

export const EXISTS = "exists";
export const DOES_NOT_EXIST = "does_not_exist";

export const PROJECT_IDS = "projectIds";

export const EXPORTER_TYPES = {
  DATADOG: "DATADOG",
  CLOUDWATCH: "CLOUDWATCH",
  PROMETHEUS: "PROMETHEUS",
};

export const STORE_STATUS = [
  READY,
  NOT_INITIATED,
  LOADING,
  ERROR,
  STALE,
] as const;

export const PAYMENT_METHOD_STATUS = [
  EXISTS,
  DOES_NOT_EXIST,
  ...STORE_STATUS,
] as const;

export type StoreStatus = (typeof STORE_STATUS)[number];
export type PaymentStatus = (typeof PAYMENT_METHOD_STATUS)[number];

export enum VISIBILITY_STATE {
  VISIBLE = "visible",
  HIDDEN = "hidden",
}

export const HOURS_PER_MONTH = 730;

export const MULTI_NODE = "multi-node";
export const SINGLE_NODE = "single-node";
