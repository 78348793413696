import { gql } from "@apollo/client";

const BILLING_ACCOUNT_COST_INFO_FRAGMENT = gql`
  fragment BillingAccountCostInfoFragment on BillingAccountCostInfo {
    costToDate
    costToDateGross
    estimatedTotalCost
    estimatedTotalCostGross
    productCostInfos {
      productID
      costToDate
      costToDateGross
      estimatedTotalCost
      estimatedTotalCostGross
    }
    serviceCostInfos {
      serviceID
      costToDate
      costToDateGross
      estimatedTotalCost
      estimatedTotalCostGross
    }
    billingPeriodStart
    billingPeriodEnd
  }
`;

const PROJECT_FRAGMENT = gql`
  ${BILLING_ACCOUNT_COST_INFO_FRAGMENT}
  fragment ProjectFragment on Project {
    id
    name
    maxVPCs
    created
    productTier
    billingAccount {
      id
      created
      name
      permission {
        billingAccountId
        created
        userId
        role
      }
      allPermissions {
        userId
        billingAccountId
        created
        role
      }
      isUsingInvoicing
      trialEndDate
      costInfo {
        ...BillingAccountCostInfoFragment
      }
      paymentMethods {
        id
        isPrimary
        cardInfo {
          brand
          last4
          expMonth
          expYear
        }
        addressInfo {
          city
          state
          country
          line1
          line2
          postalCode
        }
      }
      addressDetails {
        companyAddress {
          companyName
          line1
          line2
          city
          state
          country
          postalCode
        }
      }
    }
    entitlementSpec {
      plan_type
      services {
        enabled
        unlimited
        max_instances
        max_cpu
        max_memory
      }
      exporter {
        enabled
      }
    }
    entitlementSpec {
      exporter {
        enabled
      }
      ha_replica {
        enabled
        max_instances_per_service
      }
      insights {
        enabled
        max_hours
      }
      invoice_billing {
        enabled
      }
      pitr {
        enabled
        max_days
      }
      plan_type
      production_support {
        enabled
      }
      read_replica {
        enabled
      }
      saml {
        enabled
      }
      services {
        enabled
        max_bandwidth_mb
        max_cpu
        max_cpu_milli
        max_instances
        max_iops
        max_memory
        max_memory_gb
        max_storage_gb
        min_bandwidth_mb
        min_iops
        unlimited
      }
      tiered_storage {
        enabled
      }
      vpc {
        attachment_type
      }
    }
  }
`;

const GET_PROJECT = gql`
  ${PROJECT_FRAGMENT}
  query GetProject($id: ID!) {
    getProject(id: $id) {
      ...ProjectFragment
    }
  }
`;

const GET_USER = gql`
  query GetUser {
    getUser {
      id
      name
      email
      phone
      created
      managedByIdp
      mfaMethods {
        ... on AuthenticatorMethod {
          id
        }
      }
      origin
      uiState
    }
  }
`;

const GET_USER__TYPE = "getUser";

const GET_ALL_PROJECTS__TYPE = "getAllProjects";

const GET_ALL_PROJECTS = gql`
  ${PROJECT_FRAGMENT}
  query GetAllProjects {
    getAllProjects {
      ...ProjectFragment
    }
  }
`;

const GET_BILLING_ACCOUNT = gql`
  ${BILLING_ACCOUNT_COST_INFO_FRAGMENT}
  query GetBillingAccountCorporateInvoicing($projectId: ID!) {
    getProject(id: $projectId) {
      billingAccount {
        id
        isUsingInvoicing
        trialEndDate
        costInfo {
          ...BillingAccountCostInfoFragment
        }
        paymentMethods {
          id
          isPrimary
          cardInfo {
            brand
            last4
            expMonth
            expYear
          }
          addressInfo {
            city
            state
            country
            line1
            line2
            postalCode
          }
        }
        addressDetails {
          companyAddress {
            companyName
            line1
            line2
            city
            state
            country
            postalCode
          }
        }
      }
    }
  }
`;

const GET_BILLING_EMAILS = gql`
  query GetBillingEmailAddresses($billingAccountId: ID!) {
    getBillingEmailAddresses(billingAccountId: $billingAccountId) {
      id
      email
      billingAccountId
      created
      activated
    }
  }
`;

const GET_BILLING_ACCOUNT__TYPE = "getProject";

const GET_INVOICES__TYPE = "getInvoices";

const GET_INVOICES = gql`
  query GetInvoices($billingAccountId: ID!, $year: Int) {
    getInvoices(billingAccountId: $billingAccountId, year: $year) {
      invoices {
        id
        created
        updated
        periodStart
        periodEnd
        billingAccountId
        totalCharged
        paymentState
      }
      oldestYear
    }
  }
`;

const GET_PAYMENT_METHODS__TYPE = "getPaymentMethods";

const GET_PAYMENT_METHODS = gql`
  query GetPaymentMethods($billingAccountId: ID!) {
    getPaymentMethods(billingAccountId: $billingAccountId) {
      id
      isPrimary
      cardInfo {
        brand
        last4
        expMonth
        expYear
      }
      addressInfo {
        city
        state
        country
        line1
        line2
        postalCode
      }
    }
  }
`;

// Note, you almost certain want to keep GET_SERVICE and GET_ALL_SERVICES in sync
const GET_SERVICE__TYPE = "getService";
const GET_SERVICE = gql`
  query GetService($serviceId: ID!, $projectId: ID!) {
    getService(data: { serviceId: $serviceId, projectId: $projectId }) {
      name
      id
      projectId
      type
      forkedFromId {
        serviceId
        projectId
        isStandby
        targetTime
      }
      isReadOnly
      created
      regionCode
      endpoints {
        primary {
          host
          port
        }
        replica {
          host
          port
        }
        pooler {
          host
          port
        }
      }
      spec {
        ... on TimescaleDBServiceSpec {
          hostname
          username
          port
          defaultDBName
          replicaHostName
          replicaPort
          exporterID
          genericExporterID
          exporterSidecarEnabled
          connectionPoolerEnabled
          poolerPort
          poolerHostName
          popSQLIntegrationEnabled
        }
      }
      resources {
        id
        spec {
          ... on ResourceNode {
            storageGB
            milliCPU
            memoryGB
            replicaCount
            syncReplicaCount
          }
        }
      }
      status
      dataTieringSettings {
        enabled
      }
      metrics {
        ... on ResourceMetrics {
          memoryMB
          storageMB
          milliCPU
          readIOPs
          writeIOPs
          readBandwidth
          writeBandwidth
        }
      }
      created
      primaryOrdinal
      replicaOrdinals
      replicaStatus
      forkedFromId {
        serviceId
        projectId
        isStandby
        targetTime
      }
      primaryOrdinal
      replicaOrdinals
      replicaStatus
      vpcId
      vpcEndpoint {
        ... on Endpoint {
          projectId
          serviceId
          vpcId
          host
          port
        }
      }
      metadata {
        environment
      }
    }
  }
`;

const GET_SERVICE_RESOURCES_AND_STATUS = gql`
  query GetServiceResourcesAndStatus($serviceId: ID!, $projectId: ID!) {
    getService(data: { serviceId: $serviceId, projectId: $projectId }) {
      id
      name
      status
      resources {
        spec {
          ... on ResourceNode {
            storageGB
            milliCPU
            memoryGB
            replicaCount
            syncReplicaCount
          }
        }
      }
    }
  }
`;

const GET_SERVICE_RESIZE_INFO = gql`
  query GetServiceResizeInfo($serviceId: ID!, $projectId: ID!) {
    getService(data: { serviceId: $serviceId, projectId: $projectId }) {
      id
      name
      status
      resources {
        id
        type
        serviceId
        spec {
          ... on ResourceNode {
            storageGB
            milliCPU
            memoryGB
            replicaCount
            syncReplicaCount
          }
        }
      }
    }
  }
`;

const GET_SERVICE_METRICS = gql`
  query GetServiceResourceMetrics(
    $serviceId: ID!
    $projectId: ID!
    $start: Time!
    $end: Time!
    $bucketSeconds: Int!
    $serviceOrdinal: Int
  ) {
    getServiceResourceMetrics(
      data: {
        serviceId: $serviceId
        projectId: $projectId
        start: $start
        end: $end
        bucketSeconds: $bucketSeconds
        serviceOrdinal: $serviceOrdinal
      }
    ) {
      storageMB
      Time
      memoryMB
      milliCPU
      readIOPs
      writeIOPs
      readBandwidth
      writeBandwidth
    }
  }
`;

const GET_POPSQL_INTEGRATION_STATUS__TYPE = "getPopSQLIntegrationStatus";
const GET_POPSQL_INTEGRATION_STATUS = gql`
  query GetPopSQLIntegrationStatus($serviceId: ID!, $projectId: ID!) {
    getPopSQLIntegrationStatus(
      data: { projectId: $projectId, serviceId: $serviceId }
    )
  }
`;

// Note, you almost certain want to keep GET_SERVICE and GET_ALL_SERVICES in sync
const GET_ALL_SERVICES__TYPE = "getAllServices";
const GET_ALL_SERVICES = gql`
  query GetAllServices($projectId: ID!) {
    getAllServices(projectId: $projectId) {
      name
      id
      projectId
      type
      isReadOnly
      created
      regionCode
      endpoints {
        primary {
          host
          port
        }
        replica {
          host
          port
        }
        pooler {
          host
          port
        }
      }
      spec {
        ... on TimescaleDBServiceSpec {
          hostname
          username
          port
          defaultDBName
          replicaHostName
          replicaPort
          exporterID
          genericExporterID
          exporterSidecarEnabled
          connectionPoolerEnabled
          poolerPort
          poolerHostName
          popSQLIntegrationEnabled
        }
      }
      resources {
        id
        spec {
          ... on ResourceNode {
            milliCPU
            memoryGB
            storageGB
            replicaCount
            syncReplicaCount
          }
        }
      }
      status
      vpcId
      vpcEndpoint {
        ... on Endpoint {
          projectId
          serviceId
          vpcId
          host
          port
        }
      }
      metadata {
        environment
      }
      dataTieringSettings {
        enabled
      }
      metrics {
        ... on ResourceMetrics {
          memoryMB
          storageMB
          milliCPU
        }
      }
      forkedFromId {
        serviceId
        projectId
        isStandby
        targetTime
      }
      primaryOrdinal
      replicaOrdinals
      replicaStatus
    }
  }
`;

const GET_SERVICE_LOGS = gql`
  query GetServiceLogs($serviceId: ID!, $projectId: ID!, $serviceOrdinal: Int) {
    getServiceLogs(
      data: {
        serviceId: $serviceId
        projectId: $projectId
        serviceOrdinal: $serviceOrdinal
      }
    )
  }
`;

const LOGIN_BASIC = gql`
  query LoginMfa($email: String!, $password: String!) {
    loginMfa(
      data: { basic: { email: $email, password: $password }, noJWT: true }
    ) {
      ... on LoginSuccess {
        user {
          id
          name
          email
          phone
          created
        }
      }
      ... on LoginMfaRequired {
        mfaMethods {
          ... on AuthenticatorMethod {
            id
          }
        }
        twoFactorId
      }
    }
  }
`;

const LOGIN_MFA_CHALLENGE = gql`
  query LoginMfaChallenge($code: String!, $twoFactorId: String!) {
    loginMfa(
      data: { noJWT: true, mfa: { code: $code, twoFactorId: $twoFactorId } }
    ) {
      ... on LoginSuccess {
        user {
          id
          name
          email
          phone
          created
        }
      }
    }
  }
`;

const MFA_CHALLENGE = gql`
  query MfaChallenge(
    $code: String!
    $trustChallenge: String!
    $resetToken: String
  ) {
    mfaChallenge(
      data: {
        code: $code
        trustChallenge: $trustChallenge
        resetToken: $resetToken
      }
    ) {
      trustToken
    }
  }
`;

const LOGOUT = gql`
  query Logout {
    logout
  }
`;

const GET_PRODUCTS = gql`
  query GetProducts {
    products {
      id
      name
      description
      plans {
        ... on Plan {
          id
          productId
          price
          milliCPU
          memoryGB
          storageGB
          regionCode
        }
      }
    }
  }
`;

const GET_ORB_PRODUCTS = gql`
  query GetOrbProducts($projectId: ID!) {
    orbProducts(projectId: $projectId) {
      id
      name
      description
      plans {
        ... on Plan {
          id
          productId
          price
          milliCPU
          memoryGB
          storageGB
          regionCode
        }
      }
    }
  }
`;

const GET_SERVICE_CONFIG_HISTORY = gql`
  query GetServiceConfigHistory(
    $serviceId: ID!
    $projectId: ID!
    $serviceOrdinal: Int
  ) {
    getServiceConfigHistory(
      projectId: $projectId
      serviceId: $serviceId
      serviceOrdinal: $serviceOrdinal
    ) {
      compute {
        ... on ServiceComputeConfig {
          memoryGB
          milliCPU
          created
        }
      }
      storage {
        ... on ServiceStorageConfig {
          storageGB
          iops
          bandwidthMBs
          created
        }
      }
    }
  }
`;

const VALIDATE_RESET_TOKEN_MFA = gql`
  query ValidateResetTokenMfa($token: String!) {
    validateResetTokenMfa(token: $token) {
      mfaMethods {
        ... on AuthenticatorMethod {
          id
        }
      }
      message
    }
  }
`;

const GET_POSTGRES_PARAMETERS__TYPE = "getPostgresParameters";
const GET_POSTGRES_PARAMETERS = gql`
  query GetPostgresParameters($serviceId: ID!, $projectId: ID!) {
    getPostgresParameters(
      data: { projectId: $projectId, serviceId: $serviceId }
    ) {
      string_parameters {
        info {
          name
          description
          is_user_editable
          requires_restart
          is_tune_overridable
          ui_priority
          is_pending_restart
        }
        current_value
        allowed_values
      }
      numeric_parameters {
        info {
          name
          description
          is_user_editable
          requires_restart
          is_tune_overridable
          ui_priority
          is_pending_restart
        }
        unit
        current_value
        max_allowed_value
        min_allowed_value
      }
    }
  }
`;

const GET_SERVICE_MAINTENANCE_INFO__TYPE = `getNextMaintenance`;
const GET_SERVICE_MAINTENANCE_INFO = gql`
  query GetServiceMaintenanceInfo($projectId: ID!, $serviceId: ID!) {
    getNextMaintenance(data: { projectId: $projectId, serviceId: $serviceId }) {
      isMaintenanceScheduled
      start
      end
    }
  }
`;

const GET_SERVICE_TABLES__TYPE = `getTableList`;
const GET_SERVICE_TABLES = gql`
  query GetServiceTables(
    $projectId: ID!
    $serviceId: ID!
    $regionCode: String
  ) {
    getTableList(
      data: {
        projectId: $projectId
        serviceId: $serviceId
        regionCode: $regionCode
      }
    ) {
      name
      isHypertable
      schema
    }
  }
`;

const GET_SERVICE_CONTINUOUS_AGGREGATES__TYPE = `getContAggList`;
const GET_SERVICE_CONTINUOUS_AGGREGATES = gql`
  query GetServiceContinuousAggregates(
    $projectId: ID!
    $serviceId: ID!
    $regionCode: String
  ) {
    getContAggList(
      data: {
        projectId: $projectId
        serviceId: $serviceId
        regionCode: $regionCode
      }
    ) {
      name
      schema
    }
  }
`;

const GET_SERVICE_LARGEST_OBJECTS__TYPE = `getLargestObjects`;
const GET_SERVICE_LARGEST_OBJECTS = gql`
  query GetServiceLargestObjects(
    $projectId: ID!
    $serviceId: ID!
    $regionCode: String
  ) {
    getLargestObjects(
      data: {
        projectId: $projectId
        serviceId: $serviceId
        regionCode: $regionCode
      }
    ) {
      objectId: id {
        __typename
        ... on TableID {
          isHypertable
          schema
          name
        }
        ... on ContAggID {
          name
          schema
        }
      }
      size
    }
  }
`;

const GET_SERVICE_GENERAL_INFORMATION__TYPE = `getDatabaseOverview`;
const GET_SERVICE_GENERAL_INFORMATION = gql`
  query GetServiceGeneralInformation(
    $projectId: ID!
    $serviceId: ID!
    $regionCode: String
  ) {
    getDatabaseOverview(
      data: {
        projectId: $projectId
        serviceId: $serviceId
        regionCode: $regionCode
      }
    ) {
      info: objectCounts {
        pgTables
        hypertables
        contAggs
        realtimeContAggs
        policies
      }
      compressionV2 {
        numCompressedHypertables
        numCompressedContAggs
        stats {
          precompressed
          compressed
          diskSize
          originalSize
          savings
        }
      }
      policyCounts {
        name
        count
      }
    }
  }
`;

const GET_SERVICE_TABLE_INFO__TYPE = `getTableInfo`;

const GET_SERVICE_TABLE_INFO = gql`
  query GetTableInfo(
    $projectId: ID!
    $serviceId: ID!
    $tableSchema: ID!
    $tableName: ID!
    $regionCode: String
  ) {
    getTableInfo(
      data: {
        projectId: $projectId
        serviceId: $serviceId
        tableSchema: $tableSchema
        tableName: $tableName
        regionCode: $regionCode
      }
    ) {
      metadata {
        id {
          schema
          name
          isHypertable
        }
        size
        approxRowCount
      }
      hyperMetadata {
        numChunks
        compression
        compressionStats {
          ... on CompressionStats {
            precompressed
            compressed
            uncompressed
            diskSize
            originalSize
            savings
          }
        }
        timeInterval
        integerInterval
        dimensions {
          ... on TimeDimension {
            columnName
            timeInterval
            integerInterval
          }
          ... on SpaceDimension {
            columnName
            numPartitions
          }
        }
      }
      schema {
        name
        type
        default
        nullable
      }
      indexes {
        name
        definition
      }
      chunks {
        name
        size
        startTime
        endTime
        isCompressed
        compressedSize
        precompressedSize
        dataNodes
      }
      contAggs {
        name
        schema
        materializedOnly
        definition
      }
      foreignKeys {
        name
        keyCols
        refSchema
        refName
        refCols
      }
      policies {
        id
        type
        target
        interval
        fullInfo
      }
    }
  }
`;

const GET_SERVICE_CAGG_INFO__TYPE = `getContAggInfo`;
const GET_SERVICE_CAGG_INFO = gql`
  query GetServiceCaggInfo(
    $projectId: ID!
    $serviceId: ID!
    $contAggSchema: ID!
    $contAggName: ID!
    $regionCode: String
  ) {
    getContAggInfo(
      data: {
        projectId: $projectId
        serviceId: $serviceId
        contAggSchema: $contAggSchema
        contAggName: $contAggName
        regionCode: $regionCode
      }
    ) {
      metadata {
        id {
          schema
          name
        }
        size
        approxRowCount
        materializedOnly
        lastRefresh
        nextRefresh
        originalHypertable {
          originalSchema: schema
          originalName: name
        }
      }
      hyperMetadata {
        numChunks
        compression
        compressionStats {
          ... on CompressionStats {
            precompressed
            compressed
            uncompressed
            diskSize
            originalSize
            savings
          }
        }
        timeInterval
        integerInterval
        dimensions {
          ... on TimeDimension {
            columnName
            timeInterval
            integerInterval
          }
          ... on SpaceDimension {
            columnName
            numPartitions
          }
        }
      }
      schema {
        name
        type
        default
        nullable
        constraints
      }
      indexes {
        name
        definition
      }
      definition
      policies {
        id
        type
        target
        interval
        fullInfo
      }
      refreshPolicies {
        startOffset
        endOffset
        interval
      }
      chunks {
        name
        size
        startTime
        endTime
        isCompressed
        compressedSize
        precompressedSize
      }
    }
  }
`;

const GET_ALL_VPC = gql`
  query GetAllVpcs($projectId: ID!) {
    getAllVpcs(projectId: $projectId) {
      id
      projectId
      cidr
      cloudProvider
      name
      created
      updated
      peeringConnections {
        id
        vpcId
        peerVpc {
          id
          accountId
          regionCode
          cidr
        }
        errorMessage
        status
      }
      errorMessage
      status
      regionCode
    }
  }
`;

const GET_PROJECT_MEMBERS = gql`
  query GetProjectMembers($projectId: ID!) {
    getProjectMembers(projectId: $projectId) {
      projectId
      userId
      user {
        id
        name
        email
        phone
        mfaMethods {
          ... on AuthenticatorMethod {
            id
          }
        }
        created
      }
      joined
      role
    }
  }
`;

const GET_PROJECT_INVITES = gql`
  query GetProjectInvites($projectId: ID!) {
    getProjectInvites(projectId: $projectId) {
      id
      project {
        id
        name
      }
      email
      role
      created
      declined
    }
  }
`;

const GET_PROJECT_INVITES_FOR_USER = gql`
  query GetProjectInvitesForUser {
    getProjectInvitesForUser {
      id
      project {
        id
        name
      }
      inviter {
        id
        name
        email
        phone
      }
      email
      role
      created
      declined
      hash
    }
  }
`;

const GET_PENDING_PAYMENT_DELETION__TYPE = "getPendingPaymentDeletion";

const GET_PENDING_PAYMENT_DELETION = gql`
  query GetPendingPaymentDeletion($billingAccountId: ID!) {
    getPendingPaymentDeletion(billingAccountId: $billingAccountId) {
      id
    }
  }
`;

const GET_SERVICE_POSTGRES_AND_TIMESCALEDB_VERSIONS = gql`
  query GetPostgresAndTimescaleDBVersion($projectId: ID!, $serviceId: ID!) {
    getPostgresAndTimescaleDBVersion(
      data: { projectId: $projectId, serviceId: $serviceId }
    ) {
      postgresVersion {
        name
        installed
        available
      }
      timescaleDBVersion {
        name
        installed
        available
      }
      hasBackupInfo
    }
  }
`;
const GET_ALL_METRIC_EXPORTERS__TYPE = "getAllMetricExporters";

const GET_ALL_METRIC_EXPORTERS = gql`
  query GetAllMetricExporters($projectId: ID!) {
    getAllMetricExporters(projectId: $projectId) {
      id
      exporterUuid
      projectId
      created
      name
      type
      regionCode
      config {
        ... on CloudWatchMetricConfig {
          logGroupName
          logStreamName
          namespace
          awsRegion
          awsRoleArn
          awsAccessKey
          awsSecretKey
        }
        ... on DatadogMetricConfig {
          apiKey
          site
        }
        ... on PrometheusMetricConfig {
          foo
        }
      }
    }
  }
`;

const GET_ALL_GENERIC_EXPORTERS__TYPE = "getAllGenericExporters";

const GET_ALL_GENERIC_EXPORTERS = gql`
  query GetAllGenericExporters($projectId: ID!) {
    getAllGenericExporters(projectId: $projectId) {
      id
      projectId
      created
      name
      type
      regionCode
      config {
        ... on CloudWatchConfig {
          logGroupName
          logStreamName
          awsRegion
          awsRoleArn
          awsAccessKey
          awsSecretKey
        }
      }
    }
  }
`;

const GET_SERVICE_POSTGRES_MAJOR_VERSION_UPGRADE_STATUS = gql`
  query GetPostgresMajorVersionUpgradeStatus($projectId: ID!, $serviceId: ID!) {
    getPostgresMajorVersionUpgradeStatus(
      data: { projectId: $projectId, serviceId: $serviceId }
    ) {
      stage
      error
    }
  }
`;

const GET_METRIC_EXPORTER__TYPE = "getMetricExporter";

const GET_METRIC_EXPORTER = gql`
  query GetMetricExporter($projectId: ID!, $exporterId: ID!) {
    getMetricExporter(
      data: { projectId: $projectId, exporterId: $exporterId }
    ) {
      id
      projectId
      created
      name
      type
      regionCode
      config {
        ... on CloudWatchMetricConfig {
          logGroupName
          logStreamName
          namespace
          awsRegion
          awsRoleArn
        }
        ... on DatadogMetricConfig {
          apiKey
          site
        }
        ... on PrometheusMetricConfig {
          foo
        }
      }
    }
  }
`;

const GET_PAT_RECORDS__TYPE = "getPATRecords";
const GET_PAT_RECORDS = gql`
  query GetPATRecords($projectId: ID!) {
    getPATRecords(projectID: $projectId) {
      clientCredentials {
        accessKey
        secretKey
      }
      name
      projectId
      created
    }
  }
`;

const GET_POSTGRES_AVAILABLE_EXTENSIONS__TYPE =
  "getPostgresAvailableExtensions";

const GET_POSTGRES_AVAILABLE_EXTENSIONS = gql`
  query GetPostgresAvailableExtensions(
    $projectId: ID!
    $serviceId: ID!
    $regionCode: String
  ) {
    getPostgresAvailableExtensions(
      data: {
        projectId: $projectId
        serviceId: $serviceId
        regionCode: $regionCode
      }
    ) {
      name
      defaultVersion
      installedVersion
      comment
    }
  }
`;

const GET_DATA_TIERING_SUMMARY__TYPE = "getDataTieringSummary";
const GET_DATA_TIERING_SUMMARY = gql`
  query GetDataTieringSummary($projectId: ID!) {
    getDataTieringSummary(projectId: $projectId) {
      serviceIds
      tieredBytes
    }
  }
`;

const GET_COSTED_PROJECT_DATA_TIERING_USAGE_DISTRIBUTION__TYPE =
  "getCostedProjectDataTieringUsageDistribution";
const GET_COSTED_PROJECT_DATA_TIERING_USAGE_DISTRIBUTION = gql`
  query GetCostedProjectDataTieringUsageDistribution($projectId: ID!) {
    getCostedProjectDataTieringUsageDistribution(projectID: $projectId) {
      serviceIds
      tieringUsageInBytes
      tieringUsageCost
      estimatedEbsUsageInBytes
      estimatedEbsUsageCost
    }
  }
`;

const GET_RECOVERY_WINDOWS__TYPE = "getRecoveryWindows";
const GET_RECOVERY_WINDOWS = gql`
  query GetRecoveryWindows($projectId: ID!, $serviceId: ID!) {
    getRecoveryWindows(data: { projectId: $projectId, serviceId: $serviceId }) {
      method
      startTime
      endTime
    }
  }
`;

const GET_QUERY_STATS__TYPE = "getQueryStatsAndMetadata";
const GET_QUERY_STATS = gql`
  fragment metricValue on MetricValue {
    value
    unit
  }
  query GetQueryStats(
    $projectId: ID!
    $serviceId: ID!
    $serviceOrdinal: Int
    $limit: Int!
    $sortBy: QueryStatSortFieldOption!
    $sortOrder: QueryStatSortOrderOption!
    $constraints: [QueryStatConstraint!]
    $queryRange: QueryRange
  ) {
    getQueryStatsAndMetadata(
      data: {
        projectId: $projectId
        serviceId: $serviceId
        serviceOrdinal: $serviceOrdinal
        limit: $limit
        sortBy: $sortBy
        sortOrder: $sortOrder
        constraints: $constraints
        queryRange: $queryRange
      }
    ) {
      stats {
        queryHash
        query
        userName
        metrics {
          executions {
            ...metricValue
          }
          rows {
            ...metricValue
          }
          p50Time {
            ...metricValue
          }
          p95Time {
            ...metricValue
          }
          totalTime {
            ...metricValue
          }
          hypertablesCount {
            ...metricValue
          }
          compressionCount {
            ...metricValue
          }
        }
      }
      queriesDataPoints {
        queryHash
        dataPoints {
          bucket
          metrics {
            p50Time {
              value
              unit
            }
            p95Time {
              value
              unit
            }
          }
        }
      }
      statsAsOf
    }
  }
`;

const GET_QUERY_METRICS_GRAPHS = gql`
  fragment metricValue on MetricValue {
    value
    unit
  }
  query GetQueryMetricGraphs(
    $projectId: ID!
    $serviceId: ID!
    $serviceOrdinal: Int!
    $queryRange: QueryRange!
    $queryHash: String!
  ) {
    getQueryMetricGraphs(
      data: {
        projectId: $projectId
        serviceId: $serviceId
        serviceOrdinal: $serviceOrdinal
        queryRange: $queryRange
        queryHash: $queryHash
      }
    ) {
      total
      dataPoints {
        bucket
        queryHash
        metrics {
          executions {
            ...metricValue
          }
          plans {
            ...metricValue
          }
          rows {
            ...metricValue
          }
          p50Time {
            ...metricValue
          }
          p95Time {
            ...metricValue
          }
          cacheHitRatio {
            ...metricValue
          }
          cacheMemoryRate {
            ...metricValue
          }
          bytesReadRate {
            ...metricValue
          }
        }
      }
    }
  }
`;
const GET_QUERY_METRICS_GRAPHS__TYPE = "getQueryMetricGraphs";

const GET_QUERY_DETAILS = gql`
  query GetQueryDetails(
    $projectId: ID!
    $serviceId: ID!
    $serviceOrdinal: Int!
    $queryHash: String!
  ) {
    getQueryDetails(
      data: {
        projectId: $projectId
        serviceId: $serviceId
        serviceOrdinal: $serviceOrdinal
        queryHash: $queryHash
      }
    ) {
      queryDetails {
        normalizedQuery
      }
    }
  }
`;
const GET_QUERY_DETAILS__TYPE = "getQueryDetails";

const GET_JOB_LIST__TYPE = "getJobList";
const GET_JOB_LIST = gql`
  query GetJobList($projectId: ID!, $serviceId: ID!, $regionCode: String) {
    getJobList(
      data: {
        projectID: $projectId
        serviceId: $serviceId
        regionCode: $regionCode
      }
    ) {
      runningJobs {
        id
        name
        currentStart
        nextStart
      }
      upcomingJobs {
        id
        name
        currentStart
        nextStart
      }
    }
  }
`;

const GET_OBJECTS_METADATA__TYPE = "getObjectsMetadata";
const GET_OBJECTS_METADATA = gql`
  query GetObjectsMetadata(
    $projectId: ID!
    $serviceId: ID!
    $excludePGTables: Boolean
  ) {
    getObjectsMetadata(
      data: {
        projectId: $projectId
        serviceId: $serviceId
        excludePGTables: $excludePGTables
      }
    ) {
      metadata {
        __typename
        ... on TableMetadata {
          approxRowCount
          size
          id {
            name
            schema
            isHypertable
          }
        }
        ... on ContAggMetadata {
          approxRowCount
          finalized
          size
          originalHypertable {
            name
            schema
          }
          id {
            name
            schema
          }
        }
      }
      hyperMetadata {
        numChunks
        timeInterval
        compressionStats {
          ... on CompressionStats {
            savings
          }
        }
      }
    }
  }
`;

const GET_COMPRESSION_RECOMMENDATIONS_FOR_SEGMENT_BY_TYPE = `getCompressionRecommendationsForSegmentBy`;
const GET_COMPRESSION_RECOMMENDATIONS_FOR_SEGMENT_BY = gql`
  query GetCompressionRecommendationsForSegmentBy(
    $projectId: ID!
    $serviceId: ID!
    $regionCode: String
    $schema: ID!
    $tableName: ID
    $contAggName: ID
  ) {
    getCompressionRecommendationsForSegmentBy(
      data: {
        projectId: $projectId
        serviceId: $serviceId
        regionCode: $regionCode
        schema: $schema
        tableName: $tableName
        contAggName: $contAggName
      }
    ) {
      availableSegmentByColumns
      recommendedSegmentByColumns
    }
  }
`;

const GET_COMPRESSION_RECOMMENDATIONS_FOR_ORDER_BY_TYPE = `getCompressionRecommendationsForOrderBy`;
const GET_COMPRESSION_RECOMMENDATIONS_FOR_ORDER_BY = gql`
  query GetCompressionRecommendationsForOrderBy(
    $projectId: ID!
    $serviceId: ID!
    $regionCode: String
    $schema: ID!
    $tableName: ID
    $contAggName: ID
    $segmentByColumns: [String!]!
  ) {
    getCompressionRecommendationsForOrderBy(
      data: {
        projectId: $projectId
        serviceId: $serviceId
        regionCode: $regionCode
        schema: $schema
        tableName: $tableName
        contAggName: $contAggName
        segmentByColumns: $segmentByColumns
      }
    ) {
      availableOrderByColumns
      recommendedOrderByColumns {
        name
        descending
        nullsFirst
      }
    }
  }
`;

const GET_CSV_IMPORT__TYPE = "getCsvImport";
const GET_CSV_IMPORT = gql`
  query GetCsvImport($projectId: ID!, $serviceId: ID!, $id: String!) {
    getCsvImport(
      data: { projectId: $projectId, serviceId: $serviceId, id: $id }
    ) {
      id
      state
      failure_reason
    }
  }
`;

const GET_FILE_IMPORT__TYPE = "getImportState";
const GET_FILE_IMPORT = gql`
  query GetImportState($projectId: ID!, $serviceId: ID!, $id: String!) {
    getImportState(
      data: { projectId: $projectId, serviceId: $serviceId, id: $id }
    ) {
      id
      state
      failure_reason
    }
  }
`;

const GET_VIRTUAL_PLANS__TYPE = "getVirtualPlans";
const GET_VIRTUAL_PLANS = gql`
  query GetVirtualPlans {
    getVirtualPlans {
      plans {
        kind
      }
    }
  }
`;

const GET_PLAN_DOWNGRADE_CHECK__TYPE = "getPlanDowngradeCheck";
const GET_PLAN_DOWNGRADE_CHECK = gql`
  query GetPlanDowngradeCheck($projectId: ID!, $downgradeTo: VirtualPlanType!) {
    downgradeCheck(data: { projectID: $projectId, downgradeTo: $downgradeTo }) {
      dependencies
    }
  }
`;

const GET_ORB_COST__TYPE = "orbCost";
const GET_ORB_COST = gql`
  ${BILLING_ACCOUNT_COST_INFO_FRAGMENT}
  query OrbCost($projectId: ID!, $billingAccountId: ID!) {
    orbCost(
      data: { projectId: $projectId, billingAccountId: $billingAccountId }
    ) {
      ...BillingAccountCostInfoFragment
    }
  }
`;

const GET_RECOVERY_PROGRESS__TYPE = "getRecoveryProgress";
const GET_RECOVERY_PROGRESS = gql`
  query GetRecoveryProgress($projectId: ID!, $serviceId: ID!) {
    getRecoveryProgress(projectId: $projectId, serviceId: $serviceId) {
      recorded
      ordinal
      lag
    }
  }
`;

export {
  GET_PRODUCTS,
  GET_PROJECT,
  GET_USER,
  GET_USER__TYPE,
  GET_ALL_PROJECTS,
  GET_ALL_PROJECTS__TYPE,
  GET_BILLING_ACCOUNT,
  GET_BILLING_EMAILS,
  GET_BILLING_ACCOUNT__TYPE,
  GET_POPSQL_INTEGRATION_STATUS__TYPE,
  GET_POPSQL_INTEGRATION_STATUS,
  GET_PROJECT_MEMBERS,
  GET_PROJECT_INVITES,
  GET_PROJECT_INVITES_FOR_USER,
  GET_INVOICES,
  GET_INVOICES__TYPE,
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS__TYPE,
  GET_SERVICE_METRICS,
  GET_SERVICE,
  GET_SERVICE__TYPE,
  GET_ALL_SERVICES,
  GET_SERVICE_RESOURCES_AND_STATUS,
  GET_SERVICE_RESIZE_INFO,
  GET_ALL_SERVICES__TYPE,
  GET_SERVICE_LOGS,
  GET_SERVICE_MAINTENANCE_INFO__TYPE,
  GET_SERVICE_MAINTENANCE_INFO,
  GET_SERVICE_CONFIG_HISTORY,
  GET_SERVICE_GENERAL_INFORMATION,
  GET_SERVICE_GENERAL_INFORMATION__TYPE,
  GET_SERVICE_TABLES,
  GET_SERVICE_TABLES__TYPE,
  GET_SERVICE_CAGG_INFO,
  GET_SERVICE_CAGG_INFO__TYPE,
  GET_SERVICE_CONTINUOUS_AGGREGATES,
  GET_SERVICE_CONTINUOUS_AGGREGATES__TYPE,
  GET_SERVICE_LARGEST_OBJECTS,
  GET_SERVICE_LARGEST_OBJECTS__TYPE,
  GET_SERVICE_TABLE_INFO,
  GET_SERVICE_TABLE_INFO__TYPE,
  GET_SERVICE_POSTGRES_AND_TIMESCALEDB_VERSIONS,
  GET_SERVICE_POSTGRES_MAJOR_VERSION_UPGRADE_STATUS,
  GET_POSTGRES_PARAMETERS,
  GET_POSTGRES_PARAMETERS__TYPE,
  GET_ALL_VPC,
  LOGIN_BASIC,
  LOGIN_MFA_CHALLENGE,
  MFA_CHALLENGE,
  LOGOUT,
  VALIDATE_RESET_TOKEN_MFA,
  GET_PENDING_PAYMENT_DELETION,
  GET_PENDING_PAYMENT_DELETION__TYPE,
  GET_ALL_METRIC_EXPORTERS,
  GET_ALL_METRIC_EXPORTERS__TYPE,
  GET_ALL_GENERIC_EXPORTERS,
  GET_ALL_GENERIC_EXPORTERS__TYPE,
  GET_METRIC_EXPORTER,
  GET_METRIC_EXPORTER__TYPE,
  GET_PAT_RECORDS,
  GET_PAT_RECORDS__TYPE,
  GET_POSTGRES_AVAILABLE_EXTENSIONS,
  GET_POSTGRES_AVAILABLE_EXTENSIONS__TYPE,
  GET_QUERY_STATS,
  GET_QUERY_STATS__TYPE,
  GET_DATA_TIERING_SUMMARY,
  GET_DATA_TIERING_SUMMARY__TYPE,
  GET_COSTED_PROJECT_DATA_TIERING_USAGE_DISTRIBUTION,
  GET_COSTED_PROJECT_DATA_TIERING_USAGE_DISTRIBUTION__TYPE,
  GET_RECOVERY_WINDOWS,
  GET_RECOVERY_WINDOWS__TYPE,
  GET_QUERY_METRICS_GRAPHS,
  GET_QUERY_METRICS_GRAPHS__TYPE,
  GET_QUERY_DETAILS,
  GET_QUERY_DETAILS__TYPE,
  GET_JOB_LIST,
  GET_JOB_LIST__TYPE,
  GET_OBJECTS_METADATA__TYPE,
  GET_OBJECTS_METADATA,
  GET_COMPRESSION_RECOMMENDATIONS_FOR_SEGMENT_BY_TYPE,
  GET_COMPRESSION_RECOMMENDATIONS_FOR_SEGMENT_BY,
  GET_COMPRESSION_RECOMMENDATIONS_FOR_ORDER_BY_TYPE,
  GET_COMPRESSION_RECOMMENDATIONS_FOR_ORDER_BY,
  GET_CSV_IMPORT__TYPE,
  GET_CSV_IMPORT,
  GET_FILE_IMPORT__TYPE,
  GET_FILE_IMPORT,
  GET_VIRTUAL_PLANS__TYPE,
  GET_VIRTUAL_PLANS,
  GET_PLAN_DOWNGRADE_CHECK__TYPE,
  GET_PLAN_DOWNGRADE_CHECK,
  GET_ORB_PRODUCTS,
  GET_ORB_COST,
  GET_ORB_COST__TYPE,
  GET_RECOVERY_PROGRESS__TYPE,
  GET_RECOVERY_PROGRESS,
};
