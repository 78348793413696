import { Flex, Grid, useBreakpointValue } from "@chakra-ui/react";
import { ErrorBoundary } from "@sentry/react";
import { NotificationBanner } from "components/notificationBanner/NotificationBanner";
import { DashboardLayout } from "layouts/DashboardLayout";
import { MainLayout } from "layouts/MainLayout";
import { PopSQLFrame } from "layouts/PopSQLFrame";
import { SuccessLayout } from "layouts/SuccessLayout";
import {
  ConnectionProgress,
  defaultStepConfig,
  showConnectionProgressUrls,
} from "pages/connectionFlow/ConnectionProgress";
import { createContext, useEffect, useRef, useState } from "react";
import { Route, Switch, useLocation, useParams } from "react-router-dom";
import { Statsig } from "statsig-react";
import { FEAT_QUAKE_SQL } from "utils/featuresGates";
import { AuthRoute, PublicRoute } from "./AuthRoute";
import { ErrorCatch } from "./ErrorCatch";
import {
  noLayoutRoutes,
  privateRoutes,
  publicRoutes,
  publicRoutesSecondary,
} from "./Routes";
import { pathArray } from "./routeUtils";
import { NavigationProvider } from "../layouts/navigation/NavigationProvider";
import { GuidedTourProvider } from "../layouts/navigation/guidedTour/GuidedTourProvider";
import { SmallScreenLayout } from "layouts/SmallScreenLayout";
import { SlideOutSQLEditor } from "./SlideOutSQLEditor";
import { LeftNav } from "layouts/navigation/LeftNav";

type Props = {
  children?: JSX.Element | JSX.Element[];
};

export const ConnectionProgressContext = createContext<any>(null);

const AuthRouteComponent = ({ children }: Props) => {
  const location = useLocation();
  const { pathname } = location;
  const containerRef = useRef<HTMLDivElement>(null);
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const { serviceId } = useParams<{ serviceId: string }>();
  const [stepConfig, setStepConfig] = useState(
    serviceId ? [] : defaultStepConfig,
  );

  useEffect(() => {
    containerRef?.current?.scrollTo(0, 0);
  }, [pathname, containerRef]);

  const showConnectionProgress =
    showConnectionProgressUrls(serviceId).includes(pathname);

  return (
    <NavigationProvider>
      <DashboardLayout>
        <PopSQLFrame>
          <NotificationBanner />
          {!isSmallScreen && (
            <GuidedTourProvider>
              <LeftNav />
            </GuidedTourProvider>
          )}
          <ErrorBoundary
            fallback={(errorData) => <ErrorCatch {...errorData} />}
          >
            <Grid
              templateAreas={`
            "header"
            "main"
          `}
              gridArea="main"
              gridTemplateRows="auto minmax(0, 1fr)"
              overflowY="auto"
              backgroundColor="grayscale.100"
              width={{ base: "100vw", md: "100%" }}
              minWidth="375px"
              data-cy="MainGrid"
            >
              {isSmallScreen && <SmallScreenLayout />}
              {Statsig.checkGate(FEAT_QUAKE_SQL.GATE) && <SlideOutSQLEditor />}
              <Flex
                direction="column"
                gridArea="main"
                gridTemplateRows="1fr"
                margin="0 auto"
                width="100%"
                overflow="hidden"
                flex="1 1 auto"
                ref={containerRef}
                data-cy="DashboardContainer"
              >
                <ConnectionProgressContext.Provider
                  value={{ stepConfig, setStepConfig }}
                >
                  {showConnectionProgress && (
                    <ConnectionProgress
                      serviceId={serviceId}
                      pathname={pathname}
                    />
                  )}

                  {children}
                </ConnectionProgressContext.Provider>
              </Flex>
            </Grid>
          </ErrorBoundary>
        </PopSQLFrame>
      </DashboardLayout>
    </NavigationProvider>
  );
};

export const Layout = ({ children }: Props) => {
  return (
    <Switch>
      <AuthRoute
        path={pathArray(privateRoutes)}
        render={() => <AuthRouteComponent>{children}</AuthRouteComponent>}
      />
      <Route
        path={pathArray(noLayoutRoutes)}
        render={() => <AuthRouteComponent>{children}</AuthRouteComponent>}
      />
      <Route
        path={pathArray(publicRoutesSecondary)}
        render={() => <SuccessLayout>{children}</SuccessLayout>}
      />
      <PublicRoute
        path={pathArray(publicRoutes)}
        render={() => <MainLayout>{children}</MainLayout>}
      />
    </Switch>
  );
};
