import { DocumentNode, FetchPolicy, ObservableQuery } from "@apollo/client";
import { client } from "api/client";
import {
  ADD_COMPRESSION_POLICY,
  ATTACH_SERVICE_TO_GENERIC_EXPORTER,
  ATTACH_SERVICE_TO_METRIC_EXPORTER,
  ATTACH_SERVICE_TO_VPC,
  CHANGE_MAINTENANCE_WINDOW,
  CREATE_SERVICE,
  CREATE_SERVICE_FORK,
  CREATE_VPC,
  DELETE_COMPRESSION_HYPERTABLE,
  DELETE_PEER_CONNECTION,
  DELETE_SERVICE,
  DELETE_VPC,
  DETACH_SERVICE_FROM_GENERIC_EXPORTER,
  DETACH_SERVICE_FROM_METRIC_EXPORTER,
  DETACH_SERVICE_FROM_VPC,
  OPEN_PEER_REQUEST,
  RENAME_SERVICE,
  RENAME_VPC,
  RESET_SERVICE_PASSWORD,
  SET_COMPRESSION,
  SET_COMPRESSION_HYPERTABLE,
  SET_ENVIRONMENT_TAG,
  SET_HYPERTABLE_CONFIG,
  SET_REPLICA_COUNT,
  SWITCHOVER_INSTANCE,
  TOGGLE_CONNECTION_POOLER,
  TOGGLE_DATA_TIERING_INPUT,
  TOGGLE_SERVICE,
  UPGRADE_SERVICE_POSTGRES_MAJOR_VERSION,
} from "api/mutations";
import {
  GET_ALL_SERVICES,
  GET_ALL_VPC,
  GET_JOB_LIST,
  GET_JOB_LIST__TYPE,
  GET_OBJECTS_METADATA,
  GET_OBJECTS_METADATA__TYPE,
  GET_POSTGRES_AVAILABLE_EXTENSIONS,
  GET_POSTGRES_AVAILABLE_EXTENSIONS__TYPE,
  GET_RECOVERY_WINDOWS,
  GET_RECOVERY_WINDOWS__TYPE,
  GET_SERVICE,
  GET_SERVICE_CAGG_INFO,
  GET_SERVICE_CAGG_INFO__TYPE,
  GET_SERVICE_CONFIG_HISTORY,
  GET_SERVICE_CONTINUOUS_AGGREGATES,
  GET_SERVICE_CONTINUOUS_AGGREGATES__TYPE,
  GET_SERVICE_GENERAL_INFORMATION,
  GET_SERVICE_GENERAL_INFORMATION__TYPE,
  GET_SERVICE_LARGEST_OBJECTS,
  GET_SERVICE_LARGEST_OBJECTS__TYPE,
  GET_SERVICE_MAINTENANCE_INFO,
  GET_SERVICE_MAINTENANCE_INFO__TYPE,
  GET_SERVICE_METRICS,
  GET_SERVICE_POSTGRES_AND_TIMESCALEDB_VERSIONS,
  GET_SERVICE_POSTGRES_MAJOR_VERSION_UPGRADE_STATUS,
  GET_SERVICE_TABLES,
  GET_SERVICE_TABLES__TYPE,
  GET_SERVICE_TABLE_INFO,
  GET_SERVICE_TABLE_INFO__TYPE,
  GET_RECOVERY_PROGRESS,
  GET_RECOVERY_PROGRESS__TYPE,
} from "api/query";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import utc from "dayjs/plugin/utc";
import { GraphQLError } from "graphql/error/GraphQLError";
import { groupBy, isEmpty, isEqual, orderBy, sortBy, unionBy } from "lodash";
import {
  autorun,
  computed,
  makeAutoObservable,
  observable,
  reaction,
  runInAction,
  toJS,
} from "mobx";
import { Statsig } from "statsig-react";
import { Exporter } from "types";
import { ALERTS, CONNECTION, STATUS } from "utils/config";
import { EXP_COMPUTE_DEFAULT_1_CPU } from "utils/featuresExperiments";
import { FEAT_MULTIPLE_HA_REPLICAS } from "utils/featuresGates";
import {
  ALL_SERVICES,
  COMPRESSION_SUGGESTION,
  COMPRESSION_USED,
  getFromLocalStorage,
  INITIAL_PASSWORDS,
  LOCAL_STORAGE_USER,
  LOCAL_STORAGE_USER_DATA,
  METRIC_SETTINGS,
  RESIZED_DATA,
  storeDirectlyIntoLocalStorage,
  UPGRADE_STATUSES,
} from "utils/localStorage";
import Logger from "utils/logger";
import { excludeById, findById, makeRandomDBName } from "utils/utilFunctions";
import { Subscription } from "zen-observable-ts";
import { UnstableServices } from "../components/notificationBanner/notificationBannerMessages";
import {
  CloudProvider,
  CompressionConfig,
  ContAggInfo,
  CreateVpcMutation,
  DeployStatus,
  ForkConfig,
  GetObjectsMetadataQuery,
  GetPostgresMajorVersionUpgradeStatusQuery,
  GetServiceCaggInfoQuery,
  GetServiceContinuousAggregatesQuery,
  GetServiceGeneralInformationQuery,
  GetServiceLargestObjectsQuery,
  GetServiceTablesQuery,
  GetTableInfoQuery,
  InputPeerVpc,
  Maybe,
  NextMaintenance,
  PostgresAndTimescaleVersions,
  PostgresExtension,
  PostgresMajorVersionUpgradeStage,
  PostgresMajorVersionUpgradeStatus,
  RecoveryProgress,
  RecoveryWindow,
  ResourceConfig,
  ResourceMetrics,
  Service,
  ServiceConfigHistory,
  ServiceEnvironment,
  SetCompressionHypertableMutation,
  SetHypertableConfInput,
  SetReplicaCountInput,
  Status,
  SwitchoverInstanceMutation,
  Table,
  TableId,
  Type,
  Vpc,
} from "../graphql/generated";
import authStore from "./authStore";
import commonStore, { APP_STATE } from "./commonStore";
import { NOT_INITIATED, READY, VISIBILITY_STATE } from "./constants";
import notificationStore from "./notificationStore";
import paymentMethodsStore from "./paymentMethodsStore";
import productStore from "./productStore";
import projectsStore from "./projectsStore";
import userStore from "./userStore";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(utc);

type MetricsPollingSettings = {
  value: string;
  label: string;
  poll: number;
};

// Union of ServiceComputeConfig and ServiceStorageConfig
type ServiceResourcesCombinedConfig = {
  created: string;
  memoryGB?: number;
  milliCPU?: number;
  milliCPULimit?: Maybe<number>;
  bandwidthMBs?: number;
  iops?: number;
  storageGB?: number;
};

type ResourceMetricsWithLimits = ResourceMetrics & {
  limit: ServiceResourcesCombinedConfig & { color?: string };
};

export const defaultService = {
  defaultDBName: "",
  name: "",
  hostname: "",
  id: "",
  port: "",
  username: "",
  status: "",
  replicaStatus: null,
  spec: {
    connectionPoolerEnabled: false,
  },
};

const defaultResources: ResourceConfig = {
  milliCPU: 1000,
  memoryGB: 4,
  storageGB: 25,
  replicaCount: 0,
};

const localMetricsSettings = getFromLocalStorage(
  METRIC_SETTINGS,
  LOCAL_STORAGE_USER,
);

const localAllServices = getFromLocalStorage(
  ALL_SERVICES,
  LOCAL_STORAGE_USER_DATA,
);

const localInitialPasswords = getFromLocalStorage(
  INITIAL_PASSWORDS,
  LOCAL_STORAGE_USER_DATA,
);

const localResizedData = getFromLocalStorage(
  RESIZED_DATA,
  LOCAL_STORAGE_USER_DATA,
);

const localUpgradeStatuses = getFromLocalStorage(
  UPGRADE_STATUSES,
  LOCAL_STORAGE_USER_DATA,
);

export class ServiceStore {
  serviceId = "";
  selectedNodeIds: string[] = [this.serviceId];
  allServices: Service[] = localAllServices || [];
  allVpcs: Vpc[] = [];
  vpcQuerySubscription: Maybe<
    ObservableQuery<any, { projectId: string | undefined }>
  > = null;
  refreshVpcSubscription: Subscription | null = null;
  servicesCount = 0;

  refreshServicesSubscription: Subscription | null = null;
  servicesQuerySubscription: Maybe<
    ObservableQuery<any, { projectId: string | undefined }>
  > = null;

  refreshSingleServiceSubscription: Subscription | null = null;
  singleServiceQuerySubscription: Maybe<
    ObservableQuery<any, { projectId: string | undefined; serviceId: string }>
  > = null;

  selectedMetricsSettings: { [key: string]: MetricsPollingSettings } =
    localMetricsSettings || {};
  resizedService = localResizedData || {};

  initialPasswords: string[] = localInitialPasswords || [];
  options = {};
  maintenanceWindowInfo: { [key: string]: NextMaintenance } = {};

  storeStatus = NOT_INITIATED;
  serviceVersions: { [key: string]: PostgresAndTimescaleVersions } = {};
  upgradeStatuses: { [key: string]: PostgresMajorVersionUpgradeStatus } =
    localUpgradeStatuses || {};
  postgresVersionWatchQueries: {
    [key: string]: any;
  } = {};
  postgresVersionSubscriptions: {
    [key: string]: Subscription | null;
  } = {};
  allServicesConfigHistory: { [key: string]: ServiceConfigHistory } = {};

  suggestHypertableCompressionList: {
    [key: string]: {
      tableSchema: string;
      tableName: string;
      id: TableId;
    };
  } = {};
  postgresAvailableExtensions: PostgresExtension[] = [];
  recoveryWindows: { [key: string]: RecoveryWindow } = {};

  recoveryProgressWatchQueries: {
    [key: string]: ObservableQuery<
      any,
      { projectId: string | undefined; serviceId: string }
    >;
  } = {};
  recoveryProgressQuerySubscription: {
    [key: string]: Subscription | null;
  } = {};
  recoveryProgressInfo: { [key: string]: RecoveryProgress[] } = {};

  constructor() {
    makeAutoObservable(this, {
      allServices: observable.struct,
      upgradeStatuses: observable.struct,
      serviceVersions: observable.struct,
      explorerDefaultVariables: computed.struct,
    });

    reaction(
      () => this.allServices,
      async (allServices) => {
        allServices &&
          storeDirectlyIntoLocalStorage(
            ALL_SERVICES,
            toJS(allServices),
            LOCAL_STORAGE_USER_DATA,
          );
        await this.getMaintenanceWindows();
      },
    );

    reaction(
      () => this.selectedMetricsSettings,
      (time) => {
        storeDirectlyIntoLocalStorage(
          METRIC_SETTINGS,
          toJS(time),
          LOCAL_STORAGE_USER,
        );
      },
    );

    reaction(
      () => this.initialPasswords,
      (passwords) =>
        passwords &&
        storeDirectlyIntoLocalStorage(
          INITIAL_PASSWORDS,
          toJS(passwords),
          LOCAL_STORAGE_USER_DATA,
        ),
    );

    reaction(
      () => this.resizedService,
      (resizedService) => {
        storeDirectlyIntoLocalStorage(
          RESIZED_DATA,
          toJS(resizedService),
          LOCAL_STORAGE_USER_DATA,
        );
      },
    );

    reaction(
      () => projectsStore?.currentProject,
      () => {
        this.storeStatus = NOT_INITIATED;
      },
    );

    reaction(
      () => this.upgradeStatuses,
      (statuses) => {
        storeDirectlyIntoLocalStorage(
          UPGRADE_STATUSES,
          toJS(statuses),
          LOCAL_STORAGE_USER_DATA,
        );
      },
    );

    autorun(() => {
      if (
        commonStore?.connectionStatus === CONNECTION.OFFLINE ||
        commonStore?.visibilityState === VISIBILITY_STATE.HIDDEN
      ) {
        this.setOptions({
          fetchPolicy: "cache-only",
        });
      } else if (commonStore?.visibilityState === VISIBILITY_STATE.VISIBLE) {
        this.setOptions({});
      }
    });

    autorun(() => {
      const unstableServices = this.allServices.filter(
        ({ status }) => status === STATUS.UNSTABLE,
      );
      if (
        unstableServices.length === 0 &&
        notificationStore?.alert?.show &&
        notificationStore?.alert?.type === ALERTS.UNSTABLE
      )
        notificationStore.setAlert({ show: false });
    });

    autorun(() => {
      this.allServices.forEach(({ id, status, name }) => {
        if (this.resizedService[id]?.status) {
          if (status === DeployStatus.Ready) {
            runInAction(() => {
              this.resizedService = {
                [id]: {
                  ...this.resizedService[id],
                  status: false,
                  notify: true,
                },
              };
              notificationStore.showSuccessToaster(
                `${name} has been successfully resized!`,
              );
            });
          } else if (
            (status === DeployStatus.Queued ||
              status === DeployStatus.Configuring) &&
            !this.resizedService[id].notify
          ) {
            runInAction(() => {
              this.resizedService = {
                [id]: {
                  ...this.resizedService[id],
                  notify: true,
                },
              };
            });
          }
        }
      });
    });

    autorun(async () => {
      if (
        projectsStore?.currentProject?.id &&
        projectsStore?.storeStatus === READY
      ) {
        await this.getAllServices();
        await this.getAllVpc();
        await this.getHypertableList();
      }
    });

    for (let serviceId in this.upgradeStatuses) {
      if (
        [
          PostgresMajorVersionUpgradeStage.Initiated,
          PostgresMajorVersionUpgradeStage.Verifying,
        ].includes(this.upgradeStatuses[serviceId]?.stage)
      ) {
        this._subscribeToPostgresMajorVersionUpgradeStatus({ serviceId });
      }
    }
  }

  get flatNodeList() {
    let allNodes = [];
    for (let i = 0; i < this.allServices.length; i++) {
      const service = this.allServices[i];
      allNodes.push(service);
    }
    return allNodes;
  }

  get service(): Service {
    const baseService = findById({
      items: this.allServices,
      id: this.serviceId,
    });
    const iP = findById({
      items: this.initialPasswords,
      id: this.serviceId,
    });
    return {
      ...(baseService ? baseService : defaultService),
      ...(iP?.initialPassword ? iP : {}),
      serviceVersions: this.serviceVersions?.[this.serviceId],
      upgradeStatus: this.upgradeStatuses?.[this.serviceId],
    };
  }

  // @deprecated
  get serviceNodeIds() {
    return [this.service.id];
  }

  get selectedNodes() {
    return this.flatNodeList.filter(({ id }) =>
      this.selectedNodeIds.includes(id),
    );
  }

  get explorerDefaultVariables() {
    return {
      serviceId: this.service?.id,
      projectId: projectsStore?.currentProject?.id,
      regionCode: this.service?.regionCode,
    };
  }

  get inProgressServices() {
    return this.allServices.filter(({ status }) =>
      [
        DeployStatus.Queued,
        DeployStatus.Configuring,
        DeployStatus.Upgrading,
      ].includes(status),
    );
  }

  get attachedExporters() {
    const attachedExporters: string[] = [];
    this.allServices.forEach((service) => {
      if (service.spec?.exporterID) {
        attachedExporters.push(service.spec.exporterID);
      }
      if (service.spec?.genericExporterID) {
        attachedExporters.push(service.spec.genericExporterID);
      }
    });
    return attachedExporters;
  }

  setService = ({ id }: { id: string }) => {
    const shouldUpdateNode = this.serviceId !== id;
    runInAction(() => {
      this.serviceId = id;
    });
    if (shouldUpdateNode) {
      this.setNodeIds([id]);
    }
    commonStore.setIsDataAvailable(true);
  };

  serviceById = (nodeId: string) => {
    return this.flatNodeList.find((node) => node.id === nodeId);
  };

  setNodeIds = (ids: string[]) => {
    runInAction(() => {
      this.selectedNodeIds = ids;
    });
  };

  setMetricsSettings = (metricSettings: MetricsPollingSettings) => {
    runInAction(() => {
      this.selectedMetricsSettings = {
        ...this.selectedMetricsSettings,
        [this.serviceId]: metricSettings,
      };
    });
  };

  setAllServices = (
    services: Array<Service & { statusTimestamp?: string }>,
  ) => {
    runInAction(() => {
      if (services !== undefined) {
        this.servicesCount = services?.length;
        this.allServices = sortBy([...services], ["id"])
          ?.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
          )
          ?.map(({ id, replicaOrdinals: replicas, ...rest }) => {
            const replicaOrdinals = replicas ?? [];
            const defaultService = {
              id,
              replicaOrdinals,
              ...rest,
            };
            const allServices: Array<Service & { statusTimestamp?: string }> =
              toJS(this.allServices);
            if (isEmpty(allServices)) {
              return defaultService;
            }
            const { statusTimestamp } =
              allServices?.find((data) => data.id === id) || {};
            if (statusTimestamp) {
              if (rest.status !== DeployStatus.Unstable) {
                if (
                  notificationStore.alert.show &&
                  notificationStore.alert.type === ALERTS.UNSTABLE
                )
                  notificationStore.setAlert({ show: false });
                return defaultService;
              }
              let hasBeen90Seconds =
                dayjs().diff(statusTimestamp, "second") >= 90;
              if (hasBeen90Seconds) {
                let { statusTimestamp, ...withoutTimestamp } = rest;
                notificationStore.setAlert({
                  show: true,
                  alertType: "warning",
                  type: ALERTS.UNSTABLE,
                  message: UnstableServices,
                });

                Logger.error("Unstable services.", {
                  source: "unknown",
                  serviceId: id,
                  projectId: rest.projectId,
                });

                return { id, replicaOrdinals, ...withoutTimestamp };
              } else {
                return {
                  ...rest,
                  id,
                  replicaOrdinals,
                  status: DeployStatus.Queued,
                  statusTimestamp,
                };
              }
            } else {
              return rest.status === DeployStatus.Unstable
                ? {
                    id,
                    statusTimestamp: dayjs().format(),
                    replicaOrdinals,
                    ...rest,
                  }
                : defaultService;
            }
          });
        commonStore.setIsDataAvailable(true);
        this.storeStatus = READY;
      }
    });
  };

  setEnvironmentTag = async ({
    serviceName,
    serviceId,
    projectId = projectsStore?.currentProject?.id,
    envTag,
  }: {
    serviceName: string;
    serviceId: string;
    projectId?: string;
    envTag: ServiceEnvironment;
  }) => {
    try {
      const { data, errors } = await client.mutate({
        variables: {
          serviceId,
          projectId,
          environment: envTag,
        },
        mutation: SET_ENVIRONMENT_TAG,
      });

      if (data) {
        notificationStore.showSuccessToaster(
          `${serviceName} has been set to "#${envTag}"!`,
        );
        this.getAllServices();
        return data;
      }

      notificationStore.showErrorToaster(
        "Sorry! We are unable to set your environment tag at the moment. Please try again later or contact us through support.",
      );
      return errors;
    } catch (errors) {
      notificationStore.showErrorToaster(
        "Sorry! We are unable to set your environment tag at the moment. Please try again later or contact us through support.",
      );
    }
  };

  setInitialPassword = ({
    id,
    initialPassword,
  }: {
    id: string;
    initialPassword: string;
  }) => {
    runInAction(() => {
      this.initialPasswords = excludeById({
        items: this.initialPasswords,
        id,
      }).concat([{ id, initialPassword }]);
    });
  };

  setOptions = (option: { fetchPolicy?: FetchPolicy }) => {
    runInAction(() => {
      this.options = option;
    });
  };

  handleAllServices = async ({
    data,
  }: {
    data: { getAllServices: Service[] };
  }) => {
    //handle upgrade status
    data?.getAllServices?.forEach(
      async ({ id: serviceId, status: deployStatus }) => {
        if (
          [DeployStatus.Upgrading, DeployStatus.Unstable].includes(deployStatus)
        ) {
          await this._subscribeToPostgresMajorVersionUpgradeStatus({
            serviceId,
          });
        }
      },
    );

    const incomingNonReadyServices = data?.getAllServices
      ?.filter(({ status: incomingStatus }) => {
        return incomingStatus !== DeployStatus.Ready;
      })
      .map(({ id, status, name }) => ({ id, status, name }));
    if (!isEmpty(this.inProgressServices)) {
      const newlyReadyServices = this.inProgressServices?.filter(
        ({ id }) => !incomingNonReadyServices?.map(({ id }) => id).includes(id),
      );
      if (!isEmpty(newlyReadyServices) && !isEmpty(this.allServices)) {
        newlyReadyServices.forEach(({ name, projectId }) => {
          if (projectId === projectsStore?.currentProject?.id) {
            return notificationStore.showSuccessToaster(
              `${name} is now running!`,
            );
          }
        });
      }
    }
    this.setAllServices(data?.getAllServices);
  };

  subscribeAllServices = async (interval: number) => {
    try {
      if (projectsStore.currentProject?.id) {
        this.unsubscribeServices();
        runInAction(() => {
          this.servicesQuerySubscription = client.watchQuery({
            variables: {
              projectId: projectsStore?.currentProject?.id,
            },
            query: GET_ALL_SERVICES,
            pollInterval: interval ?? 30000,
            fetchPolicy: "no-cache",
            ...this.options,
          });

          this.refreshServicesSubscription =
            this.servicesQuerySubscription?.subscribe(({ data, errors }) => {
              if (errors && isEmpty(data?.getAllServices)) {
                commonStore.setIsDataAvailable(false);
                return this.unsubscribeServices();
              }
              this.handleAllServices({ data });
            });
        });
      } else {
        runInAction(() => {
          this.storeStatus = NOT_INITIATED;
        });
      }
    } catch (errors) {
      commonStore.setIsDataAvailable(false);
      console.error("Error retrieving all services", errors);
    } finally {
      commonStore.setAppState(APP_STATE.READY);
    }
  };

  subscribeSingleService = async (
    serviceId: string,
    interval?: number,
    stopPollingWhenServiceReady: boolean = true,
  ) => {
    try {
      if (projectsStore.currentProject?.id && serviceId) {
        this.unsubscribeSingleService();
        runInAction(() => {
          this.singleServiceQuerySubscription = client.watchQuery({
            variables: {
              projectId: projectsStore?.currentProject?.id,
              serviceId,
            },
            query: GET_SERVICE,
            pollInterval: interval ?? 30000,
            fetchPolicy: "no-cache",
            ...this.options,
          });

          this.refreshSingleServiceSubscription =
            this.singleServiceQuerySubscription?.subscribe(
              ({ data, errors }) => {
                if (errors || isEmpty(data?.getService)) {
                  console.error(
                    "Error in setup single service for subscription",
                  );
                  return this.unsubscribeSingleService();
                }

                // Fetch a single service and merge it with the existing services.
                const blendedServices = unionBy(
                  [data?.getService],
                  this.allServices,
                  "id",
                );
                this.handleAllServices({
                  data: { getAllServices: blendedServices },
                });

                // Turn off the polling when the service is ready.
                if (
                  stopPollingWhenServiceReady &&
                  data?.getService?.status === DeployStatus.Ready
                ) {
                  this.unsubscribeSingleService();
                }
              },
            );
        });
      }
    } catch (errors) {
      commonStore.setIsDataAvailable(false);
      console.error("Error retrieving all services", errors);
    } finally {
      commonStore.setAppState(APP_STATE.READY);
    }
  };

  unsubscribeSingleService = () => {
    runInAction(() => {
      this.singleServiceQuerySubscription?.stopPolling?.();
      this.refreshSingleServiceSubscription?.unsubscribe?.();
      this.singleServiceQuerySubscription = null;
      this.refreshSingleServiceSubscription = null;
    });
  };

  subscribeAllVPC = async () => {
    try {
      if (!projectsStore?.currentProject) return;

      this.unsubscribeServices();
      runInAction(() => {
        this.vpcQuerySubscription = client.watchQuery({
          variables: { projectId: projectsStore?.currentProject?.id },
          query: GET_ALL_VPC,
          pollInterval: 10000,
          ...this.options,
        });

        this.refreshVpcSubscription = this.vpcQuerySubscription?.subscribe(
          ({ data, errors }) => {
            if (errors) {
              return this.unsubscribeServices();
            }

            if (!isEqual(data?.getAllVpcs, this.allVpcs)) {
              runInAction(() => (this.allVpcs = data?.getAllVpcs));
            }
          },
        );
      });
    } catch (errors) {
      console.error("Error retrieving all vpc", errors);
    }
  };

  getAllServices = async () => {
    runInAction(() => {
      if (!projectsStore?.currentProject?.id) {
        this.storeStatus = NOT_INITIATED;
        commonStore.setIsDataAvailable(false);
      }
    });
    try {
      if (projectsStore?.currentProject?.id) {
        const { data } = await client.query({
          variables: {
            projectId: projectsStore?.currentProject?.id,
          },
          query: GET_ALL_SERVICES,
          context: { timeout: 30000 },
          ...this.options,
        });
        if (data) {
          this.handleAllServices({ data });
        }
      }
    } catch (errors) {
      commonStore.setIsDataAvailable(false);
    }
  };

  fetchService = async ({ serviceId = this.serviceId }) => {
    try {
      const { data } = await client.query({
        variables: {
          projectId: projectsStore?.currentProject?.id,
          serviceId,
        },
        query: GET_SERVICE,
      });
      if (data) {
        return data.getService;
      }
      return null;
    } catch {
      notificationStore.showErrorToaster(
        "Failed to retrieve service information",
      );
    }
  };

  setPollStatus = (time = 5000) => {
    if (this.servicesQuerySubscription) {
      if (
        !commonStore.isPageIdle &&
        !commonStore.isModalOpen &&
        commonStore.visibilityState === VISIBILITY_STATE.VISIBLE &&
        !isEmpty(projectsStore.allProjects)
      ) {
        this.servicesQuerySubscription.startPolling(time);
      } else {
        this.servicesQuerySubscription.stopPolling();
      }
    }
  };

  unsubscribeServices = () => {
    runInAction(() => {
      this.servicesQuerySubscription?.stopPolling?.();
      this.refreshServicesSubscription?.unsubscribe?.();
      this?.vpcQuerySubscription?.stopPolling?.();
      this?.refreshVpcSubscription?.unsubscribe?.();
      this.servicesQuerySubscription = null;
      this.refreshServicesSubscription = null;
      this.vpcQuerySubscription = null;
      this.refreshVpcSubscription = null;
      this.unsubscribeSingleService();
    });
  };

  stopPolling = () => {
    this.servicesQuerySubscription?.stopPolling?.();
    this.vpcQuerySubscription?.stopPolling?.();
  };

  resetService = () => {
    runInAction(() => {
      this.serviceId = "";
    });
  };

  resetAllService = () => {
    runInAction(() => {
      this.allServices = [];
    });
  };

  resetInitialPassword = (serviceId: string) => {
    runInAction(() => {
      if (serviceId) {
        this.initialPasswords = excludeById({
          items: this.initialPasswords,
          id: serviceId,
        });
      } else {
        this.initialPasswords = [];
      }
    });
  };

  toggleService = async ({
    serviceId = this?.service.id,
    projectId = projectsStore?.currentProject?.id,
    status,
  }: {
    serviceId?: string;
    projectId?: string;
    status: Status;
  }) => {
    try {
      const { data, errors } = await client.mutate({
        variables: {
          serviceId,
          projectId,
          status,
        },
        mutation: TOGGLE_SERVICE,
      });
      if (data) {
        const serviceName = data.toggleService.name;
        const status =
          data.toggleService.spec.status === Status.Active
            ? `
            Resuming service: ${serviceName}.`
            : `
            Pausing service: ${serviceName}.`;
        notificationStore.showInfoToaster(status);
        await this.getAllServices();
      }
      if (errors) {
        notificationStore.showErrorToaster(`Error: ${errors[0].message}.`);
      }
    } catch (errors) {
      notificationStore.showErrorToaster(
        "Sorry! We are unable to toggle your instance at the moment. Please try again later or contact us through support.",
      );
    }
  };

  toggleDataTiering = async ({
    serviceId = this?.service?.id,
    projectId = projectsStore?.currentProject?.id,
    enable = true,
  }: {
    serviceId?: string;
    projectId?: string;
    enable: boolean;
  }): Promise<void | {
    data: string;
    errors?: readonly GraphQLError[];
  }> => {
    try {
      const { data, errors } = await client.mutate({
        variables: {
          serviceId,
          projectId,
          enable,
        },
        mutation: TOGGLE_DATA_TIERING_INPUT,
      });
      return { data, errors };
    } catch (errors) {
      notificationStore.showErrorToaster(
        "Sorry! We are unable to toggle your instance at the moment. Please try again later or contact us through support.",
      );
    }
  };

  getAllServicesConfigHistory = async () => {
    const configHistoryMap: { [key: string]: ServiceConfigHistory } = {};
    if (
      !this.allServices?.some(
        ({ projectId }) => projectId === projectsStore?.currentProject?.id,
      )
    ) {
      return;
    }
    for (let i = 0; i < this.allServices.length; i++) {
      try {
        const { data, errors } = await client.query({
          variables: {
            projectId: projectsStore?.currentProject?.id,
            serviceId: this.allServices[i].id,
          },
          query: GET_SERVICE_CONFIG_HISTORY,
          ...this.options,
        });
        if (errors) {
          console.log(
            "There was an error retrieving a service's maintenance window",
          );
        }
        if (data) {
          configHistoryMap[this.allServices[i].id] = {
            ...data.getServiceConfigHistory,
          };
        }
      } catch {
        console.log(
          "There was an error retrieving a service's maintenance window",
        );
      }
    }

    runInAction(() => {
      this.allServicesConfigHistory = configHistoryMap;
    });
  };

  getResizedHistory = async ({
    serviceId = this?.service.id,
    projectId = projectsStore?.currentProject?.id,
    serviceOrdinal,
    orderedData,
    created,
  }: {
    serviceId?: string;
    projectId?: string;
    serviceOrdinal: number;
    orderedData: ResourceMetrics[];
    created: string;
  }): Promise<void | any> => {
    try {
      if (!serviceId || !projectId) return;
      let tmp: ResourceMetricsWithLimits[];
      const { data } = await client.query({
        variables: {
          serviceId,
          projectId,
          serviceOrdinal,
        },
        query: GET_SERVICE_CONFIG_HISTORY,
        context: { timeout: 30000 },
        ...this.options,
      });

      const { compute: rawCompute, storage: rawStorage }: ServiceConfigHistory =
        data.getServiceConfigHistory;
      let storage = rawStorage.filter(({ storageGB }) => storageGB !== 0);
      let compute = rawCompute.filter(({ milliCPU }) => milliCPU !== 0);

      const metricsColor = (num: number | string) => {
        let colorsList = ["purple2", "sky", "sand"];
        if (typeof num !== "number") {
          return colorsList[0];
        }
        return colorsList[num % colorsList.length] || colorsList[0]; // hotfix fallback https://timescale.sentry.io/issues/4296081629/?alert_rule_id=13943234&alert_type=issue&project=5317137&referrer=slack, https://iobeam.slack.com/archives/C032ZV5UKU2/p1688589915346349
      };

      let combineSameTimeResizing: ServiceResourcesCombinedConfig[] = [
        ...compute,
        ...storage,
      ]
        .reduce((a: Array<ServiceResourcesCombinedConfig>, v) => {
          // If a change happened within 60 seconds of another one,
          // combine them into just a single change.

          let index = a.findIndex(
            (el) => Math.abs(dayjs(el.created).diff(v.created, "s")) <= 60,
          );
          if (index !== -1) {
            a[index] = { ...a[index], ...v };
            return a;
          }

          a.push(v);
          return a;
        }, [])
        .sort((a, b) => dayjs(b.created).unix() - dayjs(a.created).unix());

      combineSameTimeResizing = combineSameTimeResizing
        .map((data, idx) => ({
          ...data,
          color:
            metricsColor(combineSameTimeResizing.length - 1 - idx) || "primary", // hotfix fallback https://timescale.sentry.io/issues/4296081629/?alert_rule_id=13943234&alert_type=issue&project=5317137&referrer=slack, https://iobeam.slack.com/archives/C032ZV5UKU2/p1688589915346349
        }))
        .reverse();

      let combinedComputeAndStorage = combineSameTimeResizing
        .reduce((a: ServiceResourcesCombinedConfig[], v) => {
          const { milliCPU, storageGB, created } = v;
          if (milliCPU && storageGB) {
            a.push(v);
            return a;
          }
          const list = a.reverse();
          let index = milliCPU
            ? list.findIndex(
                (el) => el.storageGB && dayjs(created).isAfter(el.created),
              )
            : list.findIndex(
                (el) => el.milliCPU && dayjs(created).isAfter(el.created),
              );

          if (index !== -1) {
            a.push({ ...a[index], ...v });
            return a;
          }
          a.push(v);
          return a;
        }, [])
        .sort((a, b) => dayjs(b.created).unix() - dayjs(a.created).unix())
        .reverse();

      tmp = orderedData.reduce((a: ResourceMetricsWithLimits[], v) => {
        const { Time, ...data } = v;
        // Removing values that may return empty values when initially created
        if (dayjs(Time).diff(this.service.created, "s") <= 60) {
          return a;
        }
        for (let i = 0; i < combinedComputeAndStorage.length; i++) {
          if (
            combinedComputeAndStorage[i + 1] &&
            dayjs(Time).isBetween(
              combinedComputeAndStorage[i].created,
              combinedComputeAndStorage[i + 1].created,
            )
          ) {
            a.push({
              Time,
              ...data,
              limit: { ...combinedComputeAndStorage[i] },
            });
            return a;
          } else if (
            dayjs(Time).isSameOrBefore(combinedComputeAndStorage[i].created)
          ) {
            a.push({
              Time,
              ...data,
              limit: { ...combinedComputeAndStorage[i - 1] },
            });
            return a;
          } else if (i === combinedComputeAndStorage.length - 1) {
            a.push({
              Time,
              ...data,
              limit: { ...combinedComputeAndStorage[i] },
            });
            return a;
          }
        }
        // @ts-ignore
        a.push({ Time, ...data, limit: {} });
        return a;
      }, []);

      const resizedDataSample = groupBy(tmp, ({ limit }) => limit.created);
      let cpuTotal: Array<Array<number | null | undefined>> = [];
      let memoryTotal: Array<Array<number | null>> = [];
      let storageTotal: Array<Array<number | null | undefined>> = [];
      let readIOPsTotal: Array<Array<number | null | undefined>> = [];
      let writeIOPsTotal: Array<Array<number | null | undefined>> = [];
      let readBandwidthTotal: Array<Array<number | null>> = [];
      let writeBandwidthTotal: Array<Array<number | null>> = [];
      let legend: Array<ServiceResourcesCombinedConfig & { color?: string }> =
        [];
      let graphColor: Array<string | undefined> = [];

      let emptyMemory: Array<Array<{ xAxis: number }>> = [];
      let emptyMemoryRange: Array<{ xAxis: number }> = [];

      let emptyStorage: Array<Array<{ xAxis: number }>> = [];
      let emptyStorageRange: Array<{ xAxis: number }> = [];

      let emptyCPU: Array<Array<{ xAxis: number }>> = [];
      let emptyCPURange: Array<{ xAxis: number }> = [];

      tmp.reverse().forEach(({ memoryMB, milliCPU, Time }, idx) => {
        const xAxis = { xAxis: idx };
        if (memoryMB === null && isEmpty(emptyMemoryRange)) {
          emptyMemoryRange.push(xAxis);
        }
        if (memoryMB !== null && !isEmpty(emptyMemoryRange)) {
          emptyMemoryRange.push(xAxis);
          emptyMemory.push(emptyMemoryRange);
          emptyMemoryRange = [];
        }
        if (!isEmpty(emptyMemoryRange) && idx === tmp.length - 1) {
          emptyMemoryRange.push(xAxis);
          emptyMemory.push(emptyMemoryRange);
          emptyMemoryRange = [];
        }
        if (milliCPU === null && isEmpty(emptyCPURange)) {
          emptyCPURange.push(xAxis);
        }
        if (milliCPU !== null && !isEmpty(emptyCPURange)) {
          emptyCPURange.push(xAxis);
          emptyCPU.push(emptyCPURange);
          emptyCPURange = [];
        }
        if (!isEmpty(emptyCPURange) && idx === tmp.length - 1) {
          emptyCPURange.push(xAxis);
          emptyCPU.push(emptyCPURange);
          emptyCPURange = [];
        }
        if (
          dayjs(Time).isBefore(dayjs(created)) &&
          isEmpty(emptyStorageRange)
        ) {
          emptyStorageRange.push(xAxis);
        }
        if (
          dayjs(Time).isSameOrAfter(dayjs(created)) &&
          !isEmpty(emptyStorageRange)
        ) {
          emptyStorageRange.push(xAxis);
          emptyStorage.push(emptyStorageRange);
          emptyStorageRange = [];
        }
        if (!isEmpty(emptyStorageRange) && idx === tmp.length - 1) {
          emptyStorageRange.push(xAxis);
          emptyStorage.push(emptyStorageRange);
          emptyStorageRange = [];
        }
      });
      Object.values(resizedDataSample)
        .reverse()
        .forEach((data) => {
          const dataSet = data.reverse();
          //Everything within group will have the same limits
          isEmpty(data[0].limit)
            ? graphColor.push("primary")
            : graphColor.push(data[0].limit.color);
          !isEmpty(data[0].limit) && legend.push(data[0].limit);

          // Maps through the data set, extracts the milliCPU value,
          // and throws them in the cpuTotal array
          cpuTotal.push(dataSet.map(({ milliCPU }) => milliCPU));
          memoryTotal.push(
            dataSet.map(({ memoryMB }) =>
              memoryMB === null || memoryMB === undefined
                ? null
                : memoryMB / 1024,
            ),
          );

          // Maps through the data set. If there are any entries for
          // storageMB during any time prior to the service's creation date,
          // return null. If not, throw that storageMB value in the storageTotal array
          storageTotal.push(
            dataSet.map(({ Time, storageMB }) =>
              dayjs(Time).isBefore(dayjs(created))
                ? null
                : storageMB === null
                  ? null
                  : storageMB,
            ),
          );

          readIOPsTotal.push(dataSet.map(({ readIOPs }) => readIOPs));

          writeIOPsTotal.push(dataSet.map(({ writeIOPs }) => writeIOPs));

          readBandwidthTotal.push(
            dataSet.map(({ readBandwidth }) =>
              readBandwidth === null || readBandwidth === undefined
                ? null
                : readBandwidth / 1000000,
            ), // bytes in MB
          );
          writeBandwidthTotal.push(
            dataSet.map(({ writeBandwidth }) =>
              writeBandwidth === null || writeBandwidth === undefined
                ? null
                : writeBandwidth / 1000000,
            ), // bytes in MB
          );
        });

      return {
        orderedData: tmp.reverse(),
        graphColor,
        emptyMemory,
        emptyStorage,
        emptyCPU,
        emptyIOPs: [], // CloudWatch doesn't return null values for storage iops
        emptyBandwidth: [], // CloudWatch doesn't return null values for storage bandwidth
        cpuTotal,
        memoryTotal,
        storageTotal,
        readIOPsTotal,
        writeIOPsTotal,
        readBandwidthTotal,
        writeBandwidthTotal,
        legend,
      };
    } catch (errors) {
      console.error("Error retrieving history", errors);
      // commonStore.setIsDataAvailable(false);
    }
  };

  createService = async (props: {
    projectId?: string;
    name?: string;
    type?: Type;
    resourceConfig?: ResourceConfig;
    regionCode?: string;
    vpcId?: Maybe<string>;
    enableConnectionPooler?: boolean;
    forkConfig?: ForkConfig;
    demoId?: Maybe<number>;
    environmentTag?: ServiceEnvironment;
  }) => {
    const {
      projectId = projectsStore?.currentProject?.id,
      name = makeRandomDBName(),
      type = Type.Timescaledb,
      resourceConfig = defaultResources,
      regionCode = "us-east-1",
      vpcId = null,
      enableConnectionPooler,
      forkConfig,
      demoId,
      environmentTag,
    } = props;

    if (paymentMethodsStore?.pendingPaymentDeletion?.id) {
      notificationStore.showErrorToaster(
        "Your payment method is scheduled for deletion.",
      );
      return {};
    }
    try {
      const { data, errors } = await client.mutate({
        variables: {
          projectId,
          name,
          type,
          regionCode,
          resourceConfig,
          vpcId,
          enableConnectionPooler,
          forkConfig,
          ...(demoId ? { demoID: demoId } : {}),
          environmentTag,
        },
        mutation: CREATE_SERVICE,
      });

      Statsig.logEvent(
        EXP_COMPUTE_DEFAULT_1_CPU.EVENTS.SERVICE_CREATED,
        undefined,
        {
          milliCPU: String(resourceConfig.milliCPU),
          memoryGB: String(resourceConfig.memoryGB),
          storageGB: String(resourceConfig.storageGB),
          replicaCount: String(resourceConfig.replicaCount),
        },
      );

      if (resourceConfig && resourceConfig?.replicaCount) {
        if (resourceConfig.replicaCount === 1) {
          Statsig.logEvent(FEAT_MULTIPLE_HA_REPLICAS.EVENTS.ADD_ONE_HA_REPLICA);
        }

        if (resourceConfig.replicaCount === 2) {
          Statsig.logEvent(
            FEAT_MULTIPLE_HA_REPLICAS.EVENTS.ADD_TWO_HA_REPLICAS,
          );
        }
      }

      if (data) {
        this.setInitialPassword({
          id: data.createService.service.id,
          initialPassword: data.createService.initialPassword,
        });

        productStore.resetServices();
        this.setAllServices(
          this.allServices.concat([data.createService.service]),
        );
        this.setService(data.createService.service);
        return data.createService;
      }

      if (errors) {
        notificationStore.showErrorToaster(
          `Error creating service: ${
            errors[0].message.includes("Entitlement check has failed")
              ? "You have reached the maximum service limit provided by your current plan. Please upgrade your plan to create more services."
              : errors[0].message
          }`,
        );
        return errors;
      }
    } catch (e) {
      console.log("catch: serviceStore", e);
    }
  };

  createForkedService = async ({
    originService = this.service,
    isStandby,
    name = `fork-${originService?.name}`,
    enableConnectionPooler = false,
    resourceConfig: {
      milliCPU = originService?.resources[0]?.spec?.milliCPU,
      memoryGB = originService?.resources[0]?.spec?.memoryGB,
      storageGB = originService?.resources[0]?.spec?.storageGB,
      replicaCount = 0,
    },
    targetTime,
    environmentTag = originService?.metadata?.environment,
  }: {
    originService: Service;
    isStandby: boolean;
    name?: string;
    enableConnectionPooler?: boolean;
    resourceConfig: ResourceConfig;
    targetTime?: string;
    environmentTag?: ServiceEnvironment;
  }) => {
    try {
      const { data, errors } = await client.mutate({
        variables: {
          projectId: originService?.projectId,
          name,
          type: originService?.type || "TIMESCALEDB",
          regionCode: originService?.regionCode,
          enableConnectionPooler,
          resourceConfig: {
            milliCPU,
            memoryGB,
            storageGB,
            replicaCount,
          },
          forkConfig: {
            serviceID: originService?.id,
            projectID: originService?.projectId,
            isStandby,
            ...(targetTime ? { targetTime } : {}),
          },
          environmentTag,
        },
        mutation: CREATE_SERVICE_FORK,
      });

      if (data) {
        this.setInitialPassword({
          id: data.createService.service.id,
          initialPassword: data.createService.initialPassword,
        });

        productStore.resetServices();
        this.setAllServices(
          this.allServices.concat([data.createService.service]),
        );
        this.setService(data.createService.service);
        return data.createService;
      }

      if (errors) {
        notificationStore.showErrorToaster(
          `Error forking service: ${
            errors[0].message.includes("Entitlement check has failed")
              ? "You have reached the maximum service limit provided by your current plan. Please upgrade your plan to create more services."
              : errors[0].message
          }.`,
        );
        return errors;
      }
    } catch (e) {
      console.log("catch: serviceStore", e);
    }
  };

  renameService = async ({
    projectId = projectsStore?.currentProject?.id,
    serviceId = this?.service.id,
    newName,
  }: {
    projectId?: string;
    serviceId?: string;
    newName: string;
  }) => {
    try {
      const { data, errors } = await client.mutate({
        variables: {
          projectId,
          serviceId,
          newName,
        },
        mutation: RENAME_SERVICE,
      });

      if (data) {
        return data;
      }

      if (errors) {
        notificationStore.showErrorToaster(
          `Error renaming service: ${errors[0].message}.`,
        );
        return errors;
      }
    } catch (e) {
      console.log("catch: rename service", e);
    }
  };

  setReplicaCount = async ({
    projectId = projectsStore.currentProject!.id!,
    serviceId = this.service.id!,
    replicaCount = 0,
    synchronousReplicaCount = 0,
    allReplicasAreSync = false,
  }: Omit<SetReplicaCountInput, "projectId" | "serviceId"> &
    Partial<SetReplicaCountInput>) => {
    try {
      const { data, errors } = await client.mutate({
        variables: {
          projectId,
          serviceId,
          replicaCount,
          synchronousReplicaCount,
          allReplicasAreSync,
        },
        mutation: SET_REPLICA_COUNT,
      });
      if (data) {
        await this.getAllServices();
        notificationStore.showSuccessToaster(
          "Your HA replica configuration has been successfully changed!",
        );
      }
      if (errors) {
        notificationStore.showErrorToaster(
          `Something went wrong changing your HA replica configuration. Please try again.`,
        );
      }

      return { data, errors };
    } catch (e) {
      console.log("catch: set replica count", e);
      return { data: null, errors: e };
    }
  };

  renameVpc = async ({
    projectId = projectsStore?.currentProject?.id,
    forgeVpcId,
    newName,
  }: {
    projectId?: string;
    forgeVpcId: string;
    newName: string;
  }) => {
    try {
      const { data, errors } = await client.mutate({
        variables: {
          projectId,
          forgeVpcId,
          newName,
        },
        mutation: RENAME_VPC,
      });

      if (data) {
        notificationStore.showSuccessToaster(
          "Your VPC has been successfully renamed!",
        );
        return data;
      }

      if (errors) {
        notificationStore.showErrorToaster(
          `Something went wrong. Please try to rename your VPC again.`,
        );
        return errors;
      }
    } catch (e) {
      console.log("catch: rename vpc", e);
    }
  };

  getAllVpc = async () => {
    try {
      const { data } = await client.query({
        variables: { projectId: projectsStore?.currentProject?.id },
        query: GET_ALL_VPC,
        context: { timeout: 30000000 },
        ...this.options,
      });

      if (data?.getAllVpcs) {
        runInAction(() => (this.allVpcs = data?.getAllVpcs));
      }
    } catch (e) {
      console.log(e);
    }
  };

  deleteVpc = async ({
    projectId = projectsStore?.currentProject?.id,
    vpcId,
  }: {
    projectId?: string;
    vpcId: string;
  }) => {
    try {
      const { data, errors } = await client.mutate({
        variables: { projectId, vpcId },
        mutation: DELETE_VPC,
      });
      if (data) {
        notificationStore.showSuccessToaster(
          "Your VPC has been queued for deletion!",
        );
      }
      if (errors) {
        notificationStore.showErrorToaster(
          `Error deleting VPC: ${errors[0].message}.`,
        );
      }
    } catch (e) {
      notificationStore.showErrorToaster(`Error deleting VPC: ${e}.`);
    } finally {
      this.getAllVpc();
    }
  };

  createVPC = async ({
    projectId = projectsStore?.currentProject?.id,
    cidr,
    cloudProvider = CloudProvider.Aws,
    name,
    regionCode = "us-east-1",
  }: {
    projectId?: string;
    cidr: string;
    cloudProvider: CloudProvider;
    name: string;
    regionCode: string;
  }): Promise<
    | void
    | {}
    | {
        data: CreateVpcMutation;
        errors?: readonly GraphQLError[];
      }
  > => {
    if (paymentMethodsStore.pendingPaymentDeletion?.id) {
      notificationStore.showErrorToaster(
        "Your payment method is scheduled for deletion.",
      );
      return {};
    }
    try {
      const { data, errors } = await client.mutate({
        variables: { projectId, cidr, cloudProvider, name, regionCode },
        mutation: CREATE_VPC,
      });
      if (data) {
        notificationStore.showSuccessToaster(
          "Your VPC has been successfully created!",
        );
      }
      if (errors) {
        authStore.setError(errors);
        notificationStore.showErrorToaster(
          `Error creating VPC: ${errors[0].message}.`,
        );
      }
      return { data, errors };
    } catch (e) {
      notificationStore.showErrorToaster(`Error creating VPC: ${e}.`);
    }
  };

  addVPCPeering = async ({
    projectId = projectsStore?.currentProject?.id,
    forgeVpcId,
    cloudProvider = CloudProvider.Aws,
    peerVpc,
  }: {
    projectId?: string;
    forgeVpcId: string;
    cloudProvider: CloudProvider;
    peerVpc: InputPeerVpc;
  }) => {
    try {
      const { data, errors } = await client.mutate({
        variables: {
          projectId,
          forgeVpcId,
          cloudProvider,
          peerVpc,
        },
        mutation: OPEN_PEER_REQUEST,
      });
      if (data) {
        notificationStore.showSuccessToaster(
          "Your VPC peering has been successfully requested!",
        );
      }
      if (errors) {
        notificationStore.showErrorToaster(
          `Unable to request a VPC peering: ${errors[0]?.message}`,
        );
        authStore.setError(errors);
      }
      return !!data;
    } catch (e) {
      notificationStore.showErrorToaster(`Something went wrong: ${e}.`);
      return false;
    } finally {
      this.getAllVpc();
    }
  };

  deletePeeringConnection = async ({
    projectId = projectsStore?.currentProject?.id,
    vpcId,
    id,
  }: {
    projectId?: string;
    vpcId: string;
    id: string;
  }) => {
    try {
      const { data, errors } = await client.mutate({
        variables: {
          projectId,
          vpcId,
          id,
        },
        mutation: DELETE_PEER_CONNECTION,
      });
      if (data) {
        notificationStore.showSuccessToaster(
          "Your VPC peering delete has been requested.",
        );
      }
      if (errors) {
        notificationStore.showErrorToaster(
          "Something went wrong, please try to delete again.",
        );
      }
      return !!data;
    } catch (e) {
      notificationStore.showErrorToaster(`Something went wrong: ${e}.`);
      return false;
    } finally {
      this.getAllVpc();
    }
  };

  attachServiceToVpc = async ({
    projectId = projectsStore?.currentProject?.id,
    serviceId = this?.service.id,
    vpcId,
  }: {
    projectId?: string;
    serviceId?: string;
    vpcId: string;
  }) => {
    try {
      const { data, errors } = await client.mutate({
        variables: {
          projectId,
          serviceId,
          vpcId,
        },
        mutation: ATTACH_SERVICE_TO_VPC,
      });
      if (data) {
        notificationStore.showSuccessToaster(
          "Your VPC has been successfully attached.",
        );
      }
      if (errors) {
        notificationStore.showErrorToaster(
          "Something went wrong, please try to attach again.",
        );
      }
    } catch (e) {
      notificationStore.showErrorToaster(`Something went wrong: ${e}.`);
    } finally {
      await this.getAllVpc();
      await this.getAllServices();
    }
  };

  detachServiceFromVpc = async ({
    projectId = projectsStore?.currentProject?.id,
    serviceId = this?.service.id,
    vpcId,
  }: {
    projectId?: string;
    serviceId?: string;
    vpcId: string;
  }) => {
    try {
      const { data, errors } = await client.mutate({
        variables: {
          projectId,
          serviceId,
          vpcId,
        },
        mutation: DETACH_SERVICE_FROM_VPC,
      });
      if (data) {
        notificationStore.showSuccessToaster(
          "Your VPC is migrating back to the public network.  This may take a short time to complete.",
        );
      }
      if (errors) {
        notificationStore.showErrorToaster(
          "Something went wrong, please try to detach again.",
        );
      }
    } catch (e) {
      notificationStore.showErrorToaster(`Something went wrong: ${e}.`);
    } finally {
      await this.getAllVpc();
      await this.getAllServices();
    }
  };

  deleteService = async ({
    serviceId = this?.service.id,
    projectId = projectsStore?.currentProject?.id,
    isReadReplica,
  }: {
    serviceId?: string;
    projectId?: string;
    isReadReplica: boolean;
  }) => {
    const { data, errors } = await client.mutate({
      variables: {
        serviceId,
        projectId,
      },
      mutation: DELETE_SERVICE,
    });

    if (data) {
      Statsig.logEvent(
        EXP_COMPUTE_DEFAULT_1_CPU.EVENTS.SERVICE_DELETED,
        undefined,
        {
          milliCPU: String(this.service.resources[0].spec.milliCPU),
          memoryGB: String(this.service.resources[0].spec.memoryGB),
          storageGB: String(this.service.resources[0].spec.storageGB),
          replicaCount: String(this.service.resources[0].spec.replicaCount),
        },
      );

      notificationStore.showSuccessToaster(
        `Deleted ${data.deleteService.name}.`,
      );
      this.setAllServices(
        this.allServices.filter(
          (service) => service.id !== data.deleteService.id,
        ),
      );
      if (!isReadReplica) {
        this.setService(defaultService);
      }
      await this.getAllServices();
    }
    if (errors) {
      notificationStore.showErrorToaster(`Error: ${errors}.`);
    }
  };

  updateServicePassword = async ({
    serviceId = this?.service.id,
    projectId = projectsStore?.currentProject?.id,
    password,
  }: {
    serviceId?: string;
    projectId?: string;
    password: string;
  }): Promise<void | any> => {
    try {
      return await client.mutate({
        variables: { serviceId, projectId, password, passwordType: "SCRAM" },
        mutation: RESET_SERVICE_PASSWORD,
      });
    } catch (e) {
      console.error(e);
    }
  };

  clearStore = () => {
    this._deleteAllPostgresVersionUpgradeQueryWatchers();
    this.unsubscribeServices();
    runInAction(() => {
      this.storeStatus = NOT_INITIATED;
      this.allServices = [];
      this.allVpcs = [];
      this.vpcQuerySubscription = null;
      this.refreshVpcSubscription = null;
      this.servicesCount = 0;
      this.refreshServicesSubscription = null;
      this.resizedService = {};
      this.initialPasswords = [];
      this.options = {};
      this.maintenanceWindowInfo = {};
    });
  };

  getServiceMetrics = async ({
    start,
    end,
    bucketSeconds = 60 * 15,
  }: {
    start: string;
    end: string;
    bucketSeconds?: number;
  }) => {
    try {
      const { data } = await client.query({
        variables: {
          serviceId: this?.service.id,
          projectId: projectsStore?.currentProject?.id,
          start,
          end,
          bucketSeconds,
        },
        query: GET_SERVICE_METRICS,
        context: { timeout: 30000 },
        ...this.options,
      });

      return data;
    } catch (errors) {
      // commonStore.setIsDataAvailable(false);
    }
  };

  getServiceById = (serviceId: string) =>
    this.allServices?.find((service) => service.id === serviceId);

  changeMaintenanceWindow = async ({
    all = false,
    projectId = projectsStore?.currentProject?.id,
    maintenanceWindowStart,
    maintenanceWindowDuration,
  }: {
    all: boolean;
    projectId?: string;
    maintenanceWindowStart: string;
    maintenanceWindowDuration: number;
  }) => {
    const ids = all
      ? this.allServices.map(({ id, name }) => ({ id, name }))
      : [{ id: this?.service.id, name: this?.service.name }];
    for (let i = 0; i < ids.length; i++) {
      try {
        const { data, errors } = await client.mutate({
          variables: {
            projectId,
            serviceId: ids[i].id,
            start: maintenanceWindowStart,
            durationInSeconds: maintenanceWindowDuration,
          },
          mutation: CHANGE_MAINTENANCE_WINDOW,
        });
        if (errors) {
          notificationStore.showErrorToaster(`Error: ${errors[0].message}.`);
          return;
        } else if (data && i === ids.length - 1) {
          notificationStore.showSuccessToaster(
            `Maintenance window${ids.length > 1 ? "s" : ""} updated.`,
          );
        }
        await this.getMaintenanceWindows();
        // runInAction(() => {
        //   this.maintenanceWindowInfo[ids[i].id] = {
        //     start: maintenanceWindowStart,
        //     end: dayjs
        //       .utc(maintenanceWindowStart)
        //       .add(maintenanceWindowDuration, "s")
        //       .format(),
        //     isUpcomingMaintenance: this.maintenanceWindowInfo?.[ids[i]]
        //       ?.isUpcomingMaintenance,
        //   };
        // });
      } catch {
        notificationStore.showErrorToaster(
          "There was an error updating your maintenance window.  Please try again or contact us for help.",
        );
      }
    }
  };

  getMaintenanceWindows = async () => {
    const windows: { [key: string]: NextMaintenance } = {};
    for (let i = 0; i < this.allServices?.length; i++) {
      try {
        const { data, errors } = await client.query({
          variables: {
            projectId: projectsStore?.currentProject?.id,
            serviceId: this.allServices[i].id,
          },
          query: GET_SERVICE_MAINTENANCE_INFO,
          ...this.options,
        });
        if (errors) {
          console.log(
            "There was an error retrieving a service's maintenance window",
          );
        }
        if (data) {
          windows[this.allServices[i].id] = {
            ...data[GET_SERVICE_MAINTENANCE_INFO__TYPE],
          };
        }
      } catch {
        console.log(
          "There was an error retrieving a service's maintenance window",
        );
      }
    }
    runInAction(() => {
      this.maintenanceWindowInfo = windows;
    });
  };

  getPostgresAndTimescaleDbVersions = async ({
    projectId = projectsStore?.currentProject?.id,
    serviceId = this?.service.id,
  }: {
    projectId?: string;
    serviceId?: string;
  } = {}) => {
    if (!projectId || !serviceId) {
      return;
    }
    const service = this.serviceById(serviceId);
    if (
      service &&
      [DeployStatus.Configuring, DeployStatus.Ready].includes(service.status)
    ) {
      const { data, errors } = await client.query({
        variables: { projectId, serviceId },
        query: GET_SERVICE_POSTGRES_AND_TIMESCALEDB_VERSIONS,
      });
      if (errors) {
        notificationStore.showErrorToaster(
          "There was an error retrieving service versions",
        );
      }
      if (data) {
        runInAction(() => {
          this.serviceVersions = {
            ...this.serviceVersions,
            [serviceId]: data.getPostgresAndTimescaleDBVersion,
          };
        });
      }
    }
  };

  _deletePostgresVersionUpgradeQueryWatcher = (serviceId: string) => {
    if (this.postgresVersionWatchQueries[serviceId]) {
      this.postgresVersionWatchQueries?.[serviceId]?.stopPolling?.();
      this.postgresVersionSubscriptions?.[serviceId]?.unsubscribe?.();
      runInAction(() => {
        const { [serviceId]: _, ...rest } = this.postgresVersionWatchQueries;
        this.postgresVersionWatchQueries = rest;
      });
    }
  };

  _deleteAllPostgresVersionUpgradeQueryWatchers = () => {
    this.allServices.forEach(({ id }) => {
      this._deletePostgresVersionUpgradeQueryWatcher(id);
    });
  };

  getPostgresMajorVersionUpgradeStatus = async ({
    projectId = projectsStore?.currentProject?.id,
    serviceId = this?.service.id,
  }: {
    projectId?: string;
    serviceId?: string;
  } = {}) => {
    if (!serviceId || !projectId) return;
    try {
      const { data, errors } = await client.query({
        variables: { projectId, serviceId },
        query: GET_SERVICE_POSTGRES_MAJOR_VERSION_UPGRADE_STATUS,
      });
      if (errors) {
        notificationStore.showErrorToaster(
          "There was an error retrieving upgrade status",
        );
      }
      if (data) {
        runInAction(() => {
          this.upgradeStatuses = {
            ...this.upgradeStatuses,
            [serviceId]: data.getPostgresMajorVersionUpgradeStatus,
          };
          if (
            data.getPostgresMajorVersionUpgradeStatus.stage ===
            PostgresMajorVersionUpgradeStage.Finished
          ) {
            this.getPostgresAndTimescaleDbVersions({ serviceId });
          }
        }); //pg-major-upgrades
      }
    } catch {}
  };

  _subscribeToPostgresMajorVersionUpgradeStatus = async ({
    projectId = projectsStore?.currentProject?.id,
    serviceId = this?.service.id,
  }: {
    projectId?: string;
    serviceId?: string;
  } = {}) => {
    if (!projectId || !serviceId) {
      return;
    }
    try {
      this._deletePostgresVersionUpgradeQueryWatcher(serviceId);
      this.postgresVersionWatchQueries[serviceId] = client.watchQuery({
        variables: { projectId, serviceId },
        query: GET_SERVICE_POSTGRES_MAJOR_VERSION_UPGRADE_STATUS,
        pollInterval: 5 * 1000, //5s
        fetchPolicy: "no-cache",
      });
      this.postgresVersionSubscriptions[serviceId] =
        this.postgresVersionWatchQueries[serviceId].subscribe(
          ({
            data,
            errors,
          }: {
            data: GetPostgresMajorVersionUpgradeStatusQuery;
            errors?: GraphQLError[];
          }) => {
            if (errors) {
              notificationStore.showErrorToaster(
                "There was an error retrieving upgrade status",
              );
            }
            if (data) {
              runInAction(() => {
                this.upgradeStatuses = {
                  ...this.upgradeStatuses,
                  [serviceId]: data.getPostgresMajorVersionUpgradeStatus,
                };
              });
              if (
                [
                  PostgresMajorVersionUpgradeStage.Uninitiated,
                  PostgresMajorVersionUpgradeStage.Finished,
                ].includes(data.getPostgresMajorVersionUpgradeStatus?.stage)
              ) {
                this.getPostgresAndTimescaleDbVersions({ serviceId });
                this._deletePostgresVersionUpgradeQueryWatcher(serviceId!);
              }
            }
          },
        );
    } catch {}
  };

  upgradeService = async ({
    projectId = projectsStore?.currentProject?.id,
    serviceId = this?.service.id,
    postgresMajorVersion,
  }: {
    projectId?: string;
    serviceId?: string;
    postgresMajorVersion: number;
  }): Promise<void | boolean> => {
    const { data, errors } = await client.mutate({
      variables: {
        projectId,
        serviceId,
        postgresVersion: postgresMajorVersion,
      },
      mutation: UPGRADE_SERVICE_POSTGRES_MAJOR_VERSION,
    });
    await this.getAllServices();
    if (errors) {
      notificationStore.showErrorToaster(
        "There was an error initiating upgrade",
      );
      return false;
    }
    if (data) {
      notificationStore.showSuccessToaster("Upgrade initiated");
      return true;
    }
  };

  _explorerDefaultErrors = (errors: readonly GraphQLError[]) => {
    notificationStore.showErrorToaster(`Error: ${errors[0].message}.`);
    return null;
  };

  _explorerQuery =
    <T extends DocumentNode, Q extends any, R extends any>({
      query,
      onData = () => null,
      onErrors = this._explorerDefaultErrors,
      onNoData = () => null,
      timeout = 60000,
      errorItem = "service info",
    }: {
      query: T;
      onData: (data: Q) => R | null;
      onErrors?: (errors: readonly GraphQLError[]) => void | null;
      onNoData?: () => void | null;
      timeout?: number;
      errorItem?: string;
    }) =>
    async (explorerVariables = {}) => {
      if (this.service.status === DeployStatus.Ready) {
        try {
          const { data, errors } = await client.query({
            variables: {
              ...this.explorerDefaultVariables,
              ...explorerVariables,
            },
            query,
            context: { timeout },
          });

          if (errors) {
            return onErrors(errors);
          } else if (data) {
            return onData(data);
          } else if (!data) {
            return onNoData();
          }
        } catch (e) {
          notificationStore.showErrorToaster(
            `There was an error retrieving ${errorItem}.  Please reload the page or contact us for help.`,
          );
          return null;
        }
      }
    };

  getServiceTables = this._explorerQuery({
    query: GET_SERVICE_TABLES,
    onData: (data: GetServiceTablesQuery) => data?.[GET_SERVICE_TABLES__TYPE],
  });

  getServiceContAggs = this._explorerQuery({
    query: GET_SERVICE_CONTINUOUS_AGGREGATES,
    onData: (data: GetServiceContinuousAggregatesQuery) =>
      data?.[GET_SERVICE_CONTINUOUS_AGGREGATES__TYPE],
    errorItem: "continuous aggregates",
  });

  getServiceGeneralInfo = this._explorerQuery({
    query: GET_SERVICE_GENERAL_INFORMATION,
    onData: (data: GetServiceGeneralInformationQuery) => ({
      ...data?.[GET_SERVICE_GENERAL_INFORMATION__TYPE]?.info,
      ...data?.[GET_SERVICE_GENERAL_INFORMATION__TYPE]?.compressionV2,
      policyList: orderBy(
        data?.[GET_SERVICE_GENERAL_INFORMATION__TYPE]?.policyCounts,
        ["count"],
        ["desc"],
      ),
    }),
  });

  getServiceLargestObjects = this._explorerQuery({
    query: GET_SERVICE_LARGEST_OBJECTS,
    onData: (data: GetServiceLargestObjectsQuery) =>
      data?.[GET_SERVICE_LARGEST_OBJECTS__TYPE]?.map(({ size, objectId }) => ({
        size,
        name: objectId.name,
        schema: objectId.schema,
        type:
          objectId.__typename === "TableID"
            ? objectId.isHypertable
              ? "hypertable"
              : "table"
            : "continuous aggregate",
      })).sort((obj1, obj2) => obj2?.size - obj1?.size),
    onNoData: () => {
      console.log("getServiceLargestObjects: DATA IS NULL/UNDEFINED");
      return null;
    },
  });

  getServiceTableInfo = this._explorerQuery({
    query: GET_SERVICE_TABLE_INFO,
    onData: (data: GetTableInfoQuery) => {
      let parsedData = data?.[GET_SERVICE_TABLE_INFO__TYPE];
      let mutableParsedData: Partial<Table> = {};
      if (parsedData) {
        mutableParsedData = { ...parsedData } as Table;
        if (parsedData?.hyperMetadata) {
          // @ts-ignore
          mutableParsedData.hyperMetadata = {
            ...mutableParsedData.hyperMetadata,
            timeInterval:
              parsedData?.hyperMetadata?.timeInterval ||
              parsedData?.hyperMetadata?.integerInterval.toString(),
          };
        }
        if (parsedData?.chunks) {
          mutableParsedData.chunks = parsedData?.chunks;
        }
        mutableParsedData = {
          ...mutableParsedData,
        };
      }
      return mutableParsedData;
    },
    errorItem: "table data",
  });

  getObjectsMetadata = this._explorerQuery({
    query: GET_OBJECTS_METADATA,
    // TODO: genereate types for this query (is not there yet)
    onData: (data: GetObjectsMetadataQuery) => {
      return data?.[GET_OBJECTS_METADATA__TYPE].map(
        ({
          metadata: {
            __typename,
            size,
            id: { name, schema, isHypertable },
            originalHypertable,
          },
          hyperMetadata,
        }: any) => {
          if (__typename === "TableMetadata") {
            return {
              schema,
              name,
              size,
              timeInterval: hyperMetadata?.timeInterval || "-",
              numChunks: hyperMetadata?.numChunks || "-",
              type: isHypertable ? "hypertable" : "table",
              compressionStats: {
                savings: hyperMetadata?.compressionStats?.savings,
              },
            };
          } else if (__typename === "ContAggMetadata") {
            return {
              schema,
              name,
              size,
              timeInterval: hyperMetadata?.timeInterval || "-",
              numChunks: hyperMetadata?.numChunks || "-",
              hypertableName: originalHypertable.name,
              hypertableSchema: originalHypertable.schema,
              type: "continuous aggregate",
              compressionStats: {
                savings: hyperMetadata?.compressionStats?.savings,
              },
            };
          }
          return { schema, name, size, type: "unknown" };
        },
      );
    },
    onNoData: () => {
      console.log("getObjectsMetadata: DATA IS NULL/UNDEFINED");
      return null;
    },
    errorItem: "getObjectsMetadata data error",
  });

  getServiceCaggInfo = this._explorerQuery({
    query: GET_SERVICE_CAGG_INFO,
    onData: (data: GetServiceCaggInfoQuery) => {
      let parsedData = data?.[GET_SERVICE_CAGG_INFO__TYPE];
      let mutableParsedData: Partial<ContAggInfo> = {};
      if (parsedData) {
        // @ts-ignore
        mutableParsedData = { ...parsedData };
        mutableParsedData = {
          ...mutableParsedData,
          // @ts-ignore
          refreshPolicies: mutableParsedData?.refreshPolicies?.[0],
        };
      }
      return mutableParsedData;
    },
    errorItem: "continuous aggregate data",
  });

  getAllServicesByProject = async (projectId: string) => {
    if (!projectId) {
      return;
    }
    try {
      const { data } = await client.query({
        variables: {
          projectId: projectId,
        },
        query: GET_ALL_SERVICES,
        ...this.options,
      });

      return data?.getAllServices;
    } catch (errors) {
      commonStore.setIsDataAvailable(false);
      console.error(
        `Error retrieving services for projectId ${projectId}`,
        errors,
      );
    } finally {
      commonStore.setAppState(APP_STATE.READY);
    }
  };

  attachExporter = async ({
    projectId = projectsStore?.currentProject?.id,
    serviceId = this?.serviceId,
    exporter,
  }: {
    projectId?: string;
    serviceId?: string;
    exporter: Exporter;
  }) => {
    try {
      const { errors } = await client.mutate({
        variables: {
          projectId,
          serviceId,
          exporterId: exporter.id,
        },
        mutation:
          exporter.dataType === "LOGS"
            ? ATTACH_SERVICE_TO_GENERIC_EXPORTER
            : ATTACH_SERVICE_TO_METRIC_EXPORTER,
      });
      if (errors) {
        notificationStore.showErrorToaster(
          `Unable to attach exporter. ${errors[0].message}`,
        );
      } else {
        notificationStore.showSuccessToaster(
          "Your exporter has been successfully attached.",
        );
        await this.getAllServices();
      }
    } catch {
      console.error("attachMetricExporter error");
    }
  };

  detachExporter = async ({
    projectId = projectsStore?.currentProject?.id,
    serviceId = this?.serviceId,
    exporter,
  }: {
    projectId?: string;
    serviceId?: string;
    exporter: Exporter;
  }) => {
    try {
      const { errors } = await client.mutate({
        variables: {
          projectId,
          serviceId,
          exporterId: exporter.id,
        },
        mutation:
          exporter.dataType === "LOGS"
            ? DETACH_SERVICE_FROM_GENERIC_EXPORTER
            : DETACH_SERVICE_FROM_METRIC_EXPORTER,
      });
      if (errors) {
        notificationStore.showErrorToaster(
          `Unable to detach exporter. ${errors[0].message}`,
        );
      } else {
        notificationStore.showSuccessToaster(
          "Your exporter has been successfully detached.",
        );
        await this.getAllServices();
      }
    } catch {
      console.error("detachMetricExporter error");
    }
  };

  deleteCompressionHypertable = async ({
    serviceId = this?.service.id,
    projectId = projectsStore?.currentProject?.id,
    regionCode = this.service?.regionCode,
    tableSchema,
    tableName,
  }: {
    serviceId?: string;
    projectId?: string;
    regionCode?: string;
    tableSchema: string;
    tableName: string;
  }) => {
    try {
      const { data, errors } = await client.mutate({
        variables: {
          serviceId,
          projectId,
          regionCode,
          tableSchema,
          tableName,
        },
        mutation: DELETE_COMPRESSION_HYPERTABLE,
      });
      if (data) {
        notificationStore.showSuccessToaster(
          "Compression policy has been deleted",
        );
        return data;
      }
      if (errors) {
        notificationStore.showErrorToaster(`Error: ${errors[0].message}.`);
      }
    } catch (errors) {
      notificationStore.showErrorToaster(
        "Sorry! We are unable to delete compression policy at the moment. Please try again later or contact us through support.",
      );
    }
  };

  dismissCTAforHypertable = (serviceId: string) => {
    const updateSuggestedHypertableCompressionList =
      this.suggestHypertableCompressionList;
    delete updateSuggestedHypertableCompressionList[serviceId];

    userStore.mergeUiState({
      [COMPRESSION_SUGGESTION]: { [serviceId]: false },
    });

    runInAction(
      () =>
        (this.suggestHypertableCompressionList =
          updateSuggestedHypertableCompressionList),
    );
  };

  setCompressionHypertable = async ({
    serviceId = this?.service.id,
    projectId = projectsStore?.currentProject?.id,
    regionCode = this.service?.regionCode,
    tableSchema,
    tableName,
    config,
    olderThan,
    enableCompression,
  }: {
    serviceId?: string;
    projectId?: string;
    regionCode: string;
    tableSchema: string;
    tableName: string;
    config: CompressionConfig;
    olderThan: string;
    enableCompression: boolean;
  }): Promise<void | {
    data: SetCompressionHypertableMutation;
    errors?: readonly GraphQLError[];
  }> => {
    try {
      if (enableCompression) {
        await client.mutate({
          variables: {
            serviceId,
            projectId,
            regionCode,
            config,
            tableSchema,
            tableName,
          },
          mutation: SET_COMPRESSION_HYPERTABLE,
        });
      }

      const { data, errors } = await client.mutate({
        variables: {
          serviceId,
          projectId,
          regionCode,
          tableSchema,
          tableName,
          olderThan,
        },
        mutation: ADD_COMPRESSION_POLICY,
      });

      this.dismissCTAforHypertable(serviceId!);
      return { data, errors };
    } catch (e) {
      console.log("Error", e);
    }
  };

  setCompression = async ({
    serviceId = this?.service.id,
    projectId = projectsStore?.currentProject?.id,
    regionCode = this.service?.regionCode,
    schema,
    tableName,
    contAggName,
    config,
    olderThan,
    enableCompression,
  }: {
    serviceId?: string;
    projectId?: string;
    regionCode: string;
    schema: string;
    tableName?: string;
    contAggName?: string;
    config: CompressionConfig;
    olderThan: string;
    enableCompression: boolean;
  }): Promise<void | {
    data: { __typename?: "Mutation"; setCompression: any };
    errors?: readonly GraphQLError[];
  }> => {
    try {
      if (enableCompression) {
        await client.mutate({
          variables: {
            serviceId,
            projectId,
            regionCode,
            config,
            schema,
            tableName,
            contAggName,
          },
          mutation: SET_COMPRESSION,
        });
      }

      const { data, errors } = await client.mutate({
        variables: {
          serviceId,
          projectId,
          regionCode,
          tableSchema: schema,
          tableName,
          contAggName,
          olderThan,
        },
        mutation: ADD_COMPRESSION_POLICY,
      });

      this.dismissCTAforHypertable(serviceId!);
      return { data, errors };
    } catch (e) {
      console.log("Error", e);
    }
  };

  compressionToolUtilized = () => {
    userStore.mergeUiState({
      [COMPRESSION_USED]: { [this.serviceId]: true },
    });
  };

  getHypertableList = async () => {
    const serviceCompressionList: {
      [key: string]: {
        tableSchema: string;
        tableName: string;
        id: TableId;
      };
    } = {};

    if (this.allServices?.length > 0) {
      await Promise.all(
        this.allServices.map(
          async ({
            id,
            status,
            forkedFromId,
            projectId,
            regionCode,
            metrics,
          }): Promise<void | null> => {
            if (
              status !== DeployStatus.Ready ||
              (metrics?.storageMB ?? 0) < 1024 * 1024 ||
              forkedFromId?.isStandby
            ) {
              return null;
            }

            const { data } = await client.query({
              variables: {
                serviceId: id,
                projectId,
                regionCode,
              },
              query: GET_SERVICE_GENERAL_INFORMATION,
            });

            if (
              data?.[GET_SERVICE_GENERAL_INFORMATION__TYPE]?.compressionV2 ===
              null
            ) {
              const { data } = await client.query({
                variables: {
                  serviceId: id,
                  projectId,
                  regionCode,
                },
                query: GET_SERVICE_TABLES,
              });

              const firstHypertable = data?.[GET_SERVICE_TABLES__TYPE].find(
                (table: TableId) => table.isHypertable,
              );

              if (firstHypertable) {
                serviceCompressionList[id] = {
                  tableSchema: firstHypertable.schema,
                  tableName: firstHypertable.name,
                  id: firstHypertable,
                };
              }
            }
          },
        ),
      );

      runInAction(() => {
        this.suggestHypertableCompressionList = serviceCompressionList;
      });
    }
  };

  setHypertableConfig = async ({
    serviceId = this?.service.id,
    projectId = projectsStore?.currentProject?.id,
    schema,
    tableName,
    chunkSizeIntervalDuration,
  }: {
    serviceId?: string;
    projectId?: string;
    schema: string;
    tableName: string;
    chunkSizeIntervalDuration: string;
  }): Promise<void | {
    data: SetHypertableConfInput;
    errors?: readonly GraphQLError[];
  }> => {
    try {
      const { data, errors } = await client.mutate({
        variables: {
          serviceId,
          projectId,
          schema,
          tableName,
          chunkSizeIntervalDuration,
        },
        mutation: SET_HYPERTABLE_CONFIG,
      });
      return { data, errors };
    } catch (error) {
      console.log(error);
    }
  };

  instanceSwitchover = async (): Promise<void | {
    data: SwitchoverInstanceMutation;
    errors?: readonly GraphQLError[];
  }> => {
    try {
      const { data, errors } = await client.mutate({
        variables: {
          serviceId: this.serviceId,
          projectId: projectsStore?.currentProject?.id,
        },
        mutation: SWITCHOVER_INSTANCE,
      });
      return { data, errors };
    } catch (error) {
      console.log(error);
    }
  };

  getPostgresAvailableExtensions = async () => {
    try {
      const { data, errors } = await client.query({
        variables: {
          serviceId: this.serviceId,
          projectId: projectsStore?.currentProject?.id,
        },
        query: GET_POSTGRES_AVAILABLE_EXTENSIONS,
      });

      if (data) {
        runInAction(
          () =>
            (this.postgresAvailableExtensions = sortBy(
              data[GET_POSTGRES_AVAILABLE_EXTENSIONS__TYPE],
              "name",
            )),
        );
      }

      if (errors) {
        notificationStore.showErrorToaster(
          `Error getting available PostgreSQL extensions: ${errors[0].message}.`,
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  toggleConnectionPooler = async (enable: boolean) => {
    try {
      const { data, errors } = await client.mutate({
        variables: {
          projectId: projectsStore?.currentProject?.id,
          serviceId: this.service?.id,
          enable,
        },
        mutation: TOGGLE_CONNECTION_POOLER,
      });
      if (data) {
        notificationStore.showSuccessToaster(
          `Connection pooler was ${enable ? "added" : "removed"} successfully.`,
        );
        this.getAllServices();
        return data;
      } else if (errors) {
        notificationStore.showErrorToaster(
          "Failed to toggle connection pooler.",
        );
      }
    } catch (e) {
      console.log("toggleConnectionPooler: Network error", e);
    }
  };

  getRecoveryWindows = async ({
    serviceId,
    showError = true,
  }: {
    serviceId: string;
    showError?: boolean;
  }) => {
    try {
      const { data, errors } = await client.query({
        variables: {
          projectId: projectsStore?.currentProject?.id,
          serviceId,
        },
        query: GET_RECOVERY_WINDOWS,
      });
      if (data) {
        runInAction(
          () =>
            (this.recoveryWindows = {
              ...this.recoveryWindows,
              // sort oldest start time to newest
              [serviceId]: data[GET_RECOVERY_WINDOWS__TYPE].sort(
                (
                  { startTime: a }: { startTime: string },
                  { startTime: b }: { startTime: string },
                ) => {
                  const first = dayjs(a);
                  const second = dayjs(b);
                  return first.isBefore(second)
                    ? -1
                    : first.isAfter(second)
                      ? 1
                      : 0;
                },
              ),
            }),
        );
      } else if (errors && showError) {
        notificationStore.showErrorToaster("Failed to get recovery windows.");
      }
    } catch (e) {
      console.log("getRecoveryWindows: Network error", e);
    }
  };

  getJobList = async ({ serviceId }: { serviceId: string }) => {
    try {
      const { data, errors } = await client.query({
        variables: {
          projectId: projectsStore?.currentProject?.id,
          serviceId,
        },
        query: GET_JOB_LIST,
      });
      if (data) {
        return data?.[GET_JOB_LIST__TYPE];
      } else if (errors) {
        notificationStore.showErrorToaster("Failed to get job list.");
      }
    } catch (e) {
      console.log("getJobList: Network error", e);
    }
  };

  unsubscribeRecoveryProgress = ({ serviceId }: { serviceId: string }) => {
    if (this.recoveryProgressWatchQueries[serviceId]) {
      this.recoveryProgressWatchQueries[serviceId]?.stopPolling?.();
      this.recoveryProgressQuerySubscription[serviceId]?.unsubscribe?.();
      runInAction(() => {
        const { [serviceId]: _, ...rest } = this.recoveryProgressWatchQueries;
        this.recoveryProgressWatchQueries = rest;
      });
    }
  };

  subscribeRecoveryProgress = async ({ serviceId }: { serviceId: string }) => {
    try {
      if (!projectsStore?.currentProject) return;

      this.unsubscribeRecoveryProgress({ serviceId });
      runInAction(() => {
        this.recoveryProgressWatchQueries[serviceId] = client.watchQuery({
          variables: {
            projectId: projectsStore?.currentProject?.id,
            serviceId: serviceId,
          },
          query: GET_RECOVERY_PROGRESS,
          pollInterval: 30000,
          ...this.options,
        });

        this.recoveryProgressQuerySubscription[serviceId] =
          this.recoveryProgressWatchQueries[serviceId].subscribe(
            ({ data, errors }) => {
              if (errors) {
                return this.unsubscribeRecoveryProgress({ serviceId });
              }

              if (data) {
                runInAction(() => {
                  this.recoveryProgressInfo = {
                    ...this.recoveryProgressInfo,
                    [serviceId]: data[GET_RECOVERY_PROGRESS__TYPE],
                  };
                });
              }
            },
          );
      });
    } catch (errors) {
      console.error("getRecoveryProgress: error", errors);
    }
  };
}

const serviceStore = new ServiceStore();

export default serviceStore;
