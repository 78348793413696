import { autorun, runInAction, makeAutoObservable, toJS } from "mobx";
import { v4 as uuidv4 } from "uuid";
import flagsmith from "flagsmith";
import userStore from "./userStore";
import commonStore, { APP_STATE } from "./commonStore";
import productStore from "./productStore";
import serviceStore from "./serviceStore";
import projectsStore from "./projectsStore";
import { LoginMfaQuery, ResetPasswordMutation, User } from "graphql/generated";
import { client } from "api/client";
import {
  LOGIN_BASIC,
  LOGIN_MFA_CHALLENGE,
  LOGOUT,
  MFA_CHALLENGE,
  VALIDATE_RESET_TOKEN_MFA,
} from "api/query";
import {
  CHANGE_PASSWORD,
  RESET_PASSWORD,
  ADD_MFA_METHOD,
  DELETE_MFA_METHOD,
  REGENERATE_MFA_RECOVERY_CODES,
} from "api/mutations";
import featuresStore from "./featuresStore";
import notificationStore from "./notificationStore";
import { ApolloQueryResult, FetchResult } from "@apollo/client";
import { GraphQLError } from "graphql";
import { ENV } from "utils/hostEnv";
import { PROD } from "utils/config";
import { Statsig } from "statsig-react";

type AuthCode = { authCode: string; resetToken?: string };

export class AuthStore {
  errors?:
    | GraphQLError
    | ReadonlyArray<GraphQLError>
    | Pick<GraphQLError, "message">;
  inProgress = false;
  pageLoaded = false;
  forgotPassword = false;
  resendEmail = false;
  emailVerified = false;
  isResetPasswordPending = false;
  differentUser = false;
  operationTokens = {} as Record<string, string>;
  resetPasswordPromise = null as unknown as Promise<
    FetchResult<ResetPasswordMutation>
  >;
  mfaAuthCode = "";
  mfaTrustToken = null; //token returned on successful MFA challenge.  Don't store in web storage!
  mfaChallengeToken = null as unknown as string; //token to verify client sending request is the same that passed mfa challenge
  mfaSecret = ""; //secret generated to add MFA: goes to authenticator app
  twoFactorId = ""; //verification code to send with authenticator code on challenge
  recoveryCodes = [];

  constructor() {
    makeAutoObservable(this);

    autorun(async () => {
      try {
        if (userStore.currentId) {
          this.setProgress(false);
          setTimeout(async () => {
            try {
              const user = await userStore.getUser();
              await userStore.subscribeUserInvitations();
              await projectsStore.getAllProjects();

              if (!projectsStore.currentProject) {
                runInAction(
                  () =>
                    (projectsStore.currentProject =
                      projectsStore.ownedProjects[0]),
                );
              } else {
                const lastProject = projectsStore.allProjects.find(
                  (project) =>
                    project.id === JSON.parse(user.uiState)?.selectedProjectId,
                );

                const currentProject = projectsStore.allProjects.find(
                  (project) => project.id === projectsStore.currentProject?.id,
                );

                runInAction(
                  () =>
                    (projectsStore.currentProject = lastProject
                      ? lastProject
                      : currentProject
                        ? currentProject
                        : projectsStore.ownedProjects[0]),
                );
              }

              // (StatSig's userID) = (web-cloud projectId), NOT userId. See README
              await Statsig.updateUser({
                userID: projectsStore.currentProject?.id,
                email: user.email,
                customIDs: { timescaleUserId: userStore.currentId },
                custom: {
                  project_created: projectsStore?.currentProject?.created,
                },
              });
              userStore.setThirdPartyIdentify({
                id: user.id,
                name: user.name,
                email: user.email,
                created: user.created,
              });
              await productStore.getProducts();
              commonStore.setAppState(APP_STATE.READY);
            } catch {
              console.error("Error intializing authStore");
            }
            // finally {
            //   userStore.subscribeUserInvitations();
            // }
          }, 1000); //To prevent backend bug with token invalidation
        }
      } catch {
        console.log("Error retrieving upon refresh");
      }
    });
  }

  get needsMfaChallenge() {
    return userStore.hasMfa && !this.mfaTrustToken;
  }

  login = async ({
    email,
    password,
    authCode,
  }: {
    email: string;
    password: string;
    authCode: string;
  }) => {
    this.setProgress(true);
    this.errors = undefined;

    try {
      let resp = {} as ApolloQueryResult<LoginMfaQuery>;
      let loginData;
      if (this.twoFactorId) {
        resp = await client.query({
          query: LOGIN_MFA_CHALLENGE,
          variables: {
            twoFactorId: this.twoFactorId,
            code: authCode,
          },
        });
      } else {
        resp = await client.query({
          query: LOGIN_BASIC,
          variables: {
            email,
            password,
          },
        });
      }
      loginData = resp?.data?.loginMfa;
      if ("user" in loginData) {
        this.setAccount(loginData.user);
        runInAction(() => {
          this.twoFactorId = "";
        });
      }
      if ("twoFactorId" in loginData) {
        const twoFactorId = loginData.twoFactorId;
        runInAction(() => {
          this.twoFactorId = twoFactorId;
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setProgress(false);
    }
  };

  checkAuthorization = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      const { data } = await client.query({
        query: LOGIN_BASIC,
        variables: { email, password },
      });
      if (data) {
        return true;
      } else {
        this.setError(data?.errors);
        return null;
      }
    } catch (errors) {
      console.log("Password error", errors);
      return null;
    }
  };

  logout = async () => {
    try {
      if (userStore.currentId) {
        this.setProgress(true);
        if (ENV.NAME === PROD) {
          window.heap && window.heap.resetIdentity();
        }
        localStorage.clear();
        sessionStorage.clear();
        featuresStore.clearStore();
        flagsmith.logout();
        const { errors } = await client.query({
          query: LOGOUT,
        });
        if (errors) {
          console.log("Error logging out of session.");
        }
        window.location.reload();
      }
    } catch {
      notificationStore.showErrorToaster(
        "Error logging out, please try again.",
      );
    }
  };

  setProgress = (status: boolean) => {
    runInAction(() => (this.inProgress = status));
  };

  setAccount = ({
    id,
    name,
    email,
    phone,
  }: Pick<User, "id" | "email"> & Partial<Pick<User, "name" | "phone">>) => {
    userStore.updateId(id);

    // Checks to see if user is logging in with a
    // different user
    if (userStore.currentEmail !== email) {
      this.setDifferentUser(true);
      // Change default cached values if changed to a different
      // user upon login
      serviceStore.resetService();
      serviceStore.resetAllService();
    } else {
      this.setDifferentUser(false);
    }
    userStore.updateEmail(email);

    userStore.updatePhone(!!phone ? phone : "");
    userStore.updateName(!!name ? name : "");
  };

  setError = (errors: AuthStore["errors"]) => {
    runInAction(() => {
      this.errors = Array.isArray(errors)
        ? errors.length > 1
          ? errors
          : errors[0]
        : errors;
      this.setProgress(false);
    });
  };

  setForgotPassword = (status: boolean) => {
    runInAction(() => {
      this.forgotPassword = status;
    });
  };

  setResendEmail = (status: boolean) => {
    runInAction(() => {
      this.resendEmail = status;
    });
  };

  setResetPasswordStatus = (status: boolean) => {
    runInAction(() => {
      this.isResetPasswordPending = status;
    });
  };

  setDifferentUser = (status: boolean) => {
    runInAction(() => {
      this.differentUser = status;
    });
  };

  resetEmailFlow = () => {
    this.setForgotPassword(false);
    this.setResetPasswordStatus(false);
    this.setResendEmail(false);
    this.resetError();
    this.setProgress(false);
  };
  resetError = () => {
    runInAction(() => {
      this.errors = undefined;
    });
  };
  bindOperationToken = ({
    operationName,
    token,
  }: {
    operationName: string;
    token: string;
  }) => {
    runInAction(() => {
      this.operationTokens[operationName] = toJS(token);
    });
  };

  _setMfaVariables = (variables: Record<string, string>) => {
    if (userStore.hasMfa) {
      if (this.mfaTrustToken && this.mfaChallengeToken) {
        return {
          ...variables,
          trustToken: this.mfaTrustToken,
          trustChallenge: this.mfaChallengeToken,
        };
      } else {
        notificationStore.showErrorToaster(
          "You cannot change your password without first verifying using two-factor authentication",
        );
      }
    }
    return variables;
  };

  changePassword = async ({
    password,
    newPassword,
    repeatPassword,
  }: {
    password: string;
    newPassword: string;
    repeatPassword: string;
  }) => {
    try {
      let variables = {
        oldPassword: password,
        newPassword,
        newPasswordConfirm: repeatPassword,
      };
      const { data } = await client.mutate({
        mutation: CHANGE_PASSWORD,
        variables: this._setMfaVariables(variables),
      });
      if (data) {
        notificationStore.showSuccessToaster("Password successfully changed.");
        this.resetError();
        return true;
      } else {
        throw Error();
      }
    } catch {
      notificationStore.showErrorToaster(
        "Something went wrong. Please try again",
      );
      return false;
    } finally {
      this.clearMfaChallenge();
    }
  };

  validateResetPasswordToken = async (token: string) => {
    const { data, errors } = await client.query({
      variables: {
        token,
      },
      query: VALIDATE_RESET_TOKEN_MFA,
      context: { timeout: 30000 }, //To override default in apollo-link-timeout (client.js)
    });
    userStore.setMfaMethods(data?.validateResetTokenMfa?.mfaMethods || []);
    return { data, errors };
  };

  resetPassword = ({
    password,
    passwordConfirm,
    token,
  }: {
    password: string;
    passwordConfirm: string;
    token: string;
  }) => {
    try {
      if (!this.resetPasswordPromise) {
        let variables = {
          password,
          passwordConfirm,
          token,
        };
        runInAction(() => {
          this.resetPasswordPromise = client.mutate({
            variables: this._setMfaVariables(variables),
            mutation: RESET_PASSWORD,
          });
        });
      }
      return this.resetPasswordPromise;
    } catch {
      return {};
    }
  };

  addMfa = async ({ authCode }: AuthCode) => {
    try {
      if (this.mfaSecret && authCode) {
        const { data } = await client.mutate({
          mutation: ADD_MFA_METHOD,
          variables: {
            code: authCode,
            secret: this.mfaSecret,
          },
        });
        if (data?.addMfaMethod?.recoveryCodes) {
          await userStore.getUser();
          runInAction(() => {
            this.recoveryCodes = data?.addMfaMethod?.recoveryCodes;
          });
          this.mfaSecret = "";
          this.resetError();
          return true;
        }
      }
      throw Error;
    } catch {
      return false;
    }
  };

  removeMfa = async ({ authCode }: AuthCode) => {
    try {
      if (authCode) {
        const { data } = await client.mutate({
          mutation: DELETE_MFA_METHOD,
          variables: {
            code: authCode,
            id: userStore.mfaMethods?.[0]?.id,
          },
        });
        if (data) {
          runInAction(() => {
            userStore.resetMfaMethods();
            this.recoveryCodes = [];
          });
          this.resetError();
          return true;
        }
      }
      throw Error;
    } catch {
      return false;
    }
  };

  fetchMfaTrustToken = async ({ authCode, resetToken }: AuthCode) => {
    try {
      if (authCode) {
        this.generateMfaChallengeToken();
        const { data } = await client.query({
          query: MFA_CHALLENGE,
          variables: {
            code: authCode,
            trustChallenge: this.mfaChallengeToken,
            ...(resetToken ? { resetToken } : {}),
          },
        });
        if (data) {
          runInAction(() => {
            this.mfaTrustToken = data?.mfaChallenge?.trustToken;
          });
          this.resetError();
          return true;
        }
      }
      throw Error();
    } catch {
      return false;
    }
  };

  generateMfaChallengeToken = ({ shouldReset = false } = {}) => {
    runInAction(() => {
      this.mfaChallengeToken = shouldReset ? "" : uuidv4();
    });
  };

  regenerateMfaRecoveryCodes = async ({ authCode }: AuthCode) => {
    if (authCode) {
      const { data } = await client.mutate({
        mutation: REGENERATE_MFA_RECOVERY_CODES,
        variables: {
          code: authCode,
        },
      });
      if (Array.isArray(data?.regenerateRecoveryCodes)) {
        runInAction(() => {
          this.recoveryCodes = data?.regenerateRecoveryCodes;
        });
        this.resetError();
        return true;
      }
    }
    return false;
  };

  removeRecoveryCodes = () => {
    runInAction(() => {
      this.recoveryCodes = [];
    });
  };

  setMfaSecret = ({ secret = "" } = {}) => {
    runInAction(() => {
      this.mfaSecret = secret;
    });
  };

  resetMfaLogin = () => {
    runInAction(() => {
      this.twoFactorId = "";
    });
  };

  clearMfaChallenge = () => {
    runInAction(() => {
      this.mfaTrustToken = null;
      this.generateMfaChallengeToken({ shouldReset: true });
    });
  };
}

const authStore = new AuthStore();

export default authStore;
