import { EXP_EVENTS, EXP_META } from "./featuresGates";

// StatSig experiments
type ExperimentConfig = {
  ID: string; // The experiment_name on Statsig
  NAME: string; // The display name of the experiment.
  PARAMS: { SHOW_FEATURE: PARAMETERS.SHOW_FEATURE }; // corresponds to the parameters in Statsig
  DESCRIPTION: string; // Keep it short and concise!
  EXPIRATION: string; // The date the experiment plans on being removed.
  EVENTS?: { [key in EXP_EVENTS]?: string }; // Optional events that can be logged for this experiment.
  META?: { [key in EXP_META]?: string }; // Optional meta events that can be logged for this experiment.
};

// A template for creating new experiments.
//
// export const TEMPLATE: ExperimentConfig = {
//   ID: "",
//   NAME: "",
//   PARAMS: { SHOW_FEATURE: PARAMETERS.SHOW_FEATURE },
//   DESCRIPTION: "",
//   EXPIRATION: "",
//   EVENTS: {},
//   META: {},
// };

// For A/B Testing
enum PARAMETERS {
  SHOW_FEATURE = "show_feature",
}

export const EXP_COMPUTE_DEFAULT_1_CPU: ExperimentConfig & {
  EVENTS: {
    SERVICE_CREATED: EXP_EVENTS.SERVICE_CREATED;
    SERVICE_DELETED: EXP_EVENTS.SERVICE_DELETED;
  };
} = {
  ID: "exp_compute_default_1_cpu",
  NAME: "Default Compute 1 CPU",
  PARAMS: { SHOW_FEATURE: PARAMETERS.SHOW_FEATURE },
  DESCRIPTION: "",
  EXPIRATION: "2024-10-01",
  EVENTS: {
    SERVICE_CREATED: EXP_EVENTS.SERVICE_CREATED,
    SERVICE_DELETED: EXP_EVENTS.SERVICE_DELETED,
  },
  META: {},
};

export const EXP_SAMPLE_DATA_V2: ExperimentConfig = {
  ID: "exp_sample_data_v2",
  NAME: "Sample Data V2",
  PARAMS: { SHOW_FEATURE: PARAMETERS.SHOW_FEATURE },
  DESCRIPTION: "Adds the ability to preload a service with sample data.",
  EXPIRATION: "2024-08-30",
  EVENTS: {},
  META: {},
};

export const EXP_ACTIVATION_HUB: ExperimentConfig = {
  ID: "exp_activation_hub",
  NAME: "Activation Hub",
  PARAMS: { SHOW_FEATURE: PARAMETERS.SHOW_FEATURE },
  DESCRIPTION: "Adds a new actions tab to service page",
  EXPIRATION: "2024-09-30",
  EVENTS: {},
  META: {},
};

export const EXP_PARQUET_IMPORT: ExperimentConfig = {
  ID: "exp_parquet_import",
  NAME: "Parquet Import - Upload UI",
  PARAMS: { SHOW_FEATURE: PARAMETERS.SHOW_FEATURE },
  DESCRIPTION: "",
  EXPIRATION: "2024-09-30",
  EVENTS: {},
  META: {},
};

export const EXP_CREATE_HT: ExperimentConfig = {
  ID: "exp_create_ht",
  NAME: "Create Hypertable flow",
  PARAMS: { SHOW_FEATURE: PARAMETERS.SHOW_FEATURE },
  DESCRIPTION:
    "As a part of new service flow, Create Hypertable is added as step 3",
  EXPIRATION: "2024-10-01",
  EVENTS: {},
  META: {},
};

// Add to the list to show for the ExperimentsModal.
export const ALL_EXPERIMENTS: ExperimentConfig[] = [
  EXP_COMPUTE_DEFAULT_1_CPU,
  EXP_SAMPLE_DATA_V2,
  EXP_ACTIVATION_HUB,
  EXP_PARQUET_IMPORT,
  EXP_CREATE_HT,
];
