import { CSSObject, StyleProps, Tag } from "@chakra-ui/react";
import Icon, { Icons } from "components/icon/Icon";
import { SIZE_CONFIG } from "components/tags/tagStyles";
import { ThemeColors } from "theme/foundations/colors";

export interface TagInfoProps extends StyleProps {
  label: string;
  isLight?: boolean;
  isLarge?: boolean;
  iconName?: Icons;
  sx?: CSSObject;
  [key: string]: any;
}

const tagInfoStyles = {
  borderRadius: "4px",
  border: "none",
  padding: "2px 8px",
};

const TAG_INFO_CONFIG = (isLight: boolean) => {
  if (isLight) {
    return {
      background: "primary.400",
      color: "grayscale.700",
    };
  }

  return {
    background: "primary.900",
    color: "grayscale.0",
  };
};

const TagInfo = ({
  label,
  isLight = false,
  isLarge = false,
  iconName,
  sx,
  ...customStyles
}: TagInfoProps) => {
  return (
    <Tag
      sx={{
        ...tagInfoStyles,
        ...TAG_INFO_CONFIG(isLight),
        ...(isLarge ? SIZE_CONFIG["large"] : {}),
        ...sx,
      }}
      {...customStyles}
    >
      {iconName && (
        <Icon
          name={iconName}
          fill={
            sx?.color
              ? (sx?.color as ThemeColors)
              : isLight
                ? "grayscale.700"
                : "grayscale.0"
          }
          mr={2}
        />
      )}

      {label}
    </Tag>
  );
};

export default TagInfo;
