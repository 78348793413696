import { Flex, Grid, Text, Box } from "@chakra-ui/react";
import Icon from "components/icon/Icon";
import { Icons } from "components/icon/iconTypes";
import { useHistory } from "react-router-dom";
import { ThemeColors } from "theme/foundations/colors";
import { useContext, useEffect } from "react";
import { ConnectionProgressContext } from "routes/Layout";
import { useStores } from "stores";
import { sampleDataTypeFromServiceName } from "utils/sampleData";
import { observer } from "mobx-react";
import { Statsig } from "statsig-react";
import { EXP_CREATE_HT } from "utils/featuresExperiments";

export enum StepState {
  CURRENT = "current",
  COMPLETE = "complete",
  INCOMPLETE = "incomplete",
}

export interface StepConfig {
  id: string;
  name: string;
  slug: string;
  state: StepState;
}

export const defaultStepConfig: StepConfig[] = [
  {
    id: "1_createService",
    name: "Create a service",
    slug: "/create_services",
    state: StepState.INCOMPLETE,
  },
  {
    id: "2_saveConfig",
    name: "Save the config",
    slug: "/config",
    state: StepState.INCOMPLETE,
  },
  {
    id: "3_connectService",
    name: "Connect to service",
    slug: "/connect",
    state: StepState.INCOMPLETE,
  },
  {
    id: "4_importData",
    name: "Import your data",
    slug: "/import",
    state: StepState.INCOMPLETE,
  },
];

export const hypertableStepConfig: StepConfig[] = [
  {
    id: "1_createService",
    name: "Create a service",
    slug: "/create_services",
    state: StepState.INCOMPLETE,
  },
  {
    id: "2_saveConfig",
    name: "Save the config",
    slug: "/config",
    state: StepState.INCOMPLETE,
  },
  {
    id: "3_createHypertable",
    name: "Create a hypertable",
    slug: "/hypertable",
    state: StepState.INCOMPLETE,
  },
  {
    id: "4_importData",
    name: "Import your data",
    slug: "/import",
    state: StepState.INCOMPLETE,
  },
];

export const sampleDataStepConfig: StepConfig[] = [
  {
    id: "1_createService",
    name: "Create a service",
    slug: "/create_services",
    state: StepState.INCOMPLETE,
  },
  {
    id: "2_examineSampleData",
    name: "Examine sample data",
    slug: "/examineSampleData",
    state: StepState.INCOMPLETE,
  },
  {
    id: "3_testSampleData",
    name: "Test sample data",
    slug: "/testSampleData",
    state: StepState.INCOMPLETE,
  },
];

const mapStateToStyles: {
  [key in StepState]: {
    textColor: ThemeColors;
    iconColor: ThemeColors;
    bgColor: ThemeColors;
    icon: Icons | null;
  };
} = {
  [StepState.COMPLETE]: {
    textColor: "grayscale.700",
    iconColor: "grayscale.700",
    bgColor: "grayscale.200",
    icon: "status/Check/Check",
  },
  [StepState.CURRENT]: {
    textColor: "primary.900",
    iconColor: "primary.900",
    bgColor: "primary.300",
    icon: null,
  },
  [StepState.INCOMPLETE]: {
    textColor: "grayscale.600",
    iconColor: "warning.500",
    bgColor: "grayscale.200",
    icon: null,
  },
};

const ConnectionStep = (props: {
  name: string;
  slug: string;
  state: StepState;
  stepId: string;
  serviceId: string;
  pathname: string;
  index: number;
}) => {
  const { name, slug, state, serviceId, pathname, index } = props;
  const history = useHistory();
  const allowRoute =
    slug !== "/create_services" && pathname !== "/dashboard/create_services";

  return (
    <Flex
      fontSize="18px"
      fontWeight={700}
      gap="8px"
      color={mapStateToStyles[state].textColor}
      cursor={allowRoute ? "pointer" : ""}
      onClick={() => {
        if (allowRoute) {
          history.push(`/dashboard/services/${serviceId}${slug}`);
        }
      }}
    >
      <Grid
        bg={mapStateToStyles[state].bgColor}
        borderRadius="10rem"
        h="28px"
        w="28px"
        placeItems="center"
      >
        {state === StepState.COMPLETE ? (
          <Icon
            name={mapStateToStyles[state].icon!}
            fill={mapStateToStyles[state].iconColor}
          />
        ) : (
          String(index)
        )}
      </Grid>
      <Box>
        <Text lineHeight={1.5}>{name}</Text>
      </Box>
    </Flex>
  );
};

export const showConnectionProgressUrls = (serviceId: string) => [
  "/dashboard/create_services",
  `/dashboard/services/${serviceId}/connect`,
  `/dashboard/services/${serviceId}/import`,
  `/dashboard/services/${serviceId}/config`,
  `/dashboard/services/${serviceId}/hypertable`,
  `/dashboard/services/${serviceId}/examineSampleData`,
  `/dashboard/services/${serviceId}/testSampleData`,
];

export const ConnectionProgress = observer(
  ({ serviceId, pathname }: { serviceId: string; pathname: string }) => {
    const { serviceStore } = useStores();
    let { service } = serviceStore;
    let isCurrent = false;
    const { stepConfig, setStepConfig } = useContext(ConnectionProgressContext);
    const isSampleData = stepConfig.find(
      (o: StepConfig) => o.id === "2_examineSampleData",
    );

    for (const step of stepConfig) {
      if (isCurrent) {
        step.state = StepState.INCOMPLETE;
      } else {
        step.state = StepState.COMPLETE;
      }
      if (pathname.includes(step.slug)) {
        isCurrent = true;
        step.state = StepState.CURRENT;
      }
    }

    const isCreateHTExperiment = Statsig.getExperiment(
      EXP_CREATE_HT.ID,
    ).getValue(EXP_CREATE_HT.PARAMS.SHOW_FEATURE, false);

    useEffect(() => {
      const sampleDataType = sampleDataTypeFromServiceName(service?.name);
      if (sampleDataType) {
        setStepConfig(sampleDataStepConfig);
      } else if (isCreateHTExperiment) {
        setStepConfig(hypertableStepConfig);
      } else {
        setStepConfig(defaultStepConfig);
      }
    }, [service, isCreateHTExperiment, setStepConfig]);

    return (
      <Flex
        gap="12px"
        justifyContent="space-between"
        p="32px 32px 0 32px"
        maxW={isSampleData ? "900px" : "1400px"}
      >
        {stepConfig.map((step: any, i: any) => (
          <ConnectionStep
            name={step.name}
            state={step.state}
            slug={step.slug}
            index={i + 1}
            stepId={step.id}
            serviceId={serviceId}
            pathname={pathname}
            key={i}
          />
        ))}
      </Flex>
    );
  },
);
