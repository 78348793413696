// registration form localStorage
export const LOCAL_STORAGE_FORM = "ts:cloud/form";
export const REGISTRATION_FORM = "registration_form";

// user localStorage
export const LOCAL_STORAGE_USER = "ts:cloud/user";
export const USER_ID = "user_id";
export const USER = "user";
export const EMAIL = "email";
export const IDP = "idp";
export const COMPANY = "company";
export const PHONE = "phone";
export const CREATED = "created";
export const ORIGIN = "origin";

// user localStorage > local UI state
export const METRIC_SETTINGS = "last_metrics_settings";
export const MULTI_FACTOR_AUTH = "multi-factor-authentication";
export const QUERY_STATS_TABLE_COLUMNS = "query-stats-table-columns";
export const QUERY_STATS_TIME_INTERVAL = "query-stats-time-interval";
export const HIDE_ZOOM_HINT = "hide-zoom-hint";

// user localStorage > local UI state + server-side UI state
export const UI_STATE = "ui_state";
export const HIDE_UPGRADE_CARD = "closed-upgrade-card";
export const SERVICE_OVERVIEW_SETTINGS = "service-overview-settings";
export const FIRST_SERVICE = "first-service";
export const COMPRESSION_SUGGESTION = "hypertable_compression";
export const COMPRESSION_USED = "hypertable_compression_utilized";
export const GETTING_STARTED_PROGRESS_KEY = "getting-started-progress";
export const PGAI_SHOWCASE = "pgai_showcase";

// part of exp_product_tour set first service when service creation completes
export const SAMPLE_DATA_MODAL_CLOSED = "sample-data-modal-closed";

// data localStorage
export const LOCAL_STORAGE_USER_DATA = "ts:cloud/data";
export const INITIAL_PASSWORDS = "initial_passwords";
export const CURRENT_PROJECT = "current_project";
export const ALL_SERVICES = "all_services";
export const RESIZED_DATA = "resized_queue";
export const UPGRADE_STATUSES = "upgrade_statuses";

// data localStorage > pg parameters
export const LAST_FETCHED_DATABASE_PARAMETERS_BY_NODE =
  "current_database_parameters"; //service params from API
export const REQUESTED_DATABASE_PARAMETERS_BY_NODE =
  "requested_database_parameters"; //params from graphQL mutation request, waiting for actual change in the DB
export const PENDING_DATABASE_PARAMETERS_BY_NODE =
  "pending_database_parameters"; //params modified in the console but not yet submitted

// data localStorage > resources
export const LAST_FETCHED_RESOURCES_BY_NODE = "last_fetched_resources_by_node";
export const REQUESTED_RESOURCES_CHANGES_BY_NODE =
  "pending_updated_resources_by_node";
export const PENDING_RESOURCES_CHANGES_BY_NODE = "pending_resources_by_node";
export const PROJECT_CREATED = "project_created";

// feature flags
export const LOCAL_STORAGE_ACTIVE_FEATURES = "ts:flags";
export const USER_ID_UNKNOWN = "user_id_unknown";
export const FORCE_SHOW_FLAG_CHOOSER = "force_show_flag_chooser";
export const STATSIG_LOCAL_OVERRIDE_KEY =
  "STATSIG_LOCAL_STORAGE_INTERNAL_STORE_OVERRIDES_V3";

// third-party
export const NEW_USER_DATA = "new_user_data";
export const LATEST_CAMPAIGN = "latest_campaign";
export const URL_SEARCH_PARAMS = "search_params";
export const UTM_PARAMS = "utm_params";
export const IS_INVITED = "is_invited";
export const WANTS_SUBSCRIPTION = "wants_subscription";

export const STORAGE_TYPE = {
  SESSION: "session",
  LOCAL: "local",
};

/**** Deprecated in favor of generic storage functions below ****/
export const storeDirectlyIntoLocalStorage = (
  type: string,
  value: any,
  storageKey: string,
) => {
  const localStorageObject = JSON.parse(
    window.localStorage.getItem(storageKey) || "{}",
  );
  const newLocalStorageObject = { ...localStorageObject, [type]: value };
  window.localStorage.setItem(
    storageKey,
    JSON.stringify(newLocalStorageObject),
  );
};

export const replaceObjectInLocalStorage = (value: any, storageKey: string) => {
  window.localStorage.setItem(storageKey, JSON.stringify(value));
};

export const getFromLocalStorage = (
  type: string,
  storageKey: string,
  defaultValue = null,
) =>
  window.localStorage.getItem(storageKey)
    ? JSON.parse(window.localStorage.getItem(storageKey)!)[type]
    : defaultValue;

export const removeDirectlyFromLocalStorage = (
  type: string,
  storageKey: string,
) => {
  const localStorageObject = getFromLocalStorage(type, storageKey);
  delete localStorageObject[type];
  window.localStorage.setItem(storageKey, JSON.stringify(localStorageObject));
};

export const clearLocalStorageExceptFeatureFlags = () => {
  const featureFlags = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_ACTIVE_FEATURES) || "{}",
  );

  localStorage.clear();
  localStorage.setItem(
    LOCAL_STORAGE_ACTIVE_FEATURES,
    JSON.stringify(featureFlags),
  );
};

/******* New generic storage functions ********/
const localOrSessionStorage = (storageType = STORAGE_TYPE.SESSION) =>
  storageType === STORAGE_TYPE.SESSION ? sessionStorage : localStorage;

export const storeDirectlyIntoStorage = (
  type: string,
  value: any,
  storageKey: string,
  storageType = STORAGE_TYPE.SESSION,
) => {
  let storage = localOrSessionStorage(storageType);
  const storageObject = JSON.parse(storage.getItem(storageKey)!);
  storage.setItem(
    storageKey,
    JSON.stringify({ ...storageObject, [type]: value }),
  );
};

export const replaceObjectInStorage = (
  value: any,
  storageKey: string,
  storageType = STORAGE_TYPE.SESSION,
) => {
  localOrSessionStorage(storageType).setItem(storageKey, JSON.stringify(value));
};

export const getFromStorage = (
  type: string,
  storageKey?: string,
  defaultValue = null,
  storageType = STORAGE_TYPE.SESSION,
) => {
  const storage = localOrSessionStorage(storageType);
  return storage.getItem(storageKey as string) === "undefined"
    ? defaultValue
    : JSON.parse(storage.getItem(storageKey as string)!)?.[type];
};

export const removeFromStorage = (
  type: string,
  storageKey: string,
  storageType = STORAGE_TYPE.SESSION,
) => {
  const storage = localOrSessionStorage(storageType);
  const storageObject = JSON.parse(storage.getItem(storageKey)!);
  delete storageObject?.[type];
  storage.setItem(storageKey, JSON.stringify(storageObject));
};
