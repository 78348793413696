import { QueryWidget } from "@timescale/popsql-query-widget";
import type {
  OnQueryCompleteArgs,
  QueryWidgetProps,
} from "@timescale/popsql-query-widget/ts-dist/src/QueryWidget";
import { FC, useCallback, useEffect, useState } from "react";
import { useCurrentServiceQueryEngine } from "contexts/queryEngine";
import { Code, type CodeProps } from "./Code";
import { Box } from "@chakra-ui/react";

interface RunnableCodeProps
  extends CodeProps,
    Pick<
      QueryWidgetProps,
      | "emptyResultsBehavior"
      | "id"
      | "onQueryComplete"
      | "readonlyEditor"
      | "resizeHandles"
      | "resultsHeight"
      | "squareTop"
    > {
  forceFallback?: boolean;
  onQuerySuccess?: (args: OnQueryCompleteArgs) => void;
}

/**
 * This component uses the `QueryWidget` to make the passed code editable and
 * runnable if the integration is enabled. Falls back to the `Code` component,
 * which simply displays the code.
 */
export const RunnableCode: FC<RunnableCodeProps> = ({
  // shared props
  code,
  sx,
  // QueryWidget props
  id,
  emptyResultsBehavior,
  onQueryComplete,
  onQuerySuccess,
  resultsHeight,
  resizeHandles,
  readonlyEditor,
  squareTop,
  forceFallback,
  // Code props
  language,
  withLines,
  prompt,
  copyFn,
  copyOnClick,
  configOnClick,
  deleteOnClick,
  // StyleProps
  ...rest
}) => {
  const [query, setQuery] = useState(code);
  const { getExecuteQueryData, ready, integrationEnabled, trackQueryComplete } =
    useCurrentServiceQueryEngine();

  const handleQueryComplete = useCallback(
    (args: OnQueryCompleteArgs) => {
      trackQueryComplete(args);
      onQueryComplete?.(args);
      if (args.status === "success") {
        onQuerySuccess?.(args);
      }
    },
    [trackQueryComplete, onQueryComplete, onQuerySuccess],
  );

  useEffect(() => {
    setQuery(code);
  }, [code]);

  return integrationEnabled && !forceFallback ? (
    <Box
      textAlign="left"
      sx={{
        ".border-surface-border": {
          borderColor: "primary.600",
        },
        ...(readonlyEditor
          ? {
              // prevent "cannot edit" tooltip from being clipped
              ".border-surface-border > .overflow-hidden:first-of-type": {
                overflow: "visible",
              },
            }
          : {}),
        ...sx,
      }}
      {...rest}
    >
      <QueryWidget
        id={id}
        query={query}
        onQueryChange={setQuery}
        getExecuteQueryData={getExecuteQueryData}
        resizeHandles={resizeHandles}
        resultsHeight={resultsHeight}
        toolbarItemAlignment="start"
        onQueryComplete={handleQueryComplete}
        squareTop={squareTop}
        disableRun={!ready}
        emptyResultsBehavior={emptyResultsBehavior}
        readonlyEditor={readonlyEditor}
        runButtonTooltip={ready ? undefined : "Your service is initializing..."}
      />
    </Box>
  ) : (
    <Code
      code={code}
      sx={sx}
      language={language}
      withLines={withLines}
      prompt={prompt}
      copyFn={copyFn}
      copyOnClick={copyOnClick}
      configOnClick={configOnClick}
      deleteOnClick={deleteOnClick}
      {...rest}
    />
  );
};
