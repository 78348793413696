import { colors, ThemeColors } from "../theme/foundations/colors";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Cookies from "js-cookie";
import relativeTime from "dayjs/plugin/relativeTime";
import { ServiceStatus, ServiceType, ServiceTypeProductId } from "types";
import { mapServiceStatus } from "./mapStatus";
import { COLOR_CONFIG } from "../components/tags/tagStyles";
import {
  Maybe,
  ResourceMetrics,
  Service,
  ServiceConfigHistory,
  Type,
} from "graphql/generated";
import { ValueOf } from "types/extensions";
import { Icons } from "components/icon/iconTypes";
import _, { sortBy, uniqBy } from "lodash";
import { convert, Time, Data } from "convert";
// @ts-ignore
import sqlFormatter from "sql-format";
import React from "react";
import projectsStore from "stores/projectsStore";
import { OptionType } from "components/dropdown/dropdownTypes";

dayjs.extend(utc);

/* ReactLazyPreload */
export interface LazyPreload<Props>
  extends React.LazyExoticComponent<React.ComponentType<Props>> {
  preload: () => {};
}

export function ReactLazyPreload<Props>(
  importStatement: () => Promise<{ default: React.ComponentType<Props> }>,
) {
  // use Object.assign to set preload
  // otherwise it will complain that Component doesn't have preload
  const Component: LazyPreload<Props> = Object.assign(
    React.lazy(importStatement),
    {
      preload: importStatement,
    },
  );

  return Component;
}

export const findById = ({
  items = [],
  id = "",
}: {
  items: any[];
  id: string;
}) => [...items].find(({ id: itemId }) => id === itemId);

export const excludeById = ({ items, id }: { items: any[]; id: string }) =>
  sortBy(
    [...items].filter(({ id: itemId }) => id !== itemId),
    ["id"],
  );

export const diskPercentageNumber = (usedDisk: number, disk: number): number =>
  usedDisk && disk ? +((usedDisk / disk) * 100).toFixed(0) : 0;

export const getProgressColor = (
  value: number,
): { foreground: ThemeColors; background: ThemeColors } => {
  return value >= 99
    ? { foreground: "error.900", background: "error.100" }
    : value >= 75
      ? { foreground: "warning.900", background: "warning.100" }
      : { foreground: "success.900", background: "success.100" };
};

export const getResources = ({
  resources,
}: any): Record<"cpu" | "ram" | "disk", number> => {
  const { cpu, ram, disk } = (() => {
    if (resources?.length > 0) {
      return {
        cpu: (resources?.[0]?.spec.milliCPU || 0) / 1000,
        ram: resources?.[0]?.spec.memoryGB,
        disk: resources?.[0]?.spec.storageGB || 0,
      };
    } else {
      return { cpu: 0, ram: 0, disk: 0 };
    }
  })();

  return { cpu, ram, disk };
};

export const getResourceUsage = ({
  metrics,
}: {
  metrics?: Maybe<ResourceMetrics>;
}) => {
  const usedCPU = metrics ? (metrics.milliCPU ? metrics.milliCPU : 0) : 0;
  const usedRAM = metrics ? Number((metrics.memoryMB! / 1024).toFixed(2)) : 0;
  const usedDisk = metrics ? Number((metrics.storageMB! / 1024).toFixed(2)) : 0;
  return { usedCPU, usedRAM, usedDisk };
};

type Unit = Data | Time;

interface ConvertUnit {
  value: number;
  from: Unit;
  to: Unit;
  precision?: number;
}

interface ConvertUnitToBest {
  value: number;
  from: Unit;
  precision?: number;
  kind?: "metric" | "imperial";
}

export interface ConvertUnitReturn {
  unit: string;
  value: number;
  valueWithUnit: string;
}

/**
 A wrapper around the `convert` library to make
 our unit conversions smoother and more consistent.

 The function returns an object that contains either the
 value as a number, or a string containing both the value and
 the unit (e.g. `4.32 GB`)

 Notes:
 - The `precision` arg handles the input for the `.toFixed()`
 method
 - Using `parseFloat()` removes trailing zeroes from a number

 */

export function convertUnit(param: ConvertUnitToBest): ConvertUnitReturn;
export function convertUnit(param: ConvertUnit): ConvertUnitReturn;
export function convertUnit(param: any): ConvertUnitReturn {
  const { value, from, to, precision = 2, kind = "imperial" } = param;
  if (to) {
    const conversion = convert(value, from).to(to);
    // @ts-ignore
    const formattedVal = parseFloat(conversion.toFixed(precision));
    const convertedValueWithUnit = `${formattedVal} ${to}`;

    return {
      unit: to,
      value: formattedVal,
      valueWithUnit: convertedValueWithUnit,
    };
  } else {
    const conversion = convert(value, from).to("best", kind);
    const formattedVal =
      typeof conversion.quantity === "bigint"
        ? NaN
        : parseFloat(conversion.quantity.toFixed(precision));
    const convertedValueWithUnit = `${formattedVal} ${conversion.unit}`;

    return {
      unit: conversion.unit,
      value: formattedVal,
      valueWithUnit: convertedValueWithUnit,
    };
  }
}

type MetricApprox = {
  num: number;
  base?: number;
  units?: string[] | string;
  decimalPlaces?: number;
};

//gives value in metric prefixes: kilo, mega, giga, tera, peta
const metricApproxBase = ({
  num,
  base = 1000,
  units = " KMGTP",
  decimalPlaces = 1,
}: MetricApprox) => {
  if (num <= 0) {
    return { number: 0, units: "" };
  }
  if (num < 1) {
    return { number: num, units: units[0] };
  }
  const power = Math.min(
    units.length - 1,
    Math.floor(Math.log(num) / Math.log(base)),
  );
  const numero = Number((num / base ** power).toFixed(decimalPlaces));
  return {
    number: numero,
    units: units[power],
  };
};

export const metricApprox = ({ num, ...props }: MetricApprox) => {
  if (num <= 0) {
    return "0";
  }
  const { number: numero, units } = metricApproxBase({ num, ...props });
  return `${numero} ${units}`;
};

/**
 * Controls whether the "need more compute" recommendation
 * should be shown. You can either pass in a service object,
 * or an object with the milliCPU and storageGB properties you
 * want to compare.
 *
 * @param service - a Service object
 * @param config - a config object
 * @property config.milliCPU - the milliCPU value to compare
 * @property config.storageGB - the storageGB value to compare
 */
export const moreComputeSuggested = (
  service: Service | {} = {},
  { milliCPU, storageGB }: { milliCPU?: number; storageGB?: number } = {},
) => {
  /**
   * If neither milliCPU nor storageGB is provided,
   * and the serviceInfo object has something in it, then we
   * use the actual resource usage from that service to trigger
   * the recommendation. (e.g. in the OverviewPage, and
   * serviceCardComponents).
   *
   * But if milliCPU and storageGB is provided, then we use
   * those values to trigger the recommendation.
   */

  let cpu = milliCPU;
  let storage = storageGB;

  if (
    (_.isNil(milliCPU) || _.isNil(storageGB)) &&
    service &&
    Object.keys(service).length > 0
  ) {
    const { metrics } = service as Service;
    let { usedDisk } = getResourceUsage({ metrics });
    storage = usedDisk;
  }

  if (cpu && storage) {
    if (cpu <= 500 && storage >= 50) {
      return true;
    }
    if (cpu <= 1000 && storage >= 600) {
      return true;
    }
  }
  return false;
};

export const getCreated = (created?: string) => {
  if (!created) {
    return null;
  }
  dayjs.extend(relativeTime);
  const day = dayjs(created).fromNow();
  return `${day.replace(/\\D/g, "")}`;
};

export const getStatusColorConfig = (
  status: ServiceStatus,
  isReadOnly: boolean,
) => {
  const statusConfig = mapServiceStatus(isReadOnly ? "READ_ONLY" : status);
  return COLOR_CONFIG(false)[statusConfig.color];
};

export const getLastServiceUpdateEvent = (
  serviceConfigHistory: ServiceConfigHistory,
) => {
  const { compute = [], storage = [] } = serviceConfigHistory;

  const updateEvents = [...compute.slice(0, -1), ...storage.slice(0, -1)];
  const lastUpdateEvent = updateEvents.sort(
    (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime(),
  )[0];

  return {
    text:
      lastUpdateEvent && "storageGB" in lastUpdateEvent
        ? "Storage configuration resize."
        : "Compute configuration resize.",
    created: lastUpdateEvent?.created,
  };
};

// Accepts a service and returns a standized format for replica data.
export const replicaInfo = (service: Service) => {
  return {
    [Type.Timescaledb]: {
      replicaOrdinals: service?.replicaOrdinals,
      replicaStatus: service?.replicaStatus,
    },
    [Type.Postgres]: {
      replicaOrdinals: service?.replicaOrdinals,
      replicaStatus: service?.replicaStatus,
    },
    [Type.Grafana]: {
      replicaOrdinals: undefined,
      replicaStatus: undefined,
    },
    [Type.Vector]: {
      replicaOrdinals: service?.replicaOrdinals,
      replicaStatus: service?.replicaStatus,
    },
  }[service.type];
};

export type Configuration = {
  cpu: number;
  value: string;
  memory: number;
  label: string;
  label2: string;
  regionCode: string;
  productId: string;
  trialAccess: boolean;
};

export type ComputeOption = {
  value: { milliCPU: number; memoryGB: number };
  id: string;
  regionCode: string;
  label: string;
  iconRight?: Icons;
};

/* 
  For dynamic compute, users are now presented the option of 
  "CPU bands" that consist of a min CPU and a max CPU
  (E.g. 1-2 CPU, 2-4 CPU, 4-8 CPU, 8-16, 16-32) rather than 
  CPU / memory "plans" like before.
  

  The catch: dynamic compute is currently in beta. So 
  under the hood, the "dynamic" part is being faked by continuing 
  to use compute plans as usual and just giving services the 
  plan corresponding to the max CPU of their selected band.
  (E.g. a 2-4 CPU band is really just a 4 CPU / 16 GiB memory
  plan).

  The following object maps each possible max CPU to their
  CPU band.
*/

/**
 * @property min: hourly price taken from the compute plan corresponing to selected band's min CPU
 * @property max: hourly price taken from compute plan corresponding to selected band's max CPU
 */
export type CPURange = { min: number; max: number };

export const allCpuBands: Record<number, string> = {
  0.5: "0.5-1",
  2: "1-2",
  4: "2-4",
  8: "4-8",
  16: "8-16",
  32: "16-32",
};

export const cpuBands = _.omit(allCpuBands, 0.5);

export const getCPUBandFromMaxMilliCPU = (milliCPU = 2000) => {
  return cpuBands[milliCPU / 1000];
};

export const buildComputeDropdownOptions = (
  serviceType: string,
  configuration: Configuration[], //from product store
  regionCode: string,
  isDynamicCompute: boolean = false,
) => {
  let computeDropdownOptions = configuration.reduce(
    (
      filteredByServiceRegion: ComputeOption[],
      {
        cpu,
        value,
        memory,
        label,
        label2,
        regionCode: configRegionCode,
        productId: configuredProductId,
        trialAccess,
      },
    ) => {
      if (
        configRegionCode === regionCode &&
        configuredProductId ===
          ServiceTypeProductId[serviceType as ValueOf<typeof ServiceType>]
            .Config
      ) {
        return [
          ...filteredByServiceRegion,
          {
            value: { milliCPU: cpu, memoryGB: memory },
            id: value,
            regionCode,
            label: `${label} / ${label2}`,
            productId: configuredProductId,
            ...(projectsStore.isLimitedSpendingActive &&
              !trialAccess && { iconRight: "elements/Safety/Lock" as Icons }),
          },
        ];
      }
      return filteredByServiceRegion;
    },
    [],
  );

  const buildDynamicComputeDropdownOptions = (
    computeOptions: ComputeOption[],
  ): ComputeOption[] => {
    const updatedComputeOptions: Array<ComputeOption | {}> = Object.values(
      cpuBands,
    ).map((_band) => {
      let band = _band.split("-");
      const max = band[band.length - 1];
      const computeOptionIndex = computeOptions.findIndex(
        (option) => option.value.milliCPU / 1000 === Number(max),
      );
      const computeOption = computeOptions[computeOptionIndex];
      const computeOptionWithUpdatedLabel = computeOption?.value
        ? {
            ...computeOption,
            label: `${_band} CPU`,
          }
        : {};

      return computeOptionWithUpdatedLabel;
    });

    return updatedComputeOptions.filter((option): option is ComputeOption => {
      return (option as ComputeOption)?.value?.milliCPU !== undefined;
    });
  };
  computeDropdownOptions = uniqBy(computeDropdownOptions, "id");
  return isDynamicCompute
    ? buildDynamicComputeDropdownOptions(computeDropdownOptions)
    : computeDropdownOptions;
};

export const getLatestVersion = (versions: string[] = []) =>
  versions
    .slice()
    .sort((a: string, b: string) =>
      b.localeCompare(a, undefined, { numeric: true, sensitivity: "base" }),
    )?.[0];

export const getGaClientId = () => {
  const gaCookie = Cookies.get("_ga");

  if (gaCookie) {
    return gaCookie.split(".").slice(2).join(".");
  }

  return null;
};

export const getHubSpotUtk = () => {
  let hubspotutk = Cookies.get("hubspotutk");

  if (hubspotutk) {
    return hubspotutk;
  }

  return null;
};

export const getPathnameSegments = (
  pathname: string,
  { filterOut = [] }: { filterOut: unknown[] },
) => {
  return pathname
    .split("/")
    .filter((segment) => !!segment && !filterOut.includes(segment));
};

export const matchesRegexps: (value: string, regexps: RegExp[]) => boolean = (
  string,
  regexps,
) => {
  return regexps.some((reg) => reg.test(string));
};

const genericDay = dayjs();

export interface WindowOption {
  value: number;
  label: string;
}

export const utcOffsets: WindowOption[] = Array.from(
  Array(15 - -12).keys(),
).map((i) => ({
  value: i - 12,
  label: genericDay.utcOffset(i - 12)?.format("([GMT] Z)"),
}));

export const formatSql = (queryText: string) =>
  sqlFormatter.format(queryText, { language: "pl/sql" });

export const generateRandomName = (prefix = "") =>
  `${prefix}-${Math.floor(10000 + Math.random() * 90000)}`;

export const makeRandomDBName = (prefix = "db") => {
  return generateRandomName(prefix);
};

export const printSha = () => {
  if (process.env.NODE_ENV === "production") {
    console.info(
      `%cTimescale Console%c  %c#${(process.env.REACT_APP_SHA || "").substring(
        0,
        7,
      )}`,
      `background: ${colors.grayscale[900]}; border-radius:0.5em; padding:0.2em 0.5em; color:${colors.yellow[900]};`,
      "",
      `background: ${colors.success[900]}; border-radius:0.5em; padding: 0.2em 0.5em 0.1em 0.5em; color: ${colors.grayscale[900]}; font-weight: 700;`,
    );
  }
};

export const buildIdFromLabel = (label: string): string =>
  label.replace(/ /g, "_").toLowerCase();

export const highAvailabilityOptions: OptionType[] = [
  {
    label: "Non-production (No replica)",
    description: "No replica. Best for dev environments.",
    value: 0,
  },
  {
    label: "High (most common)",
    description:
      "A single replica in a separate AZ that will take over operations if the primary fails.",
    value: 1,
  },
  {
    label: "Highest availability",
    description:
      "Two readable replicas in a separate AZ that take over operations if the primary fails.",
    value: 2,
  },
];
