import { useCallback, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "stores";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Flex, useBreakpointValue, Image, Box, Stack } from "@chakra-ui/react";
import Divider from "components/divider/Divider";
import IconButton from "components/button/IconButton";
import { ProjectMenu } from "./ProjectMenu";
import { GettingStartedButton } from "pages/getStarted/GettingStartedButton";
import { GETTING_STARTED_PROGRESS_KEY } from "utils/localStorage";
import { VerticalNavMenuItem } from "components/navigation/internal/VerticalNavMenuItem";
import { VerticalNavItemProps } from "components/navigation/internal/navTypes";
import { UserMenu } from "./UserMenu";
import { PopSQLSwitch } from "./PopSQLSwitch";
import { HelpCenter } from "./helpCenter/HelpCenter";
import { NotificationCenter } from "./notificationCenter/NotificationCenter";
import { UpgradeBillingPlanButton } from "./UpgradeBillingPlanButton";
// assets
import Logo from "assets/logos/timescale-logo.svg";
import LogoMini from "assets/logos/timescale-logo-mini.svg";
// gate
import { Statsig } from "statsig-react";
import { EXP_TIERING } from "utils/featuresGates";
import { Icons } from "components/icon/iconTypes";
import { LeftNavContext } from "./NavigationProvider";

const tabsConfig: VerticalNavItemProps[] = [
  {
    id: "newService",
    label: "New service",
    icon: "actions/Add/Add",
    to: "/dashboard/create_services",
  },
  {
    id: "services",
    label: "Services",
    icon: "actions/Build",
    to: "/dashboard/services",
  },
  {
    id: "vpc",
    label: "VPC",
    icon: "elements/Cloud/Up",
    to: "/dashboard/vpc",
  },
  {
    id: "integrations",
    label: "Integrations",
    icon: "actions/Link/Link",
    to: "/dashboard/integrations",
  },
  {
    id: "members",
    label: "Invite users",
    icon: "elements/UserAdd",
    to: "/dashboard/members",
  },
];

const playTab = {
  id: "play",
  label: "Play [LOCAL ONLY]",
  icon: "status/Loader" as Icons,
  to: "/dashboard/play",
};

export const LeftNav = observer(() => {
  const { projectsStore, productStore, userStore, featuresStore } = useStores();
  const { pathname } = useLocation();
  const { isLeftNavMinified, setLeftNavMinified, setIsMenuOpen } =
    useContext(LeftNavContext);
  const { currentProject } = projectsStore;
  const { pendingInvitations } = userStore;

  const isPlanFeatureAvailable = Statsig.checkGate(EXP_TIERING.GATE);

  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const isMediumScreen = useBreakpointValue({
    base: false,
    md: true,
    lg: false,
  });

  const history = useHistory();

  useEffect(() => {
    setLeftNavMinified(!!isMediumScreen);
  }, [isMediumScreen, setLeftNavMinified]);

  useEffect(() => {
    if (!productStore.storagePlans || !productStore.configPlans) {
      productStore.getProducts();
    }
  }, [productStore]);

  const showGetStartedProgress =
    !userStore.uiState?.[GETTING_STARTED_PROGRESS_KEY]?.completedTimestamp;

  const showUpgradeBillingPlan =
    isPlanFeatureAvailable &&
    currentProject.entitlementSpec?.plan_type === "PERFORMANCE";

  const isActive = useCallback(
    (item: VerticalNavItemProps) =>
      pathname.includes(item.to as string) ||
      (item.matchingRoutes?.length &&
        item.matchingRoutes.find((route) => pathname.includes(route))),
    [pathname],
  );

  return (
    <Flex>
      <Box w={isSmallScreen ? "0" : "3px"} bg="timescaleGradient.0" />
      <Flex
        direction="column"
        alignItems="flex-start"
        data-cy="LeftNav"
        borderRight="1px solid"
        borderColor="grayscale.200"
        width={isSmallScreen ? "100%" : isLeftNavMinified ? "56px" : "186px"}
        transition="width 200ms"
        overflowY="auto"
        overflowX="hidden"
        position={isSmallScreen ? "relative" : "static"}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          p={`24px 16px 24px 16px`}
          direction="row"
          width="100%"
        >
          <Link to="/dashboard/services">
            <Image src={isLeftNavMinified ? LogoMini : Logo} h="30px" />
          </Link>
          {isSmallScreen && (
            <IconButton
              label="Close"
              iconName={"actions/Close/Large"}
              size="md"
              variant="ghostIcon"
              color="grayscale.800"
              onClick={() => setIsMenuOpen(false)}
            />
          )}
        </Flex>
        <Flex flexDirection="column" gap="8px" px="8px" width="100%" pb="8px">
          <ProjectMenu />

          <PopSQLSwitch />

          <Stack direction="column" data-cy="internal-navigation" gap="8px">
            {tabsConfig
              .filter(
                (item) =>
                  !item?.feature || featuresStore.isActiveFeature(item.feature),
              )
              .map((item: VerticalNavItemProps, i) => {
                const isActiveItem = isActive(item);
                return (
                  <VerticalNavMenuItem
                    key={i}
                    item={item}
                    isActive={!!isActiveItem}
                  />
                );
              })}
          </Stack>
        </Flex>

        {(showUpgradeBillingPlan || showGetStartedProgress) && (
          <>
            <Divider
              color="grayscale.200"
              width="100%"
              mb={!showUpgradeBillingPlan ? "8px" : "0"}
            />

            <Flex direction="column" gap="8px" p="8px" width="100%">
              {showUpgradeBillingPlan && (
                <UpgradeBillingPlanButton
                  onClick={() => {
                    history.push("/dashboard/billing/plans");
                    setIsMenuOpen(false);
                  }}
                  isLeftNavMinified={isLeftNavMinified}
                />
              )}
              {showGetStartedProgress && (
                <GettingStartedButton
                  onClick={() => {
                    history.push("/dashboard/getting_started");
                    setIsMenuOpen(false);
                  }}
                  isLeftNavMinified={isLeftNavMinified}
                />
              )}
            </Flex>
          </>
        )}

        <Flex
          direction="column"
          width="100%"
          gap="8px"
          p="8px"
          marginTop={{ base: "inherit", md: "auto" }}
        >
          {process.env.NODE_ENV === "development" && (
            <VerticalNavMenuItem
              item={playTab}
              isActive={pathname.includes(playTab.to)}
            />
          )}
          <NotificationCenter notifications={[...pendingInvitations]} />
          <HelpCenter />
          <UserMenu />
        </Flex>
        {!isSmallScreen && (
          <>
            <Divider color="grayscale.200" width="100%" />
            <VerticalNavMenuItem
              item={{
                id: "toggleMinNav",
                label: "",
                icon: isLeftNavMinified
                  ? "actions/Arrow/Double/Right"
                  : "actions/Arrow/Double/Left",
                onSelect: () => setLeftNavMinified(!isLeftNavMinified),
                to: null,
              }}
              m="8px"
            />
          </>
        )}
      </Flex>
    </Flex>
  );
});
