import { Box, Collapse, Flex, Link } from "@chakra-ui/react";
import CloseButton from "components/button/CloseButton";
import { observer } from "mobx-react";
import { Statsig } from "statsig-react";
import { useStores } from "stores";
import { colors } from "theme/foundations/colors";
import { FEAT_PGAI_BANNER } from "utils/featuresGates";
import { PGAI_SHOWCASE } from "utils/localStorage";

export const PGAIBanner = observer(() => {
  const { userStore } = useStores();
  const { uiState } = userStore;

  // TODO: statsig gate
  const showAIBanner =
    (uiState?.[PGAI_SHOWCASE]?.showBanner ?? true) &&
    Statsig.checkGate(FEAT_PGAI_BANNER.GATE);

  const handleClose = () => {
    userStore.mergeUiState({
      [PGAI_SHOWCASE]: {
        showBanner: false,
      },
    });
  };

  return (
    <Collapse in={showAIBanner}>
      <Flex
        bg={`linear-gradient(89.99deg, ${colors.grayscale[900]} 1.68%, ${colors.primary[900]} 91.66%)`}
        height="60px"
        alignItems="center"
        padding="0 16px"
        color="grayscale.0"
        textShadow={`0 1px colors.grayscale[900]`}
      >
        <Box flex="1">
          pgai and pgvectorscale: new open-source Postgres extensions for AI
          that make pgvector faster than Pinecone.{" "}
          <Link
            href="https://www.timescale.com/blog/making-postgresql-a-better-ai-database/"
            textDecoration="underline"
            color="grayscale.0"
            data-cy="pgai-banner-link"
            isExternal
          >
            Read more
          </Link>
        </Box>
        <CloseButton
          size="Large"
          fill="grayscale.0"
          ml="32px"
          onClick={handleClose}
        />
      </Flex>
    </Collapse>
  );
});
