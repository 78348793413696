import React, { useEffect, useState } from "react";
import { Switch, Box } from "@chakra-ui/react";
import { SelectorProps } from "./selectorsTypes";

export const Toggle = ({
  id,
  isDisabled,
  children,
  isChecked,
  onChange,
  ...customStyles
}: SelectorProps) => {
  const [checked, setChecked] = useState(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const handleCheckboxChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(evt);
    setChecked(evt.target.checked);
  };

  const now = new Date().getTime();
  const elementId = id || now.toString();

  return (
    <Switch
      id={elementId}
      isDisabled={isDisabled}
      isChecked={checked}
      onChange={handleCheckboxChange}
      size="sm"
      {...customStyles}
    >
      <Box
        color={isDisabled ? "grayscale.500" : ""}
        display="inline-flex"
        alignItems="center"
      >
        {children}
      </Box>
    </Switch>
  );
};
