import { HStack, Input, InputProps } from "@chakra-ui/react";
import { useState } from "react";
import IconButton from "components/button/IconButton";

export interface PasswordInputProps extends InputProps {
  autocomplete: "off" | "on" | "current-password" | "new-password";
  shouldStartVisible?: boolean;
}

export const PasswordInput = ({
  autocomplete,
  shouldStartVisible = false,
  isDisabled,
  ...rest
}: PasswordInputProps) => {
  const [isShowing, setIsShowing] = useState<boolean>(shouldStartVisible);
  const handleToggleVisibility = () => {
    if (!isDisabled) {
      setIsShowing((showing) => !showing);
    }
  };

  return (
    <HStack spacing="8px">
      <Input
        type={isShowing ? "text" : "password"}
        autoComplete={autocomplete}
        isDisabled={isDisabled}
        {...rest}
      />
      <IconButton
        variant="passwordVisiblityIcon"
        size="md"
        label="visibility"
        onClick={handleToggleVisibility}
        iconName={isShowing ? "actions/Unvisibility" : "actions/Visibility"}
        borderRadius="8px"
        data-cy="password-reveal"
      />
    </HStack>
  );
};

export default PasswordInput;
