import Login from "pages/register/Login";
import Register from "pages/register/Register";
import { lazy } from "react";

const ResetPassword = lazy(() => import("pages/register/ResetPassword"));

const RegisterComplete = lazy(() => import("pages/register/RegisterComplete"));

const PaymentEmailVerification = lazy(
  () => import("pages/billing/billingEmails/BillingEmailVerification"),
);

const AccountSettings = lazy(() => import("pages/account/AccountSettings"));

const ServicesPage = lazy(() => import("pages/project/service/ServicePage"));

const ProjectPage = lazy(() => import("pages/project/ProjectPage"));

const GetStartedPage = lazy(() => import("pages/getStarted/GetStartedPage"));

const ExitSurvey = lazy(() => import("pages/project/ExitSurvey"));

const ProjectSettings = lazy(
  () => import("pages/project/settings/ProjectSettings"),
);

const ServiceCreate = lazy(
  () => import("pages/project/service/create/CreateServicePage"),
);

const ServiceCreateComplete = lazy(
  () =>
    import(
      "pages/project/service/create/CreateServiceComplete/CreateServiceComplete"
    ),
);

const SupportPage = lazy(() => import("pages/support/SupportPage"));

const BillingPage = lazy(() => import("pages/billing/BillingPage"));

const MembersPage = lazy(() => import("pages/members/MembersPage"));

const ErrorPage = lazy(() => import("pages/ErrorPage"));

const VpcPage = lazy(() => import("pages/vpc/VpcPage"));

const VpcCreatePage = lazy(() => import("pages/vpc/VpcCreatePage"));

const Play = lazy(() => import("pages/Play"));

const ConfirmEmail = lazy(() => import("pages/register/ConfirmEmail"));

const IntegrationsPage = lazy(
  () => import("pages/integrations/IntegrationsPage"),
);

const ConnectServicePage = lazy(
  () =>
    import(
      "pages/project/service/envFlow/ConnectServicePage/ConnectServicePage"
    ),
);

const SaveConfigPage = lazy(
  () => import("pages/project/service/envFlow/SaveConfigPage"),
);

const CreateHypertableDataPage = lazy(
  () => import("pages/project/service/envFlow/CreateHypertablePage"),
);

const ImportDataPage = lazy(
  () => import("pages/project/service/envFlow/ImportDataPage/ImportDataPage"),
);

const DataImportSuccessPage = lazy(
  () => import("pages/project/service/envFlow/DataImportSuccessPage"),
);

const ExamineSampleDataPage = lazy(
  () =>
    import(
      "pages/project/service/envFlow/SampleDataPages/ExamineSampleDataPage"
    ),
);

const TestSampleDataPage = lazy(
  () =>
    import("pages/project/service/envFlow/SampleDataPages/TestSampleDataPage"),
);

const IDPLanding = lazy(() => import("pages/register/IDPLanding"));

const IDPError = lazy(() => import("pages/register/IDPError"));

export const redirectRoutes = [
  {
    from: "/register",
    to: "/signup",
  },
  {
    from: "/signin",
    to: "/login",
  },
  {
    from: "/",
    to: "/login",
  },
  {
    from: "/support",
    to: "/dashboard/support",
  },
];

export const publicRoutes = [
  {
    path: "/",
    exact: true,
    component: Login,
  },
  {
    path: "/login",
    exact: true,
    component: Login,
  },
  {
    path: "/signup",
    exact: true,
    component: Register,
  },
  {
    path: "/signup/success",
    exact: true,
    component: ConfirmEmail,
  },
];

export const publicRoutesSecondary = [
  {
    path: "/resetPassword",
    component: ResetPassword,
  },
  {
    path: "/verify",
    component: RegisterComplete,
  },
  {
    path: "/verifyBilling",
    component: PaymentEmailVerification,
  },
  {
    path: "/idpError",
    component: IDPError,
  },
];

export const noLayoutRoutes = [
  {
    path: "/idp",
    component: IDPLanding,
  },
];

export const privateRoutes = [
  {
    path: "/dashboard/account",
    exact: false,
    component: AccountSettings,
  },
  {
    path: `/dashboard/services/:serviceId/connect`,
    exact: true,
    component: ConnectServicePage,
  },
  {
    path: `/dashboard/services/:serviceId/config`,
    exact: true,
    component: SaveConfigPage,
  },
  {
    path: `/dashboard/services/:serviceId/import`,
    exact: true,
    component: ImportDataPage,
  },
  {
    path: `/dashboard/services/:serviceId/importSuccess`,
    exact: true,
    component: DataImportSuccessPage,
  },
  {
    path: `/dashboard/services/:serviceId/examineSampleData`,
    exact: true,
    component: ExamineSampleDataPage,
  },
  {
    path: `/dashboard/services/:serviceId/hypertable`,
    exact: true,
    component: CreateHypertableDataPage,
  },
  {
    path: `/dashboard/services/:serviceId/testSampleData`,
    exact: true,
    component: TestSampleDataPage,
  },
  {
    path: "/dashboard/services/:serviceId",
    component: ServicesPage,
  },
  {
    path: "/joinProject",
    exact: false,
    component: ProjectPage,
  },
  {
    path: "/dashboard/no_services",
    exact: true,
    component: ExitSurvey,
  },
  {
    path: "/dashboard/services",
    exact: true,
    component: ProjectPage,
  },
  {
    path: "/dashboard/create_services/complete",
    exact: true,
    component: ServiceCreateComplete,
  },
  {
    path: "/dashboard/create_services/:cloneType?/:serviceId?",
    component: ServiceCreate,
  },
  {
    path: "/dashboard/getting_started",
    component: GetStartedPage,
  },
  {
    path: "/dashboard/vpc",
    exact: true,
    component: VpcPage,
  },
  {
    path: "/dashboard/vpc/create",
    exact: true,
    component: VpcCreatePage,
  },
  {
    path: "/dashboard/integrations",
    component: IntegrationsPage,
  },
  {
    path: "/dashboard/members",
    component: MembersPage,
  },
  {
    path: "/dashboard/billing",
    exact: false,
    component: BillingPage,
  },
  {
    path: "/dashboard/settings",
    exact: false,
    component: ProjectSettings,
  },
  {
    path: "/dashboard/support",
    exact: true,
    component: SupportPage,
  },
  {
    path: "/dashboard/not-found",
    component: ErrorPage,
  },
  {
    path: "/dashboard/play",
    exact: true,
    component: Play,
  },
];
