import {
  Box,
  Flex,
  Heading,
  Modal as ChakraModal,
  ModalContent as ChakraModalContent,
  ModalOverlay as ChakraModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useKey, useKeyPressEvent } from "react-use";
import { useHistory } from "react-router-dom";
import { ModalProps, ModalButtonsProps } from "components/modal/modalTypes";
import Button from "../button/Button";
import { BOX_SHADOW_VARIANT } from "../container/Container";
import IconButton from "../button/IconButton";

export const ModalButtons = ({
  primaryCTA,
  secondaryCTA,
  isDangerous = false,
  buttonProps,
  ...rest
}: ModalButtonsProps) => {
  const smallScreen = useBreakpointValue({ base: true, lg: false });

  const buttonWidthProps = {
    w: smallScreen ? "100%" : undefined,
    maxW: smallScreen ? "none" : undefined,
  };

  useKeyPressEvent("Enter", () => {
    if (!primaryCTA.isDisabled) {
      primaryCTA.onClick("event");
    }
  });

  return (
    <Box
      display="flex"
      flexDirection={smallScreen ? "column-reverse" : "row"}
      justifyContent="center"
      mt="24px"
      gap="8px"
      {...rest}
    >
      {secondaryCTA && (
        <Button
          variant="secondary"
          sx={isDangerous ? { color: "grayscale.900" } : {}}
          size="md"
          onClick={secondaryCTA.onClick}
          {...buttonWidthProps}
          flex="1"
          isLoading={secondaryCTA.isLoading}
          isDisabled={secondaryCTA.isDisabled}
          data-cy={secondaryCTA.dataCy || "modal-button-cancel"}
          {...buttonProps}
        >
          {secondaryCTA.text}
        </Button>
      )}
      <Button
        variant={isDangerous ? "danger" : "primary"}
        size="md"
        flex="1"
        onClick={primaryCTA.onClick}
        isLoading={primaryCTA.isLoading}
        isDisabled={primaryCTA.isDisabled}
        data-cy={primaryCTA.dataCy || "modal-button-confirm"}
        {...buttonWidthProps}
      >
        {primaryCTA.text}
      </Button>
    </Box>
  );
};

/** Contains nothing but a H4 header and a close button */
const ModalHeading = ({
  title,
  onClose,
  hasBackButton,
  onBack,
  bannerImage,
}: Pick<
  ModalProps,
  "title" | "onClose" | "bannerImage" | "size" | "onBack" | "hasBackButton"
>) => {
  let history = useHistory();

  return (
    <>
      <Box
        w="100%"
        display="flex"
        flexDir="column"
        alignItems="flex-end"
        marginBottom="8px"
      >
        <Flex
          justifyContent={onBack || hasBackButton ? "space-between" : "end"}
          w="100%"
        >
          {(onBack || hasBackButton) && (
            <IconButton
              iconName="actions/Arrow/Simple/Left"
              variant="ghostIcon"
              label="back"
              onClick={onBack || history?.goBack}
              color="grayscale.900"
              size="md"
              left="-8px"
            />
          )}
          <IconButton
            iconName="actions/Close/Large"
            variant="ghostIcon"
            label="Close"
            onClick={onClose}
            color="grayscale.900"
            size="md"
            left="8px"
          />
        </Flex>
      </Box>
      {bannerImage && (
        <Box marginX="auto" mb="12px">
          {bannerImage}
        </Box>
      )}
      {title && (
        <Heading as="h3" size="h3" mb={"12px"}>
          {title}
        </Heading>
      )}
    </>
  );
};

export const Modal = (props: ModalProps) => {
  const {
    isOpen = false,
    onClose,
    children,
    size = "sm",
    width,
    dataCy,
    overflow = "hidden",
  }: // MFAProtected,
  ModalProps = props;
  const smallScreen = useBreakpointValue({ base: true, md: false });
  useKey("Esc", onClose);

  // function renderContent() {
  //   if (MFAProtected) {
  //     const trustToken = () => authStore.fetchMfaTrustToken();
  //     return <MfaChallenge verify={trustToken} />;
  //   } else {
  //     return children;
  //   }
  // }

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      {...props.customModalProps}
    >
      <ChakraModalOverlay onClick={onClose} />
      <ChakraModalContent
        display="flex"
        borderRadius="16px"
        overflow={overflow}
        boxShadow={BOX_SHADOW_VARIANT.lg}
        maxWidth="unset"
        w={
          width
            ? width
            : smallScreen && size === "sm"
              ? "327px"
              : size === "sm"
                ? "400px"
                : size === "md"
                  ? "500px"
                  : size === "xl"
                    ? "640px"
                    : "600px"
        }
        p={smallScreen ? "24px 24px 32px" : "16px 24px 32px"}
        textAlign="center"
        flexDirection="column"
        color="grayscale.700"
        data-cy={dataCy || "modal"}
      >
        <ModalHeading {...props} />
        <Box p={"0px 16px"}>{children}</Box>
      </ChakraModalContent>
    </ChakraModal>
  );
};

export type { ModalProps };
export default Modal;
